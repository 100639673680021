let backendHost;
let isLocalhost = false;
let environment = 'development';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'operarios.labory.app') {
    backendHost = 'https://api.labory.app';
    environment = 'production';
} else if (hostname === 'operarios-new.labory.app') {
    backendHost = 'https://api-new.labory.app';
    environment = 'production';
} else if (hostname === 'staging.operarios.labory.app') {
    backendHost = 'https://staging.api.labory.app';
    environment = 'staging';
} else {
    backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:5000';
    isLocalhost = true;
}

console.log('api config', hostname, backendHost);

const API_ROOT = backendHost;

export default API_ROOT;
export const IS_LOCALHOST = isLocalhost;
export const ENVIRONMENT = environment;
