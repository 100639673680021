import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { TextField } from '../../tareas/common/TextField';
import DrawerMenu from '../DrawerMenu';
import { PageBody } from '../PageBody';
import { PageHeader } from '../PageHeader';

const useStyles = makeStyles(
    (theme) => ({
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        body: {
            padding: 16,
            paddingBottom: 80,
        },
        addButton: {
            position: 'absolute',
            bottom: 16,
            right: 16,
            zIndex: 999,
        },
    }),
    { name: 'ItemCardList' },
);

export default function ItemCardList({ title, items, filterFn, onAddItem, children, headerChildren }) {
    const classes = useStyles();
    const [textFilter, setTextFilter] = useState('');

    const filteredItems = useMemo(
        () => (items ? items.filter((item) => filterFn(item, textFilter)) : items),
        [items, textFilter, filterFn],
    );

    return (
        <React.Fragment>
            <PageHeader title={title} endButton={<DrawerMenu />}>
                <div className={classes.headerContent}>
                    <TextField
                        value={textFilter}
                        onChange={(e) => setTextFilter(e.target.value)}
                        backgroundColor='rgba(255,255,255,0.15)'
                        startAdornment={<SearchIcon />}
                        placeholder={'Buscar'}
                        fieldStyle={{ margin: 0 }}
                        id='tareas-search-field'
                        variant='rounded'
                    />
                </div>
                {headerChildren}
            </PageHeader>
            <PageBody className={classes.body} bodyGap={8} color='dark' paddingTop={0}>
                {filteredItems ? (
                    children({ filteredItems })
                ) : (
                    <>
                        <Skeleton variant='rect' height={124} style={{ margin: '8px 0' }} />
                        <Skeleton variant='rect' height={124} style={{ margin: '8px 0' }} />
                        <Skeleton variant='rect' height={124} style={{ margin: '8px 0' }} />
                    </>
                )}
            </PageBody>

            {onAddItem && (
                <Fab color='primary' className={classes.addButton} onClick={onAddItem}>
                    <AddIcon />
                </Fab>
            )}
        </React.Fragment>
    );
}

ItemCardList.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array,
    filterFn: PropTypes.func.isRequired,
    onAddItem: PropTypes.func,
    children: PropTypes.func.isRequired,
    headerChildren: PropTypes.any,
};
