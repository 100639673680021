const hasToBlobSupport = typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false;

const hasBlobSupport =
    hasToBlobSupport ||
    (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined');

const hasReaderSupport = typeof FileReader !== 'undefined' || typeof URL !== 'undefined';

const hasCanvasSupport = typeof HTMLCanvasElement !== 'undefined';

export default class ImageTools {
    constructor() {
        this.browserSupport = this.isSupportedByBrowser();
    }

    isSupportedByBrowser = () => hasCanvasSupport && hasBlobSupport && hasReaderSupport;

    resize = (file, maxDimensions) =>
        new Promise((resolve, reject) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            if (!this.browserSupport || !file.type.match(/image.*/)) return reject(); // early exit - not supported

            // eslint-disable-next-line prefer-promise-reject-errors
            if (file.type.match(/image\/gif/)) return reject(); // early exit - could be an animated gif

            const image = document.createElement('img');

            image.onload = () => {
                let width = image.width;
                let height = image.height;

                if (width >= height && width > maxDimensions.width) {
                    height *= maxDimensions.width / width;
                    width = maxDimensions.width;
                } else if (height > maxDimensions.height) {
                    width *= maxDimensions.height / height;
                    height = maxDimensions.height;
                } else return resolve(file); // early exit; no need to resize

                const imageCanvas = this.drawImageToCanvas(image, width, height);

                imageCanvas.toBlob((blob) => resolve(blob), file.type);
            };

            this.loadImage(image, file);

            return true;
        });

    drawImageToCanvas = (img, width = img.width, height = img.height) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        return canvas;
    };

    loadImage = (image, file) => {
        if (typeof URL === 'undefined') {
            const reader = new FileReader();
            reader.onload = (event) => {
                image.src = event.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            image.src = URL.createObjectURL(file);
        }
    };
}
