import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    email: '',
    telefono: '',
    direccion: '',
    codigo_postal: '',
    poblacion: '',
    provincia: '',
};

class ClientesProvider extends DataProvider {
    constructor() {
        super('clientes', empty);
    }

    getEmailEnvios = async (id) => this.actionOnId(id, 'email-envios', null, { method: 'get' });
    setEmailEnvios = async (id, value) => this.actionOnId(id, 'email-envios', { email_envios_list: value });
}

export const dataProvider = new ClientesProvider();
