import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { format, formatFullDate } from '../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            textAlign: 'center',
        },
        fecha: {
            color: '#4D8AFF',
            fontSize: 18,
        },
        hora: {
            color: 'white',
            fontSize: 64,
            marginTop: theme.spacing(2),
            lineHeight: '92px',
        },
    }),
    { name: 'Reloj' },
);

export default function Reloj() {
    const [fecha, setFecha] = useState(new Date());
    const classes = useStyles();

    useEffect(() => {
        const timerId = setInterval(() => setFecha(new Date()), 1000);

        return () => clearInterval(timerId);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.fecha}>{formatFullDate(fecha)}</div>
            <div className={classes.hora}>{format(fecha, 'HH:mm:ss')}</div>
        </div>
    );
}
