import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import * as providers from '../api';
import AuthService from '../components/auth/AuthService';
import ChangePassword from '../components/auth/ChangePassword';
import Login from '../components/auth/Login';
import RecoverPassword from '../components/auth/RecoverPassword';
import FirmaExterna from '../components/fichajes/FirmaExterna';

/* Action Types */
const SET_LOGIN_INFO = 'SET_LOGIN_INFO';

/* Define a context and a reducer for updating the context */
const AuthStateContext = createContext();

const initialState = {
    username: null,
    authenticationToken: null,
    roles: null,
    userInfo: {},
};

const authStateReducer = (state, action) => {
    const authService = new AuthService();

    const token = authService.getToken();
    const userInfo = authService.getUserInfo();

    switch (action.type) {
        case SET_LOGIN_INFO:
            Object.keys(providers).forEach((key) => {
                providers[key].token = token;
            });

            if (userInfo)
                Sentry.setUser({ company: userInfo.company, name: userInfo.name, username: authService.getUsername() });
            else Sentry.setUser(null);

            return {
                ...state,
                username: authService.getUsername(),
                token,
                roles: authService.getRoles(),
                userInfo,
            };

        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const AuthStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authStateReducer, initialState);
    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: SET_LOGIN_INFO,
        });
    }, []);

    const onLogin = (token) => {
        dispatch({
            type: SET_LOGIN_INFO,
        });
    };

    if (!state.token) {
        return (
            <AuthStateContext.Provider value={[{ username: null, roles: [], userInfo: {} }, () => {}]}>
                <Switch>
                    <Route path='/firma/:token'>
                        <FirmaExterna />
                    </Route>
                    <Route path='/recover-password'>
                        <RecoverPassword />
                    </Route>
                    <Route path='/change-password/:token'>
                        <ChangePassword />
                    </Route>
                    <Route path='/'>
                        <Login onLogin={onLogin} />
                    </Route>
                </Switch>
            </AuthStateContext.Provider>
        );
    }

    Object.keys(providers).forEach((key) => {
        providers[key].onError = (error) => {
            if (error.status === 401) {
                console.log('ERROR EN PROVIDER', error);

                const authService = new AuthService();
                authService.logout();
                dispatch({
                    type: SET_LOGIN_INFO,
                });
                history.push('/');
            } else if (error.status === 403) {
                history.push('/');
                console.error('ERROR 403', error);
            } else {
                throw error;
            }
        };
    });

    return <AuthStateContext.Provider value={[state, dispatch]}>{children}</AuthStateContext.Provider>;
};

/*
Default export is a hook that provides a simple API for updating the global state.
This also allows us to keep all of this state logic in this one file
*/

const useAuthState = () => {
    const [state, dispatch] = useContext(AuthStateContext);
    const history = useHistory();

    const handleLogout = () => {
        const authService = new AuthService();
        authService.logout();
        history.push('/');
        dispatch({
            type: SET_LOGIN_INFO,
        });
    };

    const handleError = (error) => {
        if (error.status === 401) {
            const authService = new AuthService();
            authService.logout();
            dispatch({
                type: SET_LOGIN_INFO,
            });
        } else if (error.status === 403) {
            history.push('/');
            console.error('ERROR 403', error);
        } else {
            console.error('ERROR (other)', error);
        }
    };

    return {
        logout: handleLogout,
        onError: handleError,
        username: state.username,
        roles: state.roles,
        userInfo: state.userInfo,
        isAdmin: state.roles.includes('ADMIN'),
        isCompany: state.roles.includes('COMPANY'),
    };
};

export default useAuthState;

export function usePreferencias(...preferencias) {
    const { userInfo: { preferencias: preferenciasUser = {} } = {} } = useAuthState();

    if (preferencias.length === 0) return preferenciasUser;
    else if (preferencias.length === 1) return preferenciasUser[preferencias[0]];

    return preferencias.map((key) => preferenciasUser[key]);
}

export function usePermisos(...permisos) {
    const {
        userInfo: { permisos: permisosUser = {} },
    } = useAuthState();

    if (permisos.length === 0) return permisosUser;
    else if (permisos.length === 1) return permisosUser[permisos[0]];

    return permisos.map((key) => permisosUser[key]);
}

AuthStateProvider.propTypes = {
    children: PropTypes.any,
};
