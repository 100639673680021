import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useCallback, useEffect, useState } from 'react';
import { documentosProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import DrawerMenu from '../common/DrawerMenu';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import DocumentoIcon from './DocumentoIcon';

const ListItem = withStyles(
    (theme) => ({
        root: {
            background: 'white',
        },
    }),
    { name: 'ListItem' },
)(MuiListItem);

const ListItemIcon = withStyles(
    (theme) => ({
        root: {
            minWidth: 40,
        },
    }),
    { name: 'ListItemIcon' },
)(MuiListItemIcon);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            '& .MuiListItem-root .MuiTypography-body2': {
                fontWeight: 'normal',
            },
        },
        parentItem: {
            background: theme.palette.neutral.primary,
        },
    }),
    { name: 'DocumentosPage' },
);

export default function DocumentosPage() {
    const classes = useStyles();
    const [parents, setParents] = useState([]);
    const fetchDataFn = useCallback(
        () => documentosProvider.getAll(parents.length > 0 ? parents[parents.length - 1].id : null),
        [parents],
    );
    const { data: documentos, fetchData, isLoading } = useFetchData(fetchDataFn, [], true);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function addParent(parent) {
        setParents([...parents, parent]);
    }

    const currentParent = parents.length > 0 ? parents[parents.length - 1] : null;

    return (
        <>
            <PageHeader title='Documentos' endButton={<DrawerMenu />} />
            <PageBody className={classes.root} paddingTop={0}>
                <List disablePadding>
                    {currentParent && (
                        <ListItem
                            className={classes.parentItem}
                            button
                            divider
                            onClick={() => setParents(parents.splice(0, parents.length - 1))}
                        >
                            <ListItemIcon>
                                <ChevronLeftIcon />
                            </ListItemIcon>
                            <ListItemText primary={currentParent.nombre} />
                        </ListItem>
                    )}
                    {isLoading && (
                        <ListItem>
                            <ListItemIcon>
                                <CircularProgress size={24} />
                            </ListItemIcon>
                            <ListItemText primary='Cargando...' />
                        </ListItem>
                    )}
                    {!isLoading && documentos.length === 0 && (
                        <ListItem>
                            <ListItemIcon />
                            <ListItemText primary='Carpeta vacía' />
                        </ListItem>
                    )}
                    {documentos.map((documento) => {
                        function onClick() {
                            if (documento.es_carpeta) {
                                addParent(documento);
                            } else {
                                window.open(documento.original_url, '_blank');
                            }
                        }
                        return (
                            <ListItem key={documento.id} button divider onClick={onClick}>
                                <ListItemIcon>
                                    <DocumentoIcon documento={documento} />
                                </ListItemIcon>
                                <ListItemText primary={documento.nombre} secondary={documento.descripcion} />
                                {documento.es_carpeta && (
                                    <ListItemSecondaryAction onClick={onClick}>
                                        <ChevronRightIcon />
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        );
                    })}
                </List>
            </PageBody>
        </>
    );
}
