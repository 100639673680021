import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { clientesProvider } from '../../../api';
import ListView from '../../common/ListView';
import { GENERAL } from '../nueva/NuevaTarea';

export default function SelectClienteView({ tarea, setFieldValue, setCurrentFieldComponent }) {
    const [clientes, setClientes] = useState(null);

    useEffect(() => {
        clientesProvider.getAll('as_options?with_address=true').then(setClientes);
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
        ],
        [],
    );

    return (
        clientes && (
            <ListView
                autoOk
                title='Seleccionar cliente'
                onClose={() => setCurrentFieldComponent(GENERAL)}
                setFieldValue={(value) => {
                    setFieldValue('cliente')(value);
                    if (value.direccion_principal) setFieldValue('direccion')(value.direccion_principal);
                }}
                currentValue={tarea.cliente}
                items={clientes}
                columns={columns}
                searchPlaceholder='Buscar cliente'
                canAdd
            />
        )
    );
}

SelectClienteView.propTypes = {
    setCurrentFieldComponent: PropTypes.any,
    setFieldValue: PropTypes.any,
    tarea: PropTypes.any,
};
