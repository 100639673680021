import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: (props) => (props.color === 'dark' ? theme.palette.neutral.dark : '#F3F5FA'),
            flex: 1,
            paddingTop: 8,
            padding: (props) =>
                props.paddingTop !== undefined
                    ? `${props.paddingTop}px 0 0 0`
                    : `${props.removePaddingTop ? 0 : 16}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: (props) => `${props.bodyGap}px`,
        },
    }),
    { name: 'PageBody' },
);

export function PageBody({
    children,
    style,
    className,
    paddingTop,
    bodyGap = 0,
    color = 'default',
    removePaddingTop = false,
}) {
    const classes = useStyles({ paddingTop, removePaddingTop, bodyGap, color });

    return (
        <div style={style} className={clsx(classes.root, className)}>
            {children}
        </div>
    );
}

PageBody.propTypes = {
    bodyGap: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.any,
    color: PropTypes.string,
    paddingTop: PropTypes.any,
    removePaddingTop: PropTypes.bool,
    style: PropTypes.any,
};
