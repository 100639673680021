import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(0.5, 1),
            fontSize: 12,
            color: 'white',
            textAlign: 'center',
            position: 'relative',
        },
        active: {
            backgroundColor: 'white',
            color: '#0B234D',
            fontWeight: 'bold',
        },
    }),
    { name: 'TabbedFilterItem' },
);

const TabbedFilterItem = ({ label, onClick, className, classes, active = false }) => {
    const styles = useStyles();

    return (
        <div
            className={classNames(
                styles.root,
                { [styles.active]: active },
                classes && classes.root,
                classes && { [classes.active]: active },
                className,
            )}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

function TabbedFilter({ onFilterClicked, className, style, selectedFilter, filters, classes, ...props }, ref) {
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                justifyContent: 'space-between',
            }}
            ref={ref}
            {...props}
        >
            {filters.map(({ label, value, className }, i) => (
                <TabbedFilterItem
                    key={i}
                    label={label}
                    active={selectedFilter === value}
                    onClick={() => onFilterClicked(value)}
                    classes={classes}
                    className={className}
                />
            ))}
        </div>
    );
}

export default forwardRef(TabbedFilter);

TabbedFilterItem.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.any,
    classes: PropTypes.any,
    label: PropTypes.any,
    onClick: PropTypes.any,
};

TabbedFilter.propTypes = {
    className: PropTypes.any,
    classes: PropTypes.any,
    filters: PropTypes.any,
    onFilterClicked: PropTypes.any,
    selectedFilter: PropTypes.any,
    style: PropTypes.any,
};
