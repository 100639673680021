import PropTypes from 'prop-types';
import { useState } from 'react';
import PresupuestoItemCard from './PresupuestoItemCard';
import PresupuestoResumenDialog from './PresupuestoResumenDialog';

export default function PresupuestosList({ presupuestos, onUpdate }) {
    const [selectedPresupuesto, setSelectedPresupuesto] = useState(false);

    const handleClickOpen = (tarea) => () => {
        setSelectedPresupuesto(tarea);
    };

    const handleClose = () => {
        setSelectedPresupuesto(false);
    };

    return (
        <>
            {presupuestos?.map((presupuesto, i) => (
                <PresupuestoItemCard
                    key={i}
                    nombre={presupuesto.nombre}
                    referencia={
                        presupuesto.referencia_interna !== null && presupuesto.referencia_interna !== ''
                            ? presupuesto.referencia_interna
                            : presupuesto.numero
                    }
                    importe={presupuesto.importe}
                    estado={presupuesto.estado}
                    onClick={handleClickOpen(presupuesto)}
                />
            ))}

            <PresupuestoResumenDialog
                open={Boolean(selectedPresupuesto)}
                presupuestoId={selectedPresupuesto?.id}
                onClose={handleClose}
                onSave={onUpdate}
            />
        </>
    );
}

PresupuestosList.propTypes = {
    presupuestos: PropTypes.any,
    onUpdate: PropTypes.any,
};
