import PropTypes from 'prop-types';
import ItemCard from '../../common/ItemCardList/ItemCard';

export default function ProyectoItemCard({ cliente, descripcion, onClick, className }) {
    return (
        <ItemCard
            classes={{
                root: className,
            }}
            title={cliente}
            subtitle={descripcion}
            onClick={onClick}
        ></ItemCard>
    );
}

ProyectoItemCard.propTypes = {
    cliente: PropTypes.any,
    descripcion: PropTypes.any,
    onClick: PropTypes.any,
    className: PropTypes.any,
};
