import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarioWeekIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.50801 7.49331C5.84527 7.49331 5.30801 8.03057 5.30801 8.69331V10.3902H18.708V8.69331C18.708 8.03057 18.1707 7.49331 17.508 7.49331H6.50801ZM18.708 11.9902H5.30801V19.6933C5.30801 20.3561 5.84527 20.8933 6.50801 20.8933H17.508C18.1708 20.8933 18.708 20.3561 18.708 19.6933V11.9902ZM3.70801 8.69331C3.70801 7.14691 4.96161 5.89331 6.50801 5.89331H17.508C19.0544 5.89331 20.308 7.14691 20.308 8.69331V19.6933C20.308 21.2397 19.0544 22.4933 17.508 22.4933H6.50801C4.96161 22.4933 3.70801 21.2397 3.70801 19.6933V8.69331Z'
            />
            <path fillRule='evenodd' clipRule='evenodd' d='M8.84121 4.19727V7.48071H7.24121V4.19727H8.84121Z' />
            <path fillRule='evenodd' clipRule='evenodd' d='M16.8744 4.19727V7.48071H15.2744V4.19727H16.8744Z' />
            <path d='M8.04004 13.3821L8.04004 19.5726' stroke='white' strokeWidth='1.6' />
            <path d='M12.04 13.3821L12.04 19.5726' stroke='white' strokeWidth='1.6' />
            <path d='M16.04 13.3821L16.04 19.5726' stroke='white' strokeWidth='1.6' />
        </SvgIcon>
    );
}
