import makeStyles from '@material-ui/core/styles/makeStyles';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { proyectosProvider } from '../../../api';
import useSplash from '../../../contexts/SplashState';
import ListView from '../../common/ListView';
import { ButtonField } from '../../tareas/common/ButtonField';
import { FotoSlideshow } from '../../tareas/notas/FotoSlideshowView';
import { JornadaNotas } from '../../tareas/notas/JornadaNotas';
import { TareaStateContext } from '../../tareas/TareaView';
import AddSolicitudButton from '../common/AddSolicitudButton';

const useStyles = makeStyles(
    (theme) => ({
        tareaFilter: {
            background: theme.palette.neutral.dark,
            padding: theme.spacing(0, 2, 2, 2),
        },
        tarea: {
            // paddingTop: theme.spacing(2),
            '&:not(:first-of-type)': {
                borderTop: '0.5px solid #818cae60',
            },
        },
        tareaTitle: {
            margin: theme.spacing(0, 2),
        },
    }),
    { name: 'NotasList' },
);

export default function NotasList({ proyectoId }) {
    const classes = useStyles();
    const [tareas, setTareas] = useState([]);
    const [selectedTarea, setSelectedTarea] = useState(null);
    const { showCustomComponent } = useSplash();
    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'descripcion',
            },
        ],
        [],
    );

    useEffect(() => {
        proyectosProvider.getAll(`${proyectoId}/observaciones`).then(setTareas);
    }, []);

    const filteredTareas = useMemo(() => {
        if (!selectedTarea) return tareas;

        return tareas.filter((tarea) => tarea.id === selectedTarea.id);
    }, [tareas, selectedTarea]);

    return (
        <>
            <div className={classes.tareaFilter}>
                <ButtonField
                    noMargin
                    value={selectedTarea && selectedTarea.descripcion}
                    placeholder='Filtrar por servicio'
                    onClear={selectedTarea ? () => setSelectedTarea(null) : null}
                    onClick={() => {
                        showCustomComponent(({ closeSplash }) => {
                            return (
                                <ListView
                                    title='Seleccionar servicio'
                                    onClose={closeSplash}
                                    setFieldValue={setSelectedTarea}
                                    currentValue={selectedTarea}
                                    items={tareas}
                                    columns={columns}
                                    searchPlaceholder='Buscar servicio'
                                />
                            );
                        });
                    }}
                    Icon={FilterListIcon}
                />
            </div>
            {filteredTareas.map((tarea) => (
                <div key={tarea.id} className={classes.tarea}>
                    <AddSolicitudButton title={tarea.descripcion} />
                    {/* <Typography className={classes.tareaTitle}>{tarea.descripcion}</Typography> */}

                    <TareaStateContext.Provider
                        value={{
                            id: tarea.id,
                            tarea,
                            openFotoSlideshow: (fotos, fotoIdx) => {
                                showCustomComponent(({ closeSplash }) => (
                                    <FotoSlideshow
                                        tareaId={tarea.id}
                                        fotos={fotos}
                                        fotoIdx={fotoIdx}
                                        onBack={closeSplash}
                                    />
                                ));
                            },
                            addFoto: () => {},
                        }}
                    >
                        {tarea.jornadas.map((jornada) => (
                            <JornadaNotas key={jornada.id} jornada={jornada} disabled />
                        ))}
                    </TareaStateContext.Provider>
                </div>
            ))}
        </>
    );
}

NotasList.propTypes = {
    proyectoId: PropTypes.any,
};
