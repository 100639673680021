import {
    DatePicker as MuiDatePicker,
    DateTimePicker as MuiDateTimePicker,
    TimePicker as MuiTimePicker,
} from '@material-ui/pickers';
import { useField } from 'formik';
import PropTypes from 'prop-types';

export function DatePicker({ label, name, showError = true, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiDatePicker
            clearable
            autoOk={true}
            name={field.name}
            value={field.value}
            format='dd/MM/yyyy'
            helperText={showError && currentError}
            error={showError && Boolean(currentError)}
            onChange={(date) => h.setValue(date, true)}
            {...other}
        />
    );
}

export function DateTimePicker({ label, name, showError = true, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiDateTimePicker
            clearable
            name={field.name}
            value={field.value}
            format='dd/MM/yyyy HH:mm'
            helperText={showError && currentError}
            error={showError && Boolean(currentError)}
            onChange={(date) => h.setValue(date, true)}
            autoOk
            ampm={false}
            minutesStep={15}
            {...other}
        />
    );
}

export function TimePicker({ label, name, showError = true, ...other }) {
    const [field, m, h] = useField(name);
    const currentError = m.error;
    return (
        <MuiTimePicker
            clearable
            name={field.name}
            value={field.value}
            format='HH:mm'
            helperText={showError && currentError}
            error={showError && Boolean(currentError)}
            onChange={(date) => h.setValue(date, true)}
            autoOk
            ampm={false}
            minutesStep={15}
            {...other}
        />
    );
}

DatePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
    showError: PropTypes.bool,
};

DateTimePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
    showError: PropTypes.bool,
};

TimePicker.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
    showError: PropTypes.bool,
};
