import { Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import GestureIcon from '@material-ui/icons/Gesture';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { tareasProvider } from '../../api';
import useSplash from '../../contexts/SplashState';
import { BigButton } from '../common/BigButton';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import ListItem from '../tareas/common/ListItem';
import FirmaContainer from '../tareas/FirmaContainer';
import { BaseFirmarView } from '../tareas/firmar/FirmarView';
import AlbaranView from './AlbaranView';

const useStyles = makeStyles(
    (theme) => ({
        actions: {
            marginTop: 'auto',
            padding: theme.spacing(2),
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            margin: 0,
        },
    }),
    { name: 'SelectAlbaranesClienteView' },
);

export default function SelectAlbaranesClienteView({ cliente, onClose, setUpdatedAlbaranes }) {
    const classes = useStyles();
    const [selectedAlbaranIds, setSelectedAlbaranIds] = useState([]);
    const [albaranDetailsId, setAlbaranDetailsId] = useState(null);
    const [firma, setFirma] = useState(null);
    const { showCustomComponent } = useSplash();

    function toggleAlbaranSelected(id) {
        setSelectedAlbaranIds((prevSelected) => {
            const idx = prevSelected.indexOf(id);

            if (idx !== -1) {
                return [...prevSelected.slice(0, idx), ...prevSelected.slice(idx + 1)];
            }
            return [...prevSelected, id];
        });
    }

    const allSelected = selectedAlbaranIds.length === cliente.albaranes.length;

    function toggleAll() {
        if (allSelected) {
            setSelectedAlbaranIds([]);
        } else {
            setSelectedAlbaranIds(cliente.albaranes.map((albaran) => albaran.id));
        }
    }

    if (albaranDetailsId) {
        return (
            <AlbaranView
                albaranId={albaranDetailsId}
                onClose={() => setAlbaranDetailsId(null)}
                onFirmar={() => {
                    setUpdatedAlbaranes(cliente.albaranes.filter((albaran) => albaran.id !== albaranDetailsId));
                    setSelectedAlbaranIds((ids) => ids.filter((id) => id !== albaranDetailsId));
                }}
            />
        );
    }

    function onRepetirFirma() {
        showCustomComponent(({ closeSplash }) => (
            <BaseFirmarView
                cliente={cliente.nombre}
                descripcion={`${selectedAlbaranIds.length} albaranes seleccionados`}
                onClose={closeSplash}
                onSave={(firma) => {
                    setFirma(firma);
                    closeSplash();
                }}
            />
        ));
    }

    return (
        <>
            <PageHeader
                title={cliente.nombre}
                subtitle='Revisa los albaranes antes de firmarlos'
                startButton={
                    onClose && (
                        <IconButton onClick={onClose}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    )
                }
            />
            <PageBody paddingTop={0}>
                <List disablePadding className={classes.list}>
                    <ListItem onClick={toggleAll}>
                        <Checkbox
                            checked={allSelected}
                            indeterminate={
                                selectedAlbaranIds.length > 0 && selectedAlbaranIds.length < cliente.albaranes.length
                            }
                        />
                        <ListItemText primary='Seleccionar todos' />
                    </ListItem>
                    {cliente.albaranes.map((albaran) => (
                        <ListItem
                            key={albaran.id}
                            onClick={() => {
                                setAlbaranDetailsId(albaran.id);
                            }}
                        >
                            <Checkbox
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    toggleAlbaranSelected(albaran.id);
                                }}
                                checked={selectedAlbaranIds.includes(albaran.id)}
                            />
                            <ListItemText primary={`Albaran ${albaran.numero}`} />
                            <KeyboardArrowRightIcon />
                        </ListItem>
                    ))}
                </List>
                <div className={classes.actions}>
                    {firma ? (
                        <>
                            <div>
                                <FirmaContainer
                                    onRepetirFirma={onRepetirFirma}
                                    firma={firma}
                                    imageSource={firma.imagen}
                                />
                            </div>
                            <BigButton
                                onClick={() => {
                                    tareasProvider
                                        .getAll('albaranes/pendientes-firma', {
                                            method: 'post',
                                            body: JSON.stringify({
                                                albaran_ids: selectedAlbaranIds,
                                                firma,
                                            }),
                                        })
                                        .then(() => {
                                            setUpdatedAlbaranes(
                                                cliente.albaranes.filter(
                                                    (albaran) => !selectedAlbaranIds.includes(albaran.id),
                                                ),
                                            );
                                            if (allSelected) {
                                                onClose();
                                            } else {
                                                setSelectedAlbaranIds([]);
                                                setFirma(null);
                                            }
                                        });
                                }}
                                style={{ margin: 0 }}
                                disabled={selectedAlbaranIds.length === 0}
                                startIcon={<SaveIcon />}
                            >
                                Guardar {selectedAlbaranIds.length}{' '}
                                {selectedAlbaranIds.length > 1 ? 'albaranes' : 'albaran'}
                            </BigButton>
                        </>
                    ) : (
                        <BigButton
                            onClick={onRepetirFirma}
                            style={{ margin: 0 }}
                            disabled={selectedAlbaranIds.length === 0}
                            startIcon={<GestureIcon />}
                        >
                            Añadir firma del cliente
                        </BigButton>
                    )}
                </div>
            </PageBody>
        </>
    );
}

SelectAlbaranesClienteView.propTypes = {
    cliente: PropTypes.any,
    onClose: PropTypes.any,
    setUpdatedAlbaranes: PropTypes.any,
};
