import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import WheelPicker from 'react-simple-wheel-picker';
import { PageHeader } from '../common/PageHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        '& li': {
            justifyContent: 'center',
        },
        '& li span': {
            display: 'none',
        },
    },
}));

const months = [
    { id: '1', value: 'Enero' },
    { id: '2', value: 'Febrero' },
    { id: '3', value: 'Marzo' },
    { id: '4', value: 'Abril' },
    { id: '5', value: 'Mayo' },
    { id: '6', value: 'Junio' },
    { id: '7', value: 'Julio' },
    { id: '8', value: 'Agosto' },
    { id: '9', value: 'Septiembre' },
    { id: '10', value: 'Octubre' },
    { id: '11', value: 'Noviembre' },
    { id: '12', value: 'Diciembre' },
];

export default function SelectMonthYear({ currentMonth, currentYear, onClose, onSelect }) {
    const styles = useStyles();

    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);

    const years = useMemo(() => {
        const thisYear = new Date().getFullYear();
        const years = [];

        for (let i = thisYear + 2; i >= 2021; i--) {
            years.push({ id: i.toString(), value: i.toString() });
        }

        return years;
    }, []);

    const basePickerProps = {
        height: 380,
        width: null,
        itemHeight: 60,
        color: 'rgb(255,255,255,0.4)',
        activeColor: '#fff',
        focusColor: 'transparent',
        backgroundColor: 'transparent',
        shadowColor: 'none',
    };

    const monthPickerProps = {
        ...basePickerProps,
        fontSize: 26,
    };

    const yearPickerProps = {
        ...basePickerProps,
        fontSize: 36,
    };

    return (
        <PageHeader
            title='Elige mes y año'
            fill
            startButton={
                <IconButton onClick={onClose}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            }
            endButton={
                <IconButton onClick={() => onSelect(month, year)}>
                    <DoneIcon style={{ color: 'white' }} />
                </IconButton>
            }
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={styles.root}>
                    <WheelPicker
                        data={months}
                        onChange={(value) => setMonth(value.id)}
                        selectedID={month.toString()}
                        {...monthPickerProps}
                    />
                    <WheelPicker
                        data={years}
                        onChange={(value) => setYear(value.id)}
                        selectedID={year.toString()}
                        {...yearPickerProps}
                    />
                </div>
            </div>
        </PageHeader>
    );
}

SelectMonthYear.propTypes = {
    onSelect: PropTypes.any,
    onClose: PropTypes.any,
    currentMonth: PropTypes.any,
    currentYear: PropTypes.any,
};
