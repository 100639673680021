import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { PageBody } from '../../common/PageBody';
import { useTareaState } from '../TareaView';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            '& .MuiListItem-root .MuiTypography-body2': {
                fontWeight: 'normal',
            },
        },
    }),
    { name: 'AdjuntosPage' },
);

export default function AdjuntosPage() {
    const classes = useStyles();
    const { tarea } = useTareaState();

    const hasDocumentos =
        tarea.documentos.length > 0 || tarea.proyecto?.adjuntos.length > 0 || tarea.adjuntos.length > 0;
    return (
        <PageBody className={classes.root} paddingTop={8}>
            <List dense disablePadding>
                {!hasDocumentos && (
                    <ListItem>
                        <ListItemText primary='No hay documentos adjuntos' />
                    </ListItem>
                )}
                {tarea.proyecto?.adjuntos.map((adjunto) => {
                    const isImage = adjunto.content_type.startsWith('image');

                    return (
                        <ListItem
                            key={adjunto.id}
                            dense
                            button
                            divider
                            onClick={() => window.open(adjunto.image_url || adjunto.original_url, '_blank')}
                        >
                            <ListItemAvatar>
                                {isImage ? (
                                    <Avatar variant='square' src={adjunto.thumbnail_url} />
                                ) : (
                                    <Avatar variant='square'>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                )}
                            </ListItemAvatar>
                            <ListItemText primary={adjunto.original_url.split('/').pop()} />
                        </ListItem>
                    );
                })}
                {tarea.adjuntos.map((adjunto) => {
                    const isImage = adjunto.content_type.startsWith('image');

                    return (
                        <ListItem
                            key={adjunto.id}
                            dense
                            button
                            divider
                            onClick={() => window.open(adjunto.image_url || adjunto.original_url, '_blank')}
                        >
                            <ListItemAvatar>
                                {isImage ? (
                                    <Avatar variant='square' src={adjunto.thumbnail_url} />
                                ) : (
                                    <Avatar variant='square'>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                )}
                            </ListItemAvatar>
                            <ListItemText primary={adjunto.original_url.split('/').pop()} />
                        </ListItem>
                    );
                })}
                {tarea.documentos.map((documento) => {
                    const isImage = documento.content_type.startsWith('image');

                    return (
                        <ListItem
                            key={documento.id}
                            dense
                            button
                            divider
                            onClick={() => window.open(documento.image_url || documento.original_url, '_blank')}
                        >
                            <ListItemAvatar>
                                {isImage ? (
                                    <Avatar variant='square' src={documento.thumbnail_url} />
                                ) : (
                                    <Avatar variant='square'>
                                        <PictureAsPdfIcon />
                                    </Avatar>
                                )}
                            </ListItemAvatar>
                            <ListItemText primary={documento.nombre} secondary={documento.descripcion} />
                        </ListItem>
                    );
                })}
            </List>
        </PageBody>
    );
}
