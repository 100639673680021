import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import CalendarioIcon from '../../icons/Calendario';
import ProyectoItemCard from './ProyectoItemCard';

const useStyles = makeStyles(
    (theme) => ({
        emptyHero: {
            fontSize: 17,
            color: 'white',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '64px auto 0 auto',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 250,
            '& .MuiSvgIcon-root': {
                color: 'white',
                fontSize: 64,
                marginBottom: theme.spacing(2),
            },
            '& .MuiTypography-body1': {
                color: 'white',
                fontWeight: 500,
                fontSize: 16,
                lineHeight: '24px',
            },
            '& .MuiTypography-body2': {
                color: 'white',
                fontWeight: 400,
                fontSize: 12,
                opacity: 0.5,
            },
        },
    }),
    { name: 'ProyectoItemCardList' },
);
export default function ProyectoItemCardList({ proyectos, onClick }) {
    const classes = useStyles();
    return (
        <React.Fragment>
            {proyectos.length === 0 ? (
                <div className={classes.emptyHero}>
                    <CalendarioIcon />
                    <Typography variant='body1'>No se te han asignado proyectos.</Typography>
                    <Typography variant='body2'>Aquí se muestran los proyectos que se te han asignado.</Typography>
                </div>
            ) : (
                <>
                    {proyectos.map((proyecto, i) => (
                        <ProyectoItemCard
                            key={i}
                            cliente={proyecto.cliente}
                            descripcion={proyecto.nombre}
                            onClick={() => onClick(proyecto)}
                        />
                    ))}
                </>
            )}
        </React.Fragment>
    );
}

ProyectoItemCardList.propTypes = {
    proyectos: PropTypes.any,
    onClick: PropTypes.func,
};
