import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import { PageHeader } from '../../common/PageHeader';
import { RoundedButton } from '../../common/RoundedButton';
import { SwitchWithLabel } from '../../common/SwitchWithLabel';
import { useTareaState } from '../TareaView';
import OpcionConfirmar from './OpcionConfirmar';

const useStyles = makeStyles(
    (theme) => ({
        opciones: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(6)}px`,
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
        opcion: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 300,
            color: 'white',
        },
        radioButton: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            fontWeight: 500,
        },
        optionHelper: {
            fontSize: 12,
            marginLeft: theme.spacing(4),
        },
        alert: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            backgroundColor: '#213061',
            borderRadius: 8,
            padding: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        alertIcon: {
            color: 'inherit',
            fontSize: 18,
        },
        alertContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(0.5)}px`,
        },
        lockSwitch: {
            margin: theme.spacing(1.5, 0, 1, 0),
            '& .MuiFormControlLabel-label': {
                fontSize: 12,
                marginLeft: theme.spacing(1),
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: '#213061',
            },
            '& .Mui-checked .MuiSwitch-thumb': {
                backgroundColor: 'white',
            },
        },
        buttonHelper: {
            color: 'white',
            fontSize: 12,
            marginTop: theme.spacing(1),
            textAlign: 'center',
            maxWidth: 250,
            alignSelf: 'center',
        },
    }),
    { name: 'NewOpcionesContinuarView' },
);

export const DEFAULT_SUBVIEW = 'default';
const SALIR_SUBVIEW = 'salir';
const SALIR_OK_SUBVIEW = 'salir-ok';
export const CERRAR_SUBVIEW = 'cerrar';
const CERRAR_OK_SUBVIEW = 'cerrar-ok';

export default function NewOpcionesContinuarView({ onClose, initialSubView = DEFAULT_SUBVIEW }) {
    const history = useHistory();
    const classes = useStyles();
    const { id, tarea } = useTareaState();
    const [subView, setSubView] = useState(initialSubView);
    const [selectedViewOption, setSelectedViewOption] = useState(null);
    const [cerrarLocked, setCerrarLocked] = useState(true);
    const {
        userInfo: { user_id: operarioId },
    } = useAuthState();

    const otrosOperariosActivos = tarea.operarios.filter(
        (o) => o.id !== operarioId && o.planificaciones.some((p) => p.visible),
    );
    const hasOtrosOperariosActivos = otrosOperariosActivos.length > 0;

    const isDefault = subView === DEFAULT_SUBVIEW;

    useEffect(() => {
        setCerrarLocked(hasOtrosOperariosActivos);
    }, [hasOtrosOperariosActivos]);

    useEffect(() => {
        if (cerrarLocked && selectedViewOption === CERRAR_SUBVIEW) setSelectedViewOption(null);
    }, [selectedViewOption, cerrarLocked]);

    function handleCerrarTarea() {
        tareasProvider.actionOnId(id, 'cerrar').then(() => setSubView(CERRAR_OK_SUBVIEW));
    }

    return (
        <PageHeader
            title={isDefault ? 'Selecciona una opción' : ''}
            fill
            startButton={
                isDefault && (
                    <IconButton onClick={onClose}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                )
            }
        >
            {subView === DEFAULT_SUBVIEW && (
                <>
                    <div className={classes.opciones}>
                        <div className={classes.opcion}>
                            <div className={classes.radioButton} onClick={() => setSelectedViewOption(SALIR_SUBVIEW)}>
                                {selectedViewOption === SALIR_SUBVIEW ? (
                                    <RadioButtonCheckedIcon />
                                ) : (
                                    <RadioButtonUncheckedIcon />
                                )}
                                Salir del servicio
                            </div>
                            <Typography className={classes.optionHelper}>
                                He terminado mi trabajo pero el servicio aún no está finalizado.
                            </Typography>
                        </div>
                        <div className={classes.opcion}>
                            <div
                                className={classes.radioButton}
                                onClick={() => !cerrarLocked && setSelectedViewOption(CERRAR_SUBVIEW)}
                            >
                                {cerrarLocked ? (
                                    <LockIcon />
                                ) : selectedViewOption === CERRAR_SUBVIEW ? (
                                    <RadioButtonCheckedIcon />
                                ) : (
                                    <RadioButtonUncheckedIcon />
                                )}
                                Cerrar servicio
                            </div>
                            <Typography className={classes.optionHelper}>
                                El servicio está finalizado y no hay más partes de trabajo que registrar.
                            </Typography>
                            {hasOtrosOperariosActivos && (
                                <div className={classnames(classes.alert, classes.optionHelper)}>
                                    <InfoIcon className={classes.alertIcon} />
                                    <div className={classes.alertContent}>
                                        Aún no se puede cerrar el servicio. Los siguientes operarios aún no han salido
                                        del servicio.
                                        {otrosOperariosActivos.map((o) => (
                                            <div key={o.id}>· {o.nombre}</div>
                                        ))}
                                        <SwitchWithLabel
                                            label='Cerrar de todos modos'
                                            className={classes.lockSwitch}
                                            checked={!cerrarLocked}
                                            onChange={(ev) => {
                                                const checked = ev.target.checked;
                                                setCerrarLocked(!checked);
                                                if (checked) setSelectedViewOption(CERRAR_SUBVIEW);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <RoundedButton
                        disabled={selectedViewOption === null}
                        onClick={() => setSubView(selectedViewOption)}
                    >
                        CONTINUAR
                    </RoundedButton>
                    <div className={classes.buttonHelper}>
                        Si sales o cierras el servicio ya no te aparecerá en la pantalla de “Mis servicios”.
                    </div>
                </>
            )}
            {subView === SALIR_SUBVIEW && (
                <OpcionConfirmar
                    icon={InfoIcon}
                    acceptText='Si, salir del servicio'
                    onAccept={() => {
                        tareasProvider.actionOnId(id, 'salir').then(() => setSubView(SALIR_OK_SUBVIEW));
                    }}
                    onCancel={() => setSubView(DEFAULT_SUBVIEW)}
                >
                    <Typography variant='h2'>¿Estás seguro que quieres salir de este servicio?</Typography>
                </OpcionConfirmar>
            )}
            {subView === SALIR_OK_SUBVIEW && (
                <OpcionConfirmar
                    icon={DoneIcon}
                    acceptText='Volver a mis trabajos'
                    onAccept={() => {
                        onClose();
                        history.replace('/');
                    }}
                >
                    <Typography variant='h1'>Has salido del servicio</Typography>
                </OpcionConfirmar>
            )}
            {subView === CERRAR_SUBVIEW && (
                <OpcionConfirmar
                    icon={InfoIcon}
                    acceptText='Si, cerrar el servicio'
                    onAccept={handleCerrarTarea}
                    onCancel={() => (initialSubView === CERRAR_SUBVIEW ? onClose() : setSubView(DEFAULT_SUBVIEW))}
                >
                    <Typography variant='h2'>¿Estás seguro que quieres cerrar este servicio?</Typography>
                </OpcionConfirmar>
            )}
            {subView === CERRAR_OK_SUBVIEW && (
                <OpcionConfirmar
                    icon={LockIcon}
                    acceptText='Volver a mis servicios'
                    onAccept={() => {
                        onClose();
                        history.replace('/');
                    }}
                >
                    <Typography variant='h1'>Has cerrado el servicio</Typography>
                </OpcionConfirmar>
            )}
        </PageHeader>
    );
}

NewOpcionesContinuarView.propTypes = {
    initialSubView: PropTypes.any,
    onClose: PropTypes.any,
};
