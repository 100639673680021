import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import isFuture from 'date-fns/isFuture';
import { useMemo, useState } from 'react';
import useAuthState from '../../../contexts/AuthState';
import SlidingDialog from '../../common/SlidingDialog';
import { Tab, Tabs } from '../../control_jornada/Tabs';
import { useTareaState } from '../TareaView';
import AdjuntosPage from './AdjuntosPage';
import ChecklistPage from './ChecklistPage';
import ContactoPage from './ContactoPage';
import DetallesPage from './DetallesPage';

const DETALLES = 'detalles';
const CONTACTO = 'contacto';
const ADJUNTOS = 'adjuntos';
const CHECKLIST = 'checklist';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            minHeight: 500,
            maxHeight: 500,
            '& .MuiDialogContent-root': {
                backgroundColor: 'white',
            },
        },
        tabs: {
            margin: theme.spacing(1, 2, 0, 2),
        },
    }),
    { name: 'MenuSlidingDialog' },
);

export default function MenuSlidingDialog() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(DETALLES);
    const { tarea } = useTareaState();

    const {
        userInfo: { preferencias: { usar_checklists: usarChecklists } = {} },
    } = useAuthState();

    function onClose() {
        setOpen(false);
    }

    const checklist = useMemo(() => {
        const validChecklists = tarea.checklists_tarea.filter((c) => c.marcar_completado);
        const checklist =
            [...validChecklists].reverse().find((c) => !isFuture(new Date(c.fecha_inicio))) || validChecklists[0];

        return checklist ?? null;
    }, [tarea.checklists_tarea]);

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <MoreVertIcon />
            </IconButton>
            <SlidingDialog
                open={open}
                onClose={onClose}
                title='Información del servicio'
                removePadding
                className={classes.root}
                extraHeaderComponent={
                    <div>
                        <Tabs
                            value={view}
                            onChange={(ev, newValue) => setView(newValue)}
                            classes={{ root: classes.tabs }}
                            variant='scrollable'
                        >
                            <Tab label='Detalles' value={DETALLES} />
                            <Tab label='Contacto' value={CONTACTO} />
                            <Tab label='Adjuntos' value={ADJUNTOS} />
                            {usarChecklists && checklist && <Tab label='Checklist' value={CHECKLIST} />}
                        </Tabs>
                    </div>
                }
            >
                {view === DETALLES && <DetallesPage />}
                {view === CONTACTO && <ContactoPage />}
                {view === ADJUNTOS && <AdjuntosPage />}
                {view === CHECKLIST && <ChecklistPage checklist={checklist} />}
            </SlidingDialog>
        </>
    );
}
