import { makeStyles } from '@material-ui/core/styles';
import { endOfWeek, isFuture, startOfWeek } from 'date-fns';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { createTiempo } from '../../api/tareas-functions';
import { format, formatTiempo } from '../../utils';
import TabbedFilter from '../TabbedFilter';
import { ControlJornada } from './ControlJornada';

const useFilterTabStyles = makeStyles(
    (theme) => ({
        root: {
            whiteSpace: 'pre',
            fontWeight: 500,
            color: 'white',
            backgroundColor: '#3D79F3',
            textTransform: 'uppercase',
            width: 39,
            height: 54,
            padding: 0,
            paddingTop: 8,
            justifyContent: 'space-between',
            border: '2px solid transparent',
            position: 'relative',
            '& .tiempoJornada': {
                fontSize: 10,
                textTransform: 'none',
                marginBottom: 4,
            },
            '& span.hoy': {
                borderRadius: 4,
                fontSize: 11,
                background: '#45486E',
                color: 'white',
                width: '100%',
                height: 18,
                marginBottom: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: -28,
                '&::after': {
                    // eslint-disable-next-line quotes
                    content: "''",
                    width: 5,
                    height: 5,
                    display: 'block',
                    transform: 'rotate(45deg)',
                    background: '#45486E',
                    position: 'absolute',
                    bottom: -2,
                },
            },
        },
        active: {
            borderColor: 'white',
        },
    }),
    { name: 'ControlJornadaFilterTab' },
);

const useStyles = makeStyles(
    (theme) => ({
        noLaborable: {
            backgroundColor: '#2666E1',
        },
        sinRegistro: {
            backgroundColor: '#E9504F',
        },
        registroIncompleto: {
            backgroundColor: '#F3AC3D',
        },
        registroCompleto: {
            backgroundColor: '#2CBD96',
        },
    }),
    { name: 'ControlJornadaWeek' },
);

export default function ControlJornadaWeek({ fecha }) {
    const filterTabClasses = useFilterTabStyles();
    const classes = useStyles();
    const history = useHistory();

    function changeFecha(value) {
        history.replace(`/control-jornada-week/${value}`);
    }

    const year = format(new Date(fecha), 'Y');
    const week = format(new Date(fecha), 'I');

    const fetchUrl = `jornada-laboral-operario?year=${year}&week=${week}`;

    function renderFilterComponent(resumen) {
        const filters = resumen.map(({ fecha, jornada, laborables: { horario, efectivas } }) => {
            const noLaborable = horario === null || efectivas === 0;
            let className = noLaborable ? classes.noLaborable : null;

            if (!isFuture(new Date(fecha))) {
                if (jornada === 0 && !noLaborable) {
                    className = classes.sinRegistro;
                } else if (jornada > 0 && jornada >= efectivas) {
                    className = classes.registroCompleto;
                } else if (jornada < efectivas) {
                    className = classes.registroIncompleto;
                }
            }

            return {
                label: (
                    <>
                        {/* eslint-disable-next-line quotes */}
                        {format(fecha, "EEEEEE'\n'dd")}
                        <span className='tiempoJornada'>{formatTiempo(createTiempo(jornada), '')}</span>
                        {isToday(new Date(fecha)) && <span className='hoy'>HOY</span>}
                    </>
                ),
                value: fecha,
                className,
            };
        });

        return (
            <TabbedFilter
                style={{ marginTop: 24, marginBottom: 16 }}
                onFilterClicked={changeFecha}
                classes={filterTabClasses}
                filters={filters}
                selectedFilter={fecha}
            />
        );
    }

    const title = useMemo(() => {
        const from = startOfWeek(new Date(fecha), { weekStartsOn: 1 });
        const to = endOfWeek(new Date(fecha), { weekStartsOn: 1 });

        return `${format(from, 'MMM. d')} -> ${format(to, 'MMM. d, yyyy')}`;
    }, [fecha]);

    return (
        <ControlJornada
            title={title}
            fetchUrl={fetchUrl}
            onDateChange={changeFecha}
            fecha={fecha}
            renderFilterComponent={renderFilterComponent}
        />
    );
}

ControlJornadaWeek.propTypes = {
    fecha: PropTypes.any,
};
