import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { fichajesProvider } from '../../api';
import Button from '../common/Button';
import { EditDialogView } from '../common/EditDialogView';
import { PageHeader } from '../common/PageHeader';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function SolicitarRevisionButton({ fichaje, onUpdate }) {
    const [open, setOpen] = useState(false);

    const solicitudRevision = fichaje.solicitud_revision;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <EditDialogView
                    header={<PageHeader title='Editar motivo de revisión' />}
                    title='Escribe el motivo de la modificación'
                    placeholder='Ejemplo: Me he olvidado de fichar la salida 18:05'
                    onClose={(motivo) => {
                        if (motivo && motivo !== solicitudRevision?.motivo) {
                            fichajesProvider.solicitarRevisionFichaje(fichaje.id, motivo).then(onUpdate);
                        }

                        handleClose();
                    }}
                    currentText={solicitudRevision ? solicitudRevision.motivo : ''}
                />
            </Dialog>
            <Button
                color='primary'
                rounded
                outline
                fullWidth
                onClick={() => {
                    setOpen(true);
                }}
            >
                {solicitudRevision ? 'Modificación solicitada' : 'Solicitar modificación'}
            </Button>
        </>
    );
}

SolicitarRevisionButton.propTypes = {
    fichaje: PropTypes.object,
    onUpdate: PropTypes.func,
};
