// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyDfNdfcVansOm0sWX46ih38_q-_aTsimcM',
    authDomain: 'labory-app-1b6c3.firebaseapp.com',
    projectId: 'labory-app-1b6c3',
    storageBucket: 'labory-app-1b6c3.appspot.com',
    messagingSenderId: '579892673751',
    appId: '1:579892673751:web:88682955d606e9b0db4492',
    measurementId: 'G-03X2RRQ02R',
};

export let firebaseApp = null;
export let messaging = null;

try {
    if (firebaseApp === null) firebaseApp = initializeApp(firebaseConfig);

    // check that the site is running from https and then call to getMessaging
    if (window.location.protocol === 'https:' && messaging === null) messaging = getMessaging(firebaseApp);
} catch (error) {
    console.log('An error occurred while initializing Firebase. ', error);
}

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined') {
        return window.navigator.serviceWorker
            .getRegistration('/firebase-push-notification-scope')
            .then((serviceWorker) => {
                if (serviceWorker && serviceWorker.scope.endsWith('firebase-push-notification-scope'))
                    return serviceWorker;
                return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                    scope: '/firebase-push-notification-scope',
                });
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};

// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
    try {
        const messagingResolve = await messaging;
        if (messagingResolve) {
            return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
                return Promise.resolve(
                    getToken(messagingResolve, {
                        vapidKey:
                            'BFdjXD8ps3OcqeYSWf183kMDB9WdmVedDDAlPIGTqixZiGW29aWoQHU1lGCFP2p7uvdJz6o5MSDnNgMZuFXJ2fw',
                        serviceWorkerRegistration,
                    }),
                );
            });
        }
    } catch (error) {
        console.log('An error occurred while retrieving Firebase token. ', error);
    }
};
