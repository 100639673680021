import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuthState from '../../../contexts/AuthState';
import DrawerMenu from '../../common/DrawerMenu';
import Navigation from '../../common/Navigation';
import { PageBody } from '../../common/PageBody';
import { PageHeader } from '../../common/PageHeader';
import ListaFichajes from './ListaFichajesHoy';
import Reloj from './Reloj';

const useStyles = makeStyles(
    (theme) => ({
        body: {
            padding: 0,
        },
    }),
    { name: 'FicharView' },
);
export const FicharView = () => {
    const classes = useStyles();
    const history = useHistory();
    const {
        userInfo: {
            preferencias: { usar_nueva_ui_operarios: usarNuevaUI },
        },
    } = useAuthState();

    const historyButton = (
        <IconButton onClick={() => history.push('/fichajes/historico')}>
            <HistoryIcon />
        </IconButton>
    );

    return (
        <React.Fragment>
            <PageHeader
                title='Fichajes'
                endButton={
                    usarNuevaUI ? (
                        <>
                            {historyButton}
                            <DrawerMenu />
                        </>
                    ) : (
                        historyButton
                    )
                }
            />
            <PageBody color='dark' className={classes.body}>
                <Reloj />
                <ListaFichajes />
            </PageBody>
            {!usarNuevaUI && <Navigation />}
        </React.Fragment>
    );
};
