import MuiListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 18,
        color: '#213061',
        padding: theme.spacing(2),
    },
}));

export default function ListItem({ children, ...props }) {
    const styles = useStyles();
    return (
        <MuiListItem
            divider
            button
            classes={{
                root: styles.root,
            }}
            {...props}
        >
            {children}
        </MuiListItem>
    );
}

ListItem.propTypes = {
    children: PropTypes.any,
};
