import isBefore from 'date-fns/isBefore';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../../api';
import { processTareaForList } from '../../../api/tareas';
import useAuthState, { usePermisos } from '../../../contexts/AuthState';
import ItemCardList from '../../common/ItemCardList';
import { Tab, Tabs } from '../../control_jornada/Tabs';
import TareaItemList from './index';

const PLANIFICADOS = 'planificados';
const LIBRES = 'libres';

export const TareaListNew = () => {
    const history = useHistory();
    const [tareasPlanificadas, setTareasPlanificadas] = useState(null);
    const [tareasLibres, setTareasLibres] = useState(null);
    const [view, setView] = useState(PLANIFICADOS);

    const {
        userInfo: {
            preferencias: { usar_servicios_libres: usarServiciosLibres },
        },
    } = useAuthState();

    const crearServicios = usePermisos('crear_servicios');

    useEffect(() => {
        tareasProvider.getAll('pendientes').then((tareas) => {
            const tareaList = tareas.map(processTareaForList).reduce((tareaList, processedTareas) => {
                tareaList.push(...processedTareas);
                return tareaList;
            }, []);

            tareaList.sort((a, b) => (isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1));

            setTareasPlanificadas(tareaList);
        });
        tareasProvider.getAll('libres').then((tareas) => {
            const tareaList = tareas.map(processTareaForList).reduce((tareaList, processedTareas) => {
                tareaList.push(...processedTareas);
                return tareaList;
            }, []);

            // tareaList.sort((a, b) => (isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1));

            setTareasLibres(tareaList);
        });
    }, []);

    const filterTareas = useCallback(function (tarea, textFilter) {
        if (textFilter === '') return true;

        const upperFilter = textFilter.toUpperCase();

        return (
            tarea.descripcion.toUpperCase().includes(upperFilter) || tarea.cliente.toUpperCase().includes(upperFilter)
        );
    }, []);

    return (
        <ItemCardList
            filterFn={filterTareas}
            title='Lista de servicios'
            items={view === PLANIFICADOS ? tareasPlanificadas : tareasLibres}
            onAddItem={crearServicios ? () => history.push('/tarea/nueva') : null}
            headerChildren={
                usarServiciosLibres && (
                    <Tabs value={view} onChange={(ev, newValue) => setView(newValue)}>
                        <Tab label='Planificados' value={PLANIFICADOS} />
                        <Tab label='Libres' value={LIBRES} />
                    </Tabs>
                )
            }
        >
            {({ filteredItems }) => <TareaItemList tareas={filteredItems} hideTiempo showFecha />}
        </ItemCardList>
    );
};
