import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field } from './Field';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: theme.spacing(1.25, 2),
            flex: 1,
            fontSize: 14,
        },
        clearButton: {
            width: 40,
        },
    }),
    { name: 'ButtonField' },
);

export const ButtonField = ({
    label,
    value,
    onClick,
    placeholder,
    className,
    onClear,
    Icon = ChevronRightIcon,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Field label={label} {...props}>
            <div className={clsx(classes.root, className)} onClick={onClick ?? (() => {})}>
                {value || placeholder}
                {onClick && !onClear && <Icon fontSize='inherit' style={{ color: '#A6ACC0' }} />}
            </div>
            {onClear && (
                <IconButton className={classes.clearButton} size='small' onClick={onClear}>
                    <CloseIcon fontSize='inherit' style={{ color: '#A6ACC0' }} />
                </IconButton>
            )}
        </Field>
    );
};

ButtonField.propTypes = {
    className: PropTypes.any,
    label: PropTypes.any,
    onClick: PropTypes.any,
    onClear: PropTypes.any,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    Icon: PropTypes.any,
};
