import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import { estadoPreparacionColors, estadoPreparacionLabels } from '../../../../api/preparaciones_material';
import { formatDate, formatISODate } from '../../../../utils';
import { PageBodySection } from '../../../common/PageBodySection';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(2),
        },
        descripcion: {
            color: '#45486E',
            fontSize: 16,
        },
        fecha: {
            color: '#818CAE',
            fontSize: 11,
            marginBottom: theme.spacing(1.5),
        },
        title: {
            fontSize: 13,
            fontWeight: 'bold',
            color: '#818CAE',
            marginBottom: theme.spacing(2),
            gap: `${theme.spacing(0.5)}px`,
            '& span:not($label)': {
                fontWeight: 'normal',
            },
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            borderRadius: 20,
            fontWeight: 500,
            fontSize: 9,
            color: 'white',
            textTransform: 'uppercase',
            backgroundColor: (props) => theme.palette[props.backgroundColor].main,
            padding: theme.spacing(0.5, 1),
            marginLeft: 'auto',
        },
        solicitudContainer: {
            padding: (props) => `8px ${props.disabled ? 8 : 24}px 4px 8px`,
            backgroundColor: 'white',
            borderRadius: 8,
            // marginBottom: theme.spacing(2),
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            minHeight: 24,
        },
        editIcon: {
            position: 'absolute',
            right: 4,
            top: 'calc(50% - 9px)',
            color: '#B8BABE',
            fontSize: 18,
        },
        solicitudText: {
            color: '#818CAE',
            fontSize: 13,
            whiteSpace: 'pre-line',
        },
        operario: {
            color: '#2196F3',
            fontWeight: 700,
            fontSize: 10,
            textAlign: 'right',
            marginTop: 'auto',
        },
    }),
    { name: 'SolicitudMaterial' },
);

export function SolicitudMaterial({ solicitud, disabled, style, onClick }) {
    const estado = solicitud.estado ?? 'SIN_PREPARACION';
    const classes = useStyles({ backgroundColor: estadoPreparacionColors[estado] });

    const todayStr = formatISODate(new Date());
    const isToday = solicitud.fecha === todayStr;

    return (
        <PageBodySection className={classes.root} style={style}>
            <Typography className={classes.title}>
                {isToday ? 'Hoy' : formatDate(new Date(solicitud.fecha_solicitud))}{' '}
                {solicitud.operario && <span>({solicitud.operario.nombre})</span>}
                <span className={classes.label}>{estadoPreparacionLabels[estado]}</span>
            </Typography>

            <div
                className={classes.solicitudContainer}
                onClick={() => {
                    if (disabled) return;

                    onClick();
                }}
            >
                {!disabled && <ChevronRightIcon className={classes.editIcon} />}
                {solicitud.notas && <Typography className={classes.solicitudText}>{solicitud.notas}</Typography>}
                {solicitud.lineas.map((linea, i) => (
                    <Typography key={i} className={classes.solicitudText}>
                        {linea.unidades} {linea.descripcion}
                    </Typography>
                ))}
            </div>
        </PageBodySection>
    );
}

SolicitudMaterial.propTypes = {
    disabled: PropTypes.any,
    onClick: PropTypes.any,
    solicitud: PropTypes.any,
    style: PropTypes.any,
};
