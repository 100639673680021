import DataProvider from './DataProvider';

export const empty = {};

class SubtareasProvider extends DataProvider {
    constructor() {
        super('subtareas', empty);
    }

    getAsOptions = async () => {
        return this.getAll('as_options');
    };
}

export const dataProvider = new SubtareasProvider();
