import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import * as classnames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { tareasProvider } from '../../../api';
import ImageTools from '../../../utils/ImageTools';
import NotasIcon from '../../icons/Notas';
import { useTareaState } from '../TareaView';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: `${theme.spacing(2)}px`,
    },
    addFoto: {
        boxSizing: 'border-box',
        borderRadius: 4,
        width: 60,
        height: 60,
        border: '1px dashed #B5BCD1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        '& svg': {
            color: '#B5BCD1',
        },
    },
    foto: {
        position: 'relative',
        color: '#818CAE',
        fontSize: 13,
        width: 60,
        height: 60,
        marginBottom: theme.spacing(2),
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: 4,
        },
        '&.uploading img': {
            opacity: 0.5,
        },
        '& .MuiCircularProgress-root': {
            color: 'black',
            position: 'absolute',
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)',
        },
    },
    avatar: {
        position: 'absolute',
        bottom: -11,
        right: -11,
        backgroundColor: 'white',
        color: '#2196F3',
        fontWeight: 700,
        fontSize: 10,
        borderRadius: '50%',
        width: 22,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pieFotoIcon: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: 'white',
        width: 20,
        height: 20,
        '& .MuiSvgIcon-root': {
            fontSize: 20,
        },
    },
}));

export function Fotos({ jornadaId, fotos, disabled }) {
    const classes = useStyles();
    const { addFoto, openFotoSlideshow } = useTareaState();

    const [fotosUploading, setFotosUploading] = useState([]);

    const addButtonId = `add-foto-${jornadaId}`;
    return (
        <div className={classes.root}>
            {!disabled && (
                <label htmlFor={addButtonId} className={classes.addFoto}>
                    <AddAPhotoIcon />
                    <input
                        id={addButtonId}
                        type='file'
                        multiple
                        accept='image/x-png,image/jpeg'
                        style={{ display: 'none' }}
                        onChange={(ev) => {
                            const files = ev.target.files;
                            if (files.length === 0) return;

                            setFotosUploading(
                                new Array(files.length).fill({
                                    thumbnail_url: null,
                                }),
                            );

                            (async () => {
                                // Using an async IIFE (Immediately Invoked Function Expression) for demonstration
                                for (const [i, file] of new Array(...files).entries()) {
                                    // Resize the image to thumbnail size and update state
                                    const thumbnailBlob = await new ImageTools().resize(file, {
                                        width: 200,
                                        height: 200,
                                    });
                                    const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
                                    setFotosUploading((fotos) =>
                                        fotos.map((foto, j) =>
                                            j === i ? { ...foto, thumbnail_url: thumbnailUrl } : foto,
                                        ),
                                    );

                                    // Resize the image for upload and send it
                                    const uploadBlob = await new ImageTools().resize(file, {
                                        width: 3000,
                                        height: 3000,
                                    });
                                    const formData = new FormData();
                                    formData.append('fotos', uploadBlob);

                                    try {
                                        const newFotos = await tareasProvider.action(`jornadas/${jornadaId}/fotos`, {
                                            method: 'post',
                                            body: formData,
                                        });

                                        // Upon successful upload, update the state and add new photo information
                                        setFotosUploading((fotos) => fotos.map((foto, j) => (j === i ? null : foto)));
                                        newFotos.forEach((newFoto) => addFoto(jornadaId, newFoto));
                                    } catch (err) {
                                        console.log(err);
                                        // Handle upload error, possibly by resetting the state
                                        setFotosUploading((fotos) => fotos.map((foto, j) => (j === i ? null : foto)));
                                    }
                                }
                            })();
                        }}
                    />
                </label>
            )}
            {fotos.map((foto, i) => (
                <div key={i} className={classes.foto} onClick={() => openFotoSlideshow(fotos, i)}>
                    {foto.texto && foto.texto !== '' && (
                        <div className={classes.pieFotoIcon}>
                            <NotasIcon />
                        </div>
                    )}
                    <img src={foto.thumbnail_url} />
                </div>
            ))}
            {fotosUploading.filter(Boolean).map((foto, i) => (
                <div key={i} className={classnames(classes.foto, 'uploading')}>
                    <img src={foto.thumbnail_url} />
                    <CircularProgress />
                </div>
            ))}
        </div>
    );
}

Fotos.propTypes = {
    disabled: PropTypes.any,
    fotos: PropTypes.any,
    jornadaId: PropTypes.any,
};
