import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { estadoPresupuestoLabels } from '../../../api/presupuestos';
import ItemCard from '../../common/ItemCardList/ItemCard';

export const useStyles = makeStyles(
    (theme) => ({
        estado: {
            '&.PENDIENTE': {
                backgroundColor: theme.palette.error.main,
            },
            '&.ENVIADO': {
                backgroundColor: theme.palette.info.main,
            },
            '&.ACEPTADO': {
                backgroundColor: theme.palette.success.main,
            },
            '&.RECHAZADO': {
                backgroundColor: theme.palette.error.main,
            },
            '&.CANCELADO': {
                backgroundColor: theme.palette.neutral.grey4,
            },
        },
    }),
    { name: 'PresupuestoItemCard' },
);

export default function PresupuestoItemCard({ nombre, referencia, importe, estado, onClick, className }) {
    const classes = useStyles();

    const title = referencia ? `${referencia} | ${nombre}` : nombre;

    return (
        <ItemCard
            classes={{
                root: className,
                estado: classNames(classes.estado, estado),
            }}
            title={title}
            estado={estado && estadoPresupuestoLabels[estado]}
            valor={importe && `${Number(importe).toFixed(2)} EUR`}
            onClick={onClick}
        ></ItemCard>
    );
}

PresupuestoItemCard.propTypes = {
    className: PropTypes.any,
    nombre: PropTypes.any,
    referencia: PropTypes.any,
    onClick: PropTypes.any,
    importe: PropTypes.any,
    estado: PropTypes.any,
};
