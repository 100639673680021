import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { isPast } from 'date-fns';
import PropTypes from 'prop-types';
import { formatDate, formatDateTime, isAllDay } from '../../../utils';
import { PageBody } from '../../common/PageBody';
import CalendarioIcon from '../../icons/Calendario';
import OperariosIcon from '../../icons/Operarios';
import { useTareaState } from '../TareaView';
import InfoPageBodySection from './InfoPageBodySection';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            '& .MuiTypography-body2:not($text)': {
                marginBottom: theme.spacing(1),
            },
            '& .MuiTypography-body2': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .MuiTypography-body2 .MuiSvgIcon-root': {
                marginRight: theme.spacing(1),
                fontSize: 20,
            },
        },
        text: {
            whiteSpace: 'pre-wrap',
            fontWeight: 'normal',
        },
    }),
    { name: 'DetallesPage' },
);

export default function DetallesPage() {
    const classes = useStyles();
    const { tarea } = useTareaState();

    const planificacionesDelOperario = tarea.planificaciones.filter((planificacion) =>
        planificacion.operarios.some((asignacion) => asignacion.operario.is_self && asignacion.visible),
    );

    let planificaciones = planificacionesDelOperario.filter(
        (planificacion) => !isPast(new Date(planificacion.fecha_fin)),
    );

    if (planificaciones.length === 0 && planificacionesDelOperario.length > 0) {
        planificaciones = [planificacionesDelOperario[0]];
    }

    return (
        <PageBody className={classes.root} paddingTop={0}>
            <InfoPageBodySection>
                <Typography variant='body2'>Descripción para el operario</Typography>
                <Typography variant='body2' className={classes.text}>
                    {tarea.detalles && tarea.detalles !== '' ? tarea.detalles : 'No se han especificado detalles'}
                </Typography>
            </InfoPageBodySection>
            {planificaciones.map((planificacion, i) => {
                return (
                    <PlanificacionDetalle
                        key={i}
                        planificacion={planificacion}
                        title={i === 0 ? 'Planificación' : null}
                    />
                );
            })}
        </PageBody>
    );
}

export function PlanificacionDetalle({ planificacion, title }) {
    const classes = useStyles();

    const formatFn = isAllDay(planificacion.fecha_inicio, planificacion.fecha_fin) ? formatDate : formatDateTime;

    return (
        <InfoPageBodySection>
            {title && <Typography variant='body2'>{title}</Typography>}
            <Typography variant='body2' className={classes.text}>
                <CalendarioIcon />
                {formatFn(planificacion.fecha_inicio)} a {formatFn(planificacion.fecha_fin)}
            </Typography>
            <Typography variant='body2' className={classes.text}>
                <OperariosIcon />
                {planificacion.operarios
                    .filter((asignacion) => asignacion.visible)
                    .map((asignacion, j) => asignacion.operario.nombre)
                    .join(', ')}
            </Typography>
            {planificacion.vehiculo && (
                <Typography variant='body2' className={classes.text}>
                    <DriveEtaIcon />
                    {planificacion.vehiculo.nombre}
                </Typography>
            )}
        </InfoPageBodySection>
    );
}

PlanificacionDetalle.propTypes = {
    planificacion: PropTypes.any,
    title: PropTypes.string,
};
