import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons/Replay';
import SearchIcon from '@material-ui/icons/Search';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import useSplash from '../../../contexts/SplashState';
import { deepEqual } from '../../../utils';
import { RoundedInput } from '../RoundedInput';
import { DeleteDialog } from './DeleteDialog';
import { SelectMaterialView } from './SelectMaterialView';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        lineaMaterial: {
            display: 'flex',
            marginLeft: theme.spacing(-1),
        },
        error: {
            fontSize: 13,
            color: '#D84A3A',
            marginTop: theme.spacing(-0.5),
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'MaterialJornada' },
);

export function LineaMaterial({ name, disabled, material, onDelete, onAddExtra, onReset }) {
    const styles = useStyles();
    const { showCustomComponent } = useSplash();

    const [, { value, initialValue, error }, helpers] = useField(name);

    const canReset = Boolean(initialValue && material.id !== undefined) && !deepEqual(initialValue, value);

    return (
        <div className={styles.root}>
            <div className={styles.lineaMaterial}>
                <RoundedInput
                    placeholder={'Material'}
                    name={`${name}.descripcion`}
                    style={{ flex: 1 }}
                    disabled={disabled}
                />
                <RoundedInput placeholder={'Uds'} name={`${name}.unidades`} style={{ width: 80 }} disabled={disabled} />
                <IconButton
                    size='small'
                    disabled={disabled}
                    onClick={() => {
                        if (disabled) return;

                        showCustomComponent(({ closeSplash }) => (
                            <SelectMaterialView
                                onChange={(selectedMaterial) => {
                                    const [thisSelectedMaterial, ...extraSelectedMateriales] = Array.isArray(
                                        selectedMaterial,
                                    )
                                        ? selectedMaterial
                                        : [selectedMaterial];

                                    const material = {
                                        ...value,
                                        descripcion: thisSelectedMaterial.descripcion,
                                    };
                                    if (thisSelectedMaterial.id) material.material_interno_id = thisSelectedMaterial.id;

                                    helpers.setValue(material);

                                    if (extraSelectedMateriales.length > 0) onAddExtra(extraSelectedMateriales);

                                    closeSplash();
                                }}
                                onClose={closeSplash}
                                currentValue={material}
                            />
                        ));
                    }}
                >
                    <SearchIcon style={{ fontSize: 20 }} />
                </IconButton>
                {canReset ? (
                    <IconButton
                        size='small'
                        onClick={() => {
                            helpers.setValue(initialValue);
                            onReset();
                        }}
                    >
                        <ReplayIcon style={{ fontSize: 20 }} />
                    </IconButton>
                ) : (
                    <DeleteDialog
                        onDelete={onDelete}
                        // onDelete={() => deleteMaterial(jornadaId, material.id)}
                        // disabled={disabled || material.id === undefined || !canDelete}
                        disabled={disabled || !onDelete}
                    />
                )}
            </div>
            {error && <div className={styles.error}>{Object.values(error).join('. ')}</div>}
        </div>
    );
}

LineaMaterial.propTypes = {
    disabled: PropTypes.any,
    material: PropTypes.any,
    name: PropTypes.any,
    onAddExtra: PropTypes.any,
    onDelete: PropTypes.any,
    onReset: PropTypes.any,
};
