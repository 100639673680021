import withStyles from '@material-ui/core/styles/withStyles';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';

export const Tabs = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(2, 0, 0, 0),
            minHeight: 24,
        },
        flexContainer: {
            gap: (props) => (props.disableGutters ? '0px' : '32px'),
        },
        indicator: {
            height: 3,
        },
    }),
    { name: 'Tabs' },
)(({ disableGutters, ...props }) => <MuiTabs indicatorColor='primary' {...props} />);

export const Tab = withStyles(
    (theme) => ({
        root: {
            fontSize: '1rem',
            textTransform: 'none',
            minWidth: 80,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.neutral.primary,
            padding: theme.spacing(0, 0, 2, 0),
            minHeight: 24,
            '&:hover': {},
            '&$selected': {
                color: 'white',
            },
            '&:focus': {},
        },
        wrapper: {
            lineHeight: '24px',
        },
        selected: {},
    }),
    { name: 'Tab' },
)((props) => <MuiTab disableRipple {...props} />);
