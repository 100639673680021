import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import isAfter from 'date-fns/isAfter';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { tareasProvider } from '../../../api';
import useSplash from '../../../contexts/SplashState';
import { formatISODate, formatTime } from '../../../utils';
import { useTareaState } from '../TareaView';
import { SelectSubtareaView } from './SelectSubtareaView';
import SelectTime, { getNearestTiempo } from './SelectTime';

const useStyles = makeStyles(
    (theme) => ({
        emptyElement: {
            borderRadius: 4,
            border: '1px dashed #BABABA',
            minHeight: 38,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            color: '#818CAE',
            flex: 1,
            boxSizing: 'border-box',
        },
        hora: {
            background: 'white',
            borderRadius: 4,
            flex: 1,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            fontSize: 12,
            color: '#818CAE',
            '& svg': {
                color: 'inherit',
                position: 'absolute',
                right: theme.spacing(1.5),
                fontSize: 20,
            },
        },
    }),
    { name: 'MarcajesList' },
);

export function useSubtareasList(selectedJornadaDate) {
    const {
        tarea: { checklists_tarea: checklists },
    } = useTareaState();

    return useMemo(() => {
        const checklist =
            [...checklists].reverse().find((c) => !isAfter(new Date(c.fecha_inicio), new Date(selectedJornadaDate))) ||
            checklists[0];

        if (!checklist || !checklist.contar_tiempo) return [];

        return checklist.items
            .filter((item) => Boolean(item.subtarea_id))
            .map((item) => ({ id: item.subtarea_id, titulo: item.texto }));
    }, [selectedJornadaDate, checklists]);
}

export function BaseMarcajeItem({
    element,
    tareaId,
    marcaje,
    selectedJornadaDate,
    horaKey,
    onSave,
    minTime,
    maxTime,
    disabled,
    subtareasList,
}) {
    const { showCustomComponent } = useSplash();

    const tiempo = marcaje && marcaje[horaKey] ? new Date(marcaje[horaKey]) : null;

    let url = `${tareaId}/jornadas/${selectedJornadaDate}/marcajes`;
    if (marcaje) url += `/${marcaje.id}`;

    return React.cloneElement(element, {
        onClick: () => {
            if (disabled) return;

            const tipo = horaKey.includes('entrada') ? 'entrada' : 'salida';
            const currentTiempo = tiempo ? { horas: tiempo.getHours(), minutos: tiempo.getMinutes() } : null;

            function showTimePicker(tiempo, extraBodyProps) {
                showCustomComponent(({ closeSplash }) => (
                    <SelectTime
                        fecha={selectedJornadaDate}
                        onClose={closeSplash}
                        currentTiempo={tiempo}
                        onSelect={(tiempo, coords) => {
                            const fecha = new Date(selectedJornadaDate);
                            fecha.setHours(tiempo.horas, tiempo.minutos, 0, 0);
                            return tareasProvider
                                .action(url, {
                                    body: JSON.stringify({ [horaKey]: fecha, coords, ...extraBodyProps }),
                                    method: 'post',
                                })
                                .then((res) => {
                                    closeSplash();
                                    return onSave(res);
                                });
                        }}
                        tipo={tipo}
                        minTime={minTime}
                        maxTime={maxTime}
                    />
                ));
            }

            if (tipo !== 'entrada' || currentTiempo !== null) {
                showTimePicker(currentTiempo);
                return;
            }

            function showWithNextTime(extraBodyProps) {
                tareasProvider.action(`siguiente-hora-entrada/${formatISODate(selectedJornadaDate)}`).then((res) => {
                    if (res.fecha === null) {
                        showTimePicker(currentTiempo, extraBodyProps);
                        return;
                    }

                    const tiempo = getNearestTiempo(new Date(res.fecha));
                    showTimePicker(tiempo, extraBodyProps);
                });
            }

            if (subtareasList.length === 0) {
                showWithNextTime();
                return;
            }
            showCustomComponent(({ closeSplash }) => (
                <SelectSubtareaView
                    onChange={(selectedSubtarea) => {
                        showWithNextTime({ subtarea_id: selectedSubtarea.id });
                        closeSplash();
                    }}
                    subtareas={subtareasList}
                    onClose={closeSplash}
                    currentValue={null}
                />
            ));
            setTimeout(() => {
                document.querySelector('#global-filter-input').focus();
            }, 200);
        },
    });
}

export default function MarcajeItem(props) {
    const classes = useStyles();
    const { marcaje, horaKey } = props;
    const tiempo = marcaje && marcaje[horaKey] ? new Date(marcaje[horaKey]) : null;

    return (
        <BaseMarcajeItem
            element={
                <div className={tiempo ? classes.hora : classes.emptyElement}>
                    {tiempo ? (
                        <>
                            {formatTime(tiempo)}
                            {!props.disabled && <EditIcon />}
                        </>
                    ) : (
                        '+ Añadir'
                    )}
                </div>
            }
            {...props}
        />
    );
}

MarcajeItem.propTypes = {
    horaKey: PropTypes.any,
    marcaje: PropTypes.any,
    disabled: PropTypes.any,
};
