import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: (props) => `8px ${props.disabled ? 8 : 24}px 4px 8px`,
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: theme.spacing(2),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 24,
    },
    editIcon: {
        position: 'absolute',
        right: 4,
        top: 'calc(50% - 9px)',
        color: '#B8BABE',
        fontSize: 18,
    },
    explicacion: {
        color: '#818CAE',
        fontSize: 13,
        whiteSpace: 'pre-line',
    },
    operario: {
        color: '#2196F3',
        fontWeight: 700,
        fontSize: 10,
        textAlign: 'right',
        marginTop: 'auto',
    },
}));

export function Explicacion({ explicacion, setAddExplicacionView }) {
    const disabled = setAddExplicacionView === null;
    const classes = useStyles({ disabled });

    const onEdit = setAddExplicacionView ?? (() => {});
    return (
        <div className={classes.root} onClick={onEdit}>
            {!disabled && <ChevronRightIcon className={classes.editIcon} />}
            <Typography className={classes.explicacion}>{explicacion ?? 'Sin explicación'}</Typography>
        </div>
    );
}

Explicacion.propTypes = {
    explicacion: PropTypes.any,
    setAddExplicacionView: PropTypes.any,
};
