import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import Button from '../../common/Button';

const ADD_BUTTON_SIZE = 32;

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.neutral.grey1,
            padding: theme.spacing(1.5, 2),
            '& .MuiTypography-root': {
                fontSize: 16,
                fontWeight: 500,
            },
        },
        addButton: {
            marginLeft: 'auto',
            height: ADD_BUTTON_SIZE,
            width: ADD_BUTTON_SIZE,
            minWidth: ADD_BUTTON_SIZE,
            minHeight: ADD_BUTTON_SIZE,
            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
        },
    }),
    { name: 'AddSolicitudButton' },
);

export default function AddSolicitudButton({ title, onClick }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography>{title}</Typography>
            {onClick && (
                <Button className={classes.addButton} onClick={onClick}>
                    <AddIcon style={{ color: 'white', fontSize: 20 }} />
                </Button>
            )}
        </div>
    );
}

AddSolicitudButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
};
