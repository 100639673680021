import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SyncIcon from '@material-ui/icons/Sync';
import PropTypes from 'prop-types';
import Button from '../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        signatureContainer: {
            position: 'relative',
        },
        signature: {
            border: '1px dashed #bababa',
            borderRadius: 4,
            maxHeight: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            '& img': {
                margin: theme.spacing(2),
                objectFit: 'cover',
                overflow: 'hidden',
            },
        },
        signatureButton: {
            boxShadow: 'none',
            textTransform: 'none',
            color: 'white',
            backgroundColor: '#4D86FF',
            '&:focus, &:active': {
                backgroundColor: '#4D86FF',
            },
            position: 'absolute',
            top: 8,
            right: 8,
        },
        signatureName: {
            fontSize: 12,
            color: '#818cae',
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'FirmaContainer' },
);

export default function FirmaContainer({ firma, onRepetirFirma, imageSource }) {
    const classes = useStyles();

    return (
        <>
            <Typography variant='h4'>Firma del cliente</Typography>
            <div className={classes.signatureContainer}>
                <Button
                    size='small'
                    onClick={onRepetirFirma}
                    startIcon={<SyncIcon />}
                    variant='contained'
                    className={classes.signatureButton}
                >
                    Repetir
                </Button>
                <div className={classes.signature}>
                    <img src={imageSource} />
                </div>
            </div>
            <Typography className={classes.signatureName}>Firmado por: {firma.nombre}</Typography>
        </>
    );
}

FirmaContainer.propTypes = {
    firma: PropTypes.object,
    onRepetirFirma: PropTypes.func,
    imageSource: PropTypes.string,
};
