import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EstadoChip from './EstadoChip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 8,
            backgroundColor: 'white',
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        row: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
        info: {
            flex: 1,
            overflowX: 'hidden',
            '& .MuiTypography-root': {
                fontSize: 14,
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& .MuiTypography-root:first-of-type': {
                fontWeight: 500,
            },
        },
        rightColumn: {
            textAlign: 'right',
            fontSize: 16,
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'column',
            margin: '-2px 0',
            alignItems: 'flex-end',
        },
        estado: {
            fontSize: 12,
            // alignSelf: 'flex-start',
            padding: theme.spacing(0.25, 0.5),
            borderRadius: 4,
            color: 'white',
        },
    }),
    { name: 'ItemCard' },
);

export default function ItemCard({ title, subtitle, estado, valor, children, onClick, classes: extraClasses }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, extraClasses?.root)} onClick={onClick}>
            <div className={classes.row}>
                <div className={classes.info}>
                    <Typography>{title}</Typography>
                    <Typography>{subtitle}</Typography>
                </div>
                <Typography component='div' className={classes.rightColumn}>
                    {estado && <EstadoChip estado={estado} className={extraClasses?.estado} />}
                    {valor}
                </Typography>
            </div>
            {children}
        </div>
    );
}

ItemCard.propTypes = {
    title: PropTypes.any,
    subtitle: PropTypes.any,
    estado: PropTypes.any,
    valor: PropTypes.any,
    children: PropTypes.any,
    onClick: PropTypes.any,
    classes: PropTypes.any,
};
