import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import debounce from '@material-ui/core/utils/debounce';
import { useField } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { tareasProvider } from '../../../api';
import useSplash from '../../../contexts/SplashState';
import NotasIcon from '../../icons/Notas';
import { useTareaState } from '../TareaView';
import PreguntaNotasView from './PreguntaNotasView';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderTop: '1px solid #D8DAE1',
            boxSizing: 'border-box',
            padding: theme.spacing(1.5, 3),
        },
        texto: {
            fontSize: 13,
            marginBottom: theme.spacing(1),
            color: 'rgba(0, 0, 0, 0.54)',
        },
        list: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
        },
        notasButton: {
            marginLeft: 'auto',
            marginTop: 10,
        },
    }),
    { name: 'Pregunta' },
);

export default function Pregunta({ pregunta, onChangeNotas, onValueSaved, disabled, children }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [{ value: valor }, { touched }] = useField(`respuestas.${pregunta.id}.valor`);
    const [{ value: notas }, ,] = useField(`respuestas.${pregunta.id}.notas`);
    const {
        viewParams: {
            parteMantenimiento: { id: parteMantenimientoId },
        },
    } = useTareaState();
    const { showCustomComponent } = useSplash();

    const debouncedSavePregunta = useCallback(
        debounce((value) => {
            const valueString = Array.isArray(value) ? value.map((v) => v || '').join(',') : value;

            // console.log(pregunta.id, value, 'save')
            tareasProvider
                .updateParteMantenimientoRespuesta(parteMantenimientoId, pregunta.id, valueString)
                .then(() => {
                    onValueSaved && onValueSaved(valueString);
                    snackbar.showMessage('Respuesta guardada con éxito');
                })
                .catch((err) => {
                    console.log(err);
                    snackbar.showMessage('Ha ocurrido un error al guardar la respuesta');
                });
        }, 500),
        [pregunta.id, parteMantenimientoId],
    );

    useEffect(() => {
        if (!touched) return;

        debouncedSavePregunta(valor);
    }, [debouncedSavePregunta, valor, touched]);

    const hasValor = valor !== undefined && valor !== null;

    return (
        <div className={classes.root}>
            <div className={classes.texto}>{pregunta.texto}</div>
            <div className={classes.list}>
                {children}
                <IconButton
                    size='small'
                    color={notas ? 'primary' : 'default'}
                    className={classes.notasButton}
                    disabled={disabled || !hasValor}
                    onClick={() => {
                        showCustomComponent(({ closeSplash }) => (
                            <PreguntaNotasView
                                onSave={onChangeNotas}
                                onClose={closeSplash}
                                pregunta={pregunta}
                                notas={notas}
                            />
                        ));
                    }}
                >
                    <NotasIcon size='small' />
                </IconButton>
            </div>
        </div>
    );
}

Pregunta.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.any,
    onChangeNotas: PropTypes.any,
    onValueSaved: PropTypes.any,
    pregunta: PropTypes.any,
};
