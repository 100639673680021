import DataProvider from './DataProvider';

export const empty = {
    codigo: '',
    nombre: '',
    cliente: null,
    cliente_id: null,
    direccion: null,
    direccion_id: null,
    adjuntos: [],
    tareas: [],
    presupuestos: [],
    jornadas: [],
};

class ProyectosProvider extends DataProvider {
    constructor() {
        super('proyectos', empty);
    }
}

export const dataProvider = new ProyectosProvider();
