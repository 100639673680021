import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EventIcon from '@material-ui/icons/Event';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import classNames from 'classnames';
import clsx from 'clsx';
import isSameDay from 'date-fns/isSameDay';
import PropTypes from 'prop-types';
import { estadoLabels } from '../api/tareas';
import { isTiempoZero } from '../api/tareas-functions';
import { formatDate, formatTiempo, formatTime, isAllDay } from '../utils';
import OperariosIcon from './icons/Operarios';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 8,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            color: '#213061',
            position: 'relative',
            // margin: theme.spacing(1, 0),
            gap: '8px',
        },
        fecha: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: '#818CAE',
            '& svg': {
                fontSize: 16,
                marginRight: theme.spacing(0.5),
            },
        },
        cliente: {
            fontWeight: 'bold',
            fontSize: 18,
            display: 'flex',
            alignItems: 'center',
            '& $row': {
                marginLeft: 'auto',
            },
        },
        overflowText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        row: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
        },
        direccion: {
            fontSize: 13,
            fontWeight: 'normal',
            display: 'flex',
            alignItems: 'flex-start',
            '& svg': {
                fontSize: 16,
            },
        },
        descripcion: {
            fontSize: 14,
        },
        upperRow: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -4px',
            gap: '8px',
        },
        chip: {
            backgroundColor: '#f3f5fa',
            color: '#818CAE',
            fontSize: 12,
            '& svg': {
                height: 14,
                width: 14,
                color: 'inherit',
            },
        },
        operariosExtra: {
            marginLeft: 'auto',
        },
        statusLabel: {
            padding: theme.spacing(0.5, 0),
            width: 82,
            borderRadius: 20,
            fontSize: 12,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        pendiente: {
            backgroundColor: '#D9EEFF',
            color: '#2196F3',
        },
        en_progreso: {
            backgroundColor: '#FFECCF',
            color: '#F3AC3D',
        },
        finalizada: {
            backgroundColor: '#EDF8F5',
            color: '#4DB7A0',
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }),
    { name: 'TareaCard' },
);

const useFooterElementStyles = makeStyles(
    (theme) => ({
        root: {
            color: '#818CAE',
            display: 'flex',
            fontSize: 13,
        },
        label: {
            // fontSize: 11,
            // marginBottom: 4,
            '& strong': {
                fontWeight: 500,
            },
        },
        value: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 13,
            },
            '& span': {
                // fontSize: 13,
                marginLeft: 4,
            },
            '$zero &': {
                color: theme.palette.error.main,
            },
        },
        zero: {},
    }),
    { name: 'TareaCardFooterElement' },
);

const FooterElement = ({ label, icon, value, className }) => {
    const classes = useFooterElementStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>
                {icon}
                <span>{value}</span>
            </div>
        </div>
    );
};

const Tiempo = ({ label, tiempo }) => {
    const classes = useFooterElementStyles();

    return (
        <FooterElement
            label={label}
            icon={label ? null : <ScheduleIcon />}
            value={formatTiempo(tiempo)}
            className={isTiempoZero(tiempo) ? classes.zero : null}
        />
    );
};

const tipoLabels = {
    AGENDA: 'Agenda',
    INSTALACION: 'Instalacion',
    MANTENIMIENTO: 'Mantenimiento',
    REPARACION: 'Reparacion',
    OTROS: 'Otros',
    SUMINISTRO: 'Suministro',
    PRESUPUESTO: 'Presupuesto',
};

export default function TareaCard({
    cliente,
    descripcion,
    direccion,
    vehiculo,
    estado,
    tiempo,
    tiempoLabel,
    tiempoAcumulado,
    onClick,
    numero,
    fechaInicio,
    fechaFin,
    numOperarios,
    tipo,
}) {
    const styles = useStyles();

    const hasFechas = fechaInicio && fechaFin;
    let fechas = '';
    if (hasFechas) {
        if (isAllDay(fechaInicio, fechaFin)) {
            if (isSameDay(fechaInicio, fechaFin)) {
                fechas = formatDate(fechaInicio);
            } else {
                fechas = `${formatDate(fechaInicio)} a ${formatDate(fechaFin)}`;
            }
        } else {
            fechas = `${formatDate(fechaInicio)} de ${formatTime(fechaInicio)} a ${formatTime(fechaFin)}`;
        }
    }

    return (
        <div className={styles.root} onClick={onClick}>
            <div className={styles.upperRow}>
                <Chip label={tipoLabels[tipo]} size='small' className={styles.chip} style={{ fontWeight: 500 }} />
                {numOperarios > 1 && (
                    <Chip
                        label={`+${numOperarios - 1}`}
                        icon={<OperariosIcon fontSize='small' />}
                        size='small'
                        className={clsx(styles.chip, styles.operariosExtra)}
                    />
                )}
                <div className={classNames(styles.statusLabel, styles[estado.toLowerCase()])}>
                    {estadoLabels[estado]}
                </div>
            </div>
            {hasFechas && (
                <div className={styles.fecha}>
                    <EventIcon />
                    {fechas}
                </div>
            )}
            <div className={styles.cliente}>
                <span className={styles.overflowText}>{cliente}</span>
            </div>
            <div className={styles.descripcion}>{descripcion}</div>
            <div className={styles.row}>
                {vehiculo && (
                    <div className={styles.direccion}>
                        <DriveEtaIcon />
                        {vehiculo.codigo}
                    </div>
                )}
                <div className={styles.direccion}>
                    <LocationOnIcon />
                    {direccion}
                </div>
            </div>
            <div className={styles.footer}>
                <FooterElement label='Ref:' value={numero ? numero.toString().padStart(4, '0') : 'N/D'} />
                <Tiempo label={tiempoLabel === undefined ? 'Hoy:' : tiempoLabel} tiempo={tiempo} />
                {tiempoAcumulado && <Tiempo label='Total:' tiempo={tiempoAcumulado} />}
            </div>
        </div>
    );
}

TareaCard.propTypes = {
    cliente: PropTypes.any,
    descripcion: PropTypes.any,
    direccion: PropTypes.any,
    estado: PropTypes.any,
    fechaFin: PropTypes.any,
    fechaInicio: PropTypes.any,
    numOperarios: PropTypes.any,
    numero: PropTypes.any,
    onClick: PropTypes.any,
    tiempo: PropTypes.any,
    tiempoAcumulado: PropTypes.any,
    tiempoLabel: PropTypes.any,
    tipo: PropTypes.any,
    vehiculo: PropTypes.any,
};

Tiempo.propTypes = {
    label: PropTypes.any,
    tiempo: PropTypes.any,
};

FooterElement.propTypes = {
    className: PropTypes.any,
    icon: PropTypes.any,
    label: PropTypes.any,
    value: PropTypes.any,
};
