import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    items: [],
    activo: true,
};

class ChecklistsProvider extends DataProvider {
    constructor() {
        super('checklists', empty);
    }

    getOnlyActiveAsOptions = async () => this.getAll('as_options');

    getTareaChecklist = async (id) => this.get(`checklists-tarea/${id}`);

    duplicar = async (id) => this.actionOnId(id, 'duplicar');
}

export const dataProvider = new ChecklistsProvider();
