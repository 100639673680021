import { makeStyles } from '@material-ui/core/styles';
import addHours from 'date-fns/addHours';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { DatePicker, TimePicker } from './DatePicker';

const useHasChanged = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
};

const usePrevious = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

const useStyles = makeStyles(
    (theme) => ({
        datePicker: {
            flex: 1,
        },
        timePicker: {
            minWidth: 60,
            width: 60,
        },
    }),
    { name: 'DateRangePicker' },
);

export function DateRangePicker({
    fecha,
    allDay,
    setFieldValue,
    disabled,
    fechaInicioKey,
    fechaFinKey,
    fromLabel = 'Del',
    toLabel = 'al',
    showError = true,
}) {
    const classes = useStyles();

    const [{ value: fechaInicioValue }, ,] = useField(fechaInicioKey);
    const [, , { setValue: setFechaFinValue }] = useField(fechaFinKey);

    const fechaInicioChanged = useHasChanged(fechaInicioValue);
    const allDayChanged = useHasChanged(allDay);

    useEffect(() => {
        if (!fechaInicioChanged && !allDayChanged) return;

        setFechaFinValue(addHours(fechaInicioValue, 1));
    }, [fechaInicioChanged, allDayChanged]);

    if (allDay) {
        return (
            <>
                <DatePicker
                    name={fechaInicioKey}
                    format={'dd/MM/yyyy'}
                    autoOk
                    ampm={false}
                    minutesStep={15}
                    disabled={disabled}
                    showError={showError}
                    className={classes.datePicker}
                />
                <span style={{ margin: '0 8px', color: '#8F95AF', fontSize: 13, padding: '10px 0' }}>a</span>
                <DatePicker
                    name={fechaFinKey}
                    format={'dd/MM/yyyy'}
                    autoOk
                    ampm={false}
                    minutesStep={15}
                    disabled={disabled}
                    showError={showError}
                    className={classes.datePicker}
                />
            </>
        );
    }

    return (
        <>
            <DatePicker
                name={fechaInicioKey}
                disabled={disabled}
                showError={showError}
                className={classes.datePicker}
            />
            <span style={{ margin: '0 8px', color: '#8F95AF', fontSize: 13, padding: '10px 0' }}>de</span>
            <TimePicker
                name={fechaInicioKey}
                disabled={disabled}
                showError={showError}
                className={classes.timePicker}
            />
            <span style={{ margin: '0 8px', color: '#8F95AF', fontSize: 13, padding: '10px 0' }}>a</span>
            <TimePicker name={fechaFinKey} disabled={disabled} showError={showError} className={classes.timePicker} />
        </>
    );
}

DateRangePicker.propTypes = {
    allDay: PropTypes.any,
    disabled: PropTypes.any,
    fecha: PropTypes.any,
    fechaFinKey: PropTypes.any,
    fechaInicioKey: PropTypes.any,
    fromLabel: PropTypes.string,
    setFieldValue: PropTypes.any,
    showError: PropTypes.bool,
    toLabel: PropTypes.string,
};
