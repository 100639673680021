import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import debounce from '@material-ui/core/utils/debounce';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PageBody } from './PageBody';
import { PageBodySection } from './PageBodySection';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            marginTop: theme.spacing(1),
            fontWeight: 'normal',
        },
    }),
    { name: 'EditDialogView' },
);

const Input = withStyles({
    root: {
        color: '#818CAE',
        fontSize: 13,
        height: '100%',
    },
    inputMultiline: {
        height: '100%',
    },
})(InputBase);

export const EditDialogView = ({ currentText, onChange, onClose, header, children, title, placeholder }) => {
    const classes = useStyles();
    const [text, setText] = useState(currentText ?? '');
    const [initialText] = useState(currentText ?? '');

    const inputRef = useRef(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!inputRef.current) return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }, [inputRef]);

    const changed = text !== initialText;

    const debouncedSaveText = useCallback(
        debounce((text) => {
            if (!onChange) return;

            return onChange(text)
                .then(() => snackbar.showMessage('Guardado'))
                .catch(() => snackbar.showMessage('Ha ocurrido un error al guardar'));
        }, 500),
        [onChange],
    );

    function changeText(value) {
        setText(value);
        debouncedSaveText(value);
    }

    const headerDecorated =
        header &&
        React.cloneElement(header, {
            startButton: (
                <IconButton onClick={() => onClose(text)}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            ),
        });

    return (
        <>
            {headerDecorated}
            <PageBody paddingTop={0}>
                <PageBodySection>
                    {title && (
                        <Typography variant='body2' className={classes.title}>
                            {title}
                        </Typography>
                    )}
                    <div
                        style={{
                            backgroundColor: '#fff',
                            borderRadius: 8,
                            padding: '8px 16px',
                            position: 'relative',
                            minHeight: 100,
                            marginBottom: 12,
                            marginTop: 16,
                            flex: 1,
                        }}
                    >
                        <Input
                            fullWidth
                            multiline
                            rows={6}
                            value={text}
                            onChange={(ev) => {
                                changeText(ev.target.value);
                            }}
                            inputRef={inputRef}
                            placeholder={placeholder}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 8,
                                right: 8,
                                display: 'flex',
                            }}
                        >
                            {changed ? (
                                <>
                                    <IconButton size='small' onClick={() => changeText(initialText)}>
                                        <ReplayIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton size='small' onClick={() => changeText('')}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    {children}
                </PageBodySection>
            </PageBody>
        </>
    );
};

EditDialogView.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any,
    currentText: PropTypes.any,
    header: PropTypes.any,
    onChange: PropTypes.any,
    onClose: PropTypes.any,
    placeholder: PropTypes.any,
};
