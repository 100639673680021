import { useEffect, useState } from 'react';
import { tareasProvider } from '../../api';
import SelectAlbaranesClienteView from './SelectAlbaranesClienteView';
import SelectClienteView from './SelectClienteView';

export default function AlbaranesList() {
    const [clientes, setClientes] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState(null);

    useEffect(() => {
        tareasProvider.getAll('albaranes/pendientes-firma').then((res) => {
            setClientes(res);
        });
    }, []);

    if (!selectedCliente) {
        return <SelectClienteView clientes={clientes} onSelect={(cliente) => setSelectedCliente(cliente)} />;
    }

    return (
        <SelectAlbaranesClienteView
            cliente={selectedCliente}
            onClose={() => setSelectedCliente(null)}
            setUpdatedAlbaranes={(albaranes) =>
                setClientes((clientes) => {
                    if (albaranes.length === 0) {
                        setSelectedCliente(null);
                        return clientes.filter((cliente) => cliente.id !== selectedCliente.id);
                    }

                    return clientes.map((cliente) => {
                        if (cliente.id !== selectedCliente.id) return cliente;

                        const updatedCliente = {
                            ...cliente,
                            albaranes,
                        };

                        setSelectedCliente(updatedCliente);
                        return updatedCliente;
                    });
                })
            }
        />
    );
}
