import DataProvider from './DataProvider';

export const empty = {
    numero: '',
    referencia_interna: '',
    nombre: '',
    notas: '',
    estado: '',
    importe: 0,
    fecha_creacion: null,
    fecha_envio: null,
    fecha_final: null,
    cliente: null,
    cliente_id: null,
    direccion: null,
    direccion_id: null,
    proyecto: null,
    proyecto_id: null,
    adjuntos: [],
};

export const estadoPresupuestoLabels = {
    PENDIENTE: 'Pendiente',
    ENVIADO: 'Enviado',
    ACEPTADO: 'Aceptado',
    RECHAZADO: 'Rechazado',
    CANCELADO: 'Cancelado',
};

class PresupuestosProvider extends DataProvider {
    constructor() {
        super('presupuestos', empty);
    }

    aceptar = async (id) => this.actionOnId(id, 'aceptar', null, { method: 'post' });
    rechazar = async (id) => this.actionOnId(id, 'rechazar', null, { method: 'post' });
}

export const dataProvider = new PresupuestosProvider();
