import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { clientesProvider } from '../../../api';
import ListView from '../../common/ListView';
import { GENERAL } from '../nueva/NuevaTarea';

export default function SelectProyectoView({ tarea, setFieldValue, setCurrentFieldComponent, clienteId }) {
    const [proyectos, setProyectos] = useState(null);

    useEffect(() => {
        if (!clienteId) {
            setProyectos([]);
            return;
        }
        clientesProvider.actionOnId(clienteId, 'proyectos/as_options', null, { method: 'get' }).then(setProyectos);
    }, [clienteId]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Proyecto',
                accessor: 'proyecto_completa',
            },
        ],
        [],
    );

    return (
        proyectos && (
            <ListView
                title='Seleccionar proyecto'
                onClose={() => setCurrentFieldComponent(GENERAL)}
                setFieldValue={(value) => {
                    setFieldValue('proyecto')(value);
                }}
                currentValue={tarea.proyecto}
                items={proyectos}
                columns={columns}
                searchPlaceholder='Buscar proyecto'
            />
        )
    );
}

SelectProyectoView.propTypes = {
    clienteId: PropTypes.any,
    setCurrentFieldComponent: PropTypes.any,
    setFieldValue: PropTypes.any,
    tarea: PropTypes.any,
};
