import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import { useTareaState } from '../TareaView';
import { JornadaExplicacion } from './JornadaExplicacion';

const useInfoStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}));

export const TareasExplicaciones = ({ setAddExplicacionView }) => {
    const infoClasses = useInfoStyles();
    const {
        tarea: { jornadas, disabled },
    } = useTareaState();

    const todayStr = formatISODate(new Date());
    const hasToday = jornadas.findIndex((jornada) => jornada.propia && jornada.fecha === todayStr) >= 0;

    return (
        <>
            {!hasToday && (
                <PageBodySection className={infoClasses.root}>
                    <Typography className={infoClasses.title}>Hoy</Typography>
                    <Typography className={infoClasses.info}>
                        <InfoIcon /> Registra el tiempo para poder añadir la descripción de hoy.
                    </Typography>
                </PageBodySection>
            )}
            {jornadas
                .filter((jornada) => jornada.propia || jornada.explicacion !== null)
                .map((jornada, i) => (
                    <JornadaExplicacion
                        key={i}
                        jornada={jornada}
                        disabled={disabled}
                        setAddExplicacionView={() => setAddExplicacionView(jornada)}
                    />
                ))}
        </>
    );
};

TareasExplicaciones.propTypes = {
    setAddExplicacionView: PropTypes.any,
};
