import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { presupuestosProvider } from '../../../../api';
import { estadoPresupuestoLabels } from '../../../../api/presupuestos';
import Button from '../../../common/Button';
import EstadoChip from '../../../common/ItemCardList/EstadoChip';
import SlidingDialog from '../../../common/SlidingDialog';
import { useStyles as usePresupuestoEstadoStyles } from '../PresupuestoItemCard';
import InfoChip from './InfoChip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
        title: {
            display: 'flex',
            marginRight: theme.spacing(1),
            flex: 1,
        },
        row: {
            display: 'flex',
            alignItems: 'flex-end',
            overflowX: 'hidden',
        },
        nombre: {
            fontSize: 20,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
        },
        estado: {
            marginLeft: 'auto',
        },
        infoChips: {
            margin: theme.spacing(2, 0, 4, 0),
            display: 'flex',
            flexWrap: 'wrap',
            gap: `0px ${theme.spacing(3)}px`,
        },
        actions: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'PresupuestoResumenDialog' },
);

export default function PresupuestoResumenDialog({ open, onClose, presupuestoId, onSave }) {
    const classes = useStyles();
    const cardClasses = usePresupuestoEstadoStyles();
    const [presupuesto, setPresupuesto] = useState(null);

    useEffect(() => {
        if (!open) {
            setPresupuesto(null);
            return;
        }

        presupuestosProvider.getById(presupuestoId).then(setPresupuesto);
    }, [open, presupuestoId]);

    const adjunto = presupuesto?.adjuntos?.[0];

    return (
        <SlidingDialog
            open={open}
            onClose={onClose}
            removeTypography
            title={
                presupuesto && (
                    <div className={classes.title}>
                        <Typography>Presupuesto {presupuesto.numero}</Typography>

                        <EstadoChip
                            estado={estadoPresupuestoLabels[presupuesto.estado]}
                            className={classNames(presupuesto.estado, classes.estado, cardClasses.estado)}
                        />
                    </div>
                )
            }
        >
            {presupuesto && (
                <>
                    <div className={classes.row}>
                        <Typography className={classes.nombre}>{presupuesto.nombre}</Typography>
                    </div>
                    <div className={classes.infoChips}>
                        <InfoChip Icon={InfoIcon} fullWidth>
                            Referencia {presupuesto.referencia_interna}
                        </InfoChip>
                        <InfoChip Icon={InfoIcon} fullWidth>
                            {Number(presupuesto.importe).toFixed(2)} EUR
                        </InfoChip>
                    </div>
                    <div className={classes.actions}>
                        {presupuesto.estado === 'ENVIADO' && (
                            <>
                                <Button
                                    color='success'
                                    rounded
                                    fullWidth
                                    onClick={() =>
                                        presupuestosProvider.aceptar(presupuestoId).then((presupuesto) => {
                                            setPresupuesto(presupuesto);
                                            onSave && onSave(presupuesto);
                                        })
                                    }
                                >
                                    Aceptar presupuesto
                                </Button>
                                <Button
                                    color='error'
                                    rounded
                                    fullWidth
                                    onClick={() =>
                                        presupuestosProvider.rechazar(presupuestoId).then((presupuesto) => {
                                            setPresupuesto(presupuesto);
                                            onSave && onSave(presupuesto);
                                        })
                                    }
                                >
                                    Rechazar presupuesto
                                </Button>
                            </>
                        )}
                        {adjunto && (
                            <Button
                                color='primary'
                                outline
                                rounded
                                fullWidth
                                onClick={() => window.open(adjunto.original_url, '_blank')}
                            >
                                Ver presupuesto
                            </Button>
                        )}
                    </div>
                </>
            )}
        </SlidingDialog>
    );
}

PresupuestoResumenDialog.propTypes = {
    onClose: PropTypes.any,
    open: PropTypes.any,
    presupuestoId: PropTypes.any,
    onSave: PropTypes.any,
};
