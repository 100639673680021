import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatTiempo, formatTime } from '../../utils';
import CalendarioIcon from '../icons/Calendario';

const TimelineItem = withStyles(
    (theme) => ({
        root: {
            color: 'white',
        },
        missingOppositeContent: {
            '&::before': {
                flex: 0,
            },
        },
        content: {
            '& .missing': {
                color: theme.palette.error.main,
            },
        },
    }),
    { name: 'TimelineItem' },
)(MuiTimelineItem);

const TimelineDot = withStyles(
    (theme) => ({
        outlinedPrimary: {
            borderColor: theme.palette.success.main,
        },
        outlinedSecondary: {
            borderColor: theme.palette.error.main,
        },
    }),
    { name: 'TimelineItem' },
)(MuiTimelineDot);

const useStyles = makeStyles(
    (theme) => ({
        emptyHero: {
            fontSize: 17,
            color: 'white',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 250,
            '& .MuiSvgIcon-root': {
                color: 'white',
                fontSize: 64,
                marginBottom: theme.spacing(2),
            },
            '& .MuiTypography-body1': {
                color: 'white',
                fontWeight: 500,
                fontSize: 16,
                lineHeight: '24px',
            },
            '& .MuiTypography-body2': {
                color: 'white',
                fontWeight: 400,
                fontSize: 12,
                opacity: 0.5,
            },
        },
    }),
    { name: 'ActividadTab' },
);

export default function ActividadTab({ tareas }) {
    const classes = useStyles();
    const { marcajes, tareasWithoutMarcajes } = useMemo(() => {
        const marcajes = tareas.reduce((marcajes, tarea) => {
            marcajes.push(
                ...tarea.marcajes.map((marcaje) => ({
                    entrada: new Date(marcaje.hora_entrada),
                    salida: marcaje.hora_salida ? new Date(marcaje.hora_salida) : null,
                    cliente: tarea.cliente,
                    descripcion: tarea.descripcion,
                })),
            );
            return marcajes;
        }, []);

        marcajes.sort((a, b) => (isBefore(a.entrada, b.entrada) ? -1 : 1));

        const marcajesWithoutGaps = [];

        marcajes.forEach((marcaje, i) => {
            marcajesWithoutGaps.push(marcaje);
            if (i === marcajes.length - 1) return;

            if (Math.abs(differenceInMinutes(marcaje.salida, marcajes[i + 1].entrada)) > 0) {
                marcajesWithoutGaps.push({
                    entrada: marcaje.salida,
                    salida: marcajes[i + 1].entrada,
                    cliente: null,
                });
            }
        });

        return {
            marcajes: marcajesWithoutGaps,
            tareasWithoutMarcajes: tareas.filter((t) => t.marcajes.length === 0 && t.tiempoHoy !== null),
        };
    }, [tareas]);

    console.log(tareas);

    if (marcajes.length === 0 && tareasWithoutMarcajes.length === 0) {
        return (
            <div className={classes.emptyHero}>
                <CalendarioIcon />
                <Typography variant='body1'>No hay actividad</Typography>
                <Typography variant='body2'>
                    Aquí se muestra el tiempo que hayas apuntado en los servicios del dia seleccionado.
                </Typography>
            </div>
        );
    }

    return (
        <Timeline>
            {marcajes.map((marcaje, i) => (
                <TimelineItem key={`marcaje-${i}`}>
                    <TimelineSeparator>
                        <TimelineDot variant='outlined' color={marcaje.cliente ? 'primary' : 'secondary'} />
                        {i < marcajes.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='subtitle2'>
                            {formatTime(marcaje.entrada)} a{' '}
                            {marcaje.salida ? formatTime(marcaje.salida) : '(sin hora de salida)'}
                        </Typography>
                        <Typography variant='body2' className={marcaje.cliente === null ? 'missing' : null}>
                            {marcaje.cliente ?? 'Sin registro'}
                        </Typography>
                        {marcaje.cliente && <Typography variant='subtitle2'>{marcaje.descripcion}</Typography>}
                    </TimelineContent>
                </TimelineItem>
            ))}
            {tareasWithoutMarcajes.map((tarea, i) => (
                <TimelineItem key={`tarea-${i}`}>
                    <TimelineSeparator>
                        <TimelineDot variant='outlined' color='primary' />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant='subtitle2'>{formatTiempo(tarea.tiempoHoy)}</Typography>
                        <Typography variant='body2'>{tarea.cliente}</Typography>
                        <Typography variant='subtitle2'>{tarea.descripcion}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}

ActividadTab.propTypes = {
    tareas: PropTypes.any,
};
