import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import isPast from 'date-fns/isPast';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { ButtonDialog } from '../../common/ButtonDialog';
import { DateRangePicker } from '../../common/DateRangePicker';
import { StartLabelSwitchFormik } from '../../common/SwitchWithLabel';
import { ButtonField } from '../common/ButtonField';
import { OPERARIO } from './EditarTarea';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            height: 30,
            margin: theme.spacing(0.5, 0),
        },
        label: {
            color: '#213061',
            fontSize: 14,
            fontWeight: 500,
            marginBottom: theme.spacing(1),
            '&:not(:first-of-type)': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& svg': {
                fontSize: 20,
            },
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        addButtonSection: {
            display: 'flex',
            marginBottom: theme.spacing(1),
        },
        addButton: {
            fontSize: 12,
            marginLeft: 'auto',
        },
        solicitarButton: {
            marginTop: 'auto',
            marginBottom: theme.spacing(2),
        },
        dateRange: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            // alignItems: 'center',
            marginBottom: theme.spacing(1),
            '& .MuiInput-root': {
                background: 'white',
                borderRadius: 4,
            },
            '& .MuiInput-input': {
                fontSize: 13,
                padding: 10,
            },
            '&&& .MuiInput-underline:after': {
                borderBottom: 'none',
            },
            '&&& .MuiInput-underline:before': {
                borderBottom: 'none',
            },
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        errors: {
            fontSize: '0.75rem',
            color: '#f44336',
            marginBottom: theme.spacing(2),
        },
        confirmContentItem: {
            fontSize: 14,
            color: '#8F95AF',
            display: 'flex',
            '& span': {
                flex: 1,
                whiteSpace: 'nowrap',
            },
            '& + &': {
                marginTop: theme.spacing(1),
            },
        },
        switchLabelRoot: {
            flexDirection: 'row',
            marginLeft: 0,
            gap: '8px',
        },
        switchLabel: {
            flex: 1,
            color: '#213061',
            fontSize: 13,
        },
        buttonField: {
            marginTop: theme.spacing(1),
        },
        buttonFieldContent: {
            margin: theme.spacing(1.25, 2),
            fontSize: 13,
            '& span': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                borderRadius: 4,
                padding: theme.spacing(0.5, 1),
                marginRight: theme.spacing(1),
                fontSize: '80%',
            },
        },
        operariosList: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: theme.spacing(1.5),
        },
        switches: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: theme.spacing(2),
        },
    }),
    { name: 'GeneralView' },
);

export function PlanificacionFormItem({ index, isSingle = false, planificacion, onRemove, setCurrentFieldComponent }) {
    const classes = useStyles();
    const { errors, setFieldValue: setFormFieldValue } = useFormikContext();
    const itemErrors = errors.planificaciones && errors.planificaciones[index];

    const [{ value: operarios }, , { setValue: setOperarios }] = useField(`planificaciones[${index}].operarios`);
    const [{ value: selfOperario }] = useField('selfOperario');

    useEffect(() => {
        if (!selfOperario) return;

        if (operarios.findIndex((op) => op.operario.id === selfOperario.id) < 0) {
            setOperarios([
                ...operarios,
                {
                    operario: selfOperario,
                    visible: true,
                    fecha_salida: null,
                },
            ]);
        }
    }, [operarios, selfOperario]);

    const planificacionIsPast = isPast(planificacion.fecha_fin);

    const header = (
        <Typography className={classes.label}>
            Planificación{isSingle ? '' : ` #${index + 1}`}
            {onRemove && !planificacionIsPast && (
                <ButtonDialog
                    title='¿Estás seguro que quieres borrar este periodo?'
                    okText='Borrar'
                    button={
                        <IconButton size='small'>
                            <DeleteIcon />
                        </IconButton>
                    }
                    onAccept={onRemove}
                />
            )}
        </Typography>
    );

    return (
        <>
            {header}
            <div className={classes.dateRange}>
                <DateRangePicker
                    fecha={planificacion.fecha_inicio}
                    allDay={planificacion.allDay}
                    fechaInicioKey={`planificaciones[${index}].fecha_inicio`}
                    fechaFinKey={`planificaciones[${index}].fecha_fin`}
                    showError={false}
                    toLabel={'a'}
                />
            </div>
            <div className={classes.switches}>
                <StartLabelSwitchFormik
                    name={`planificaciones[${index}].allDay`}
                    label='Todo el dia'
                    classes={{
                        root: classes.switchLabelRoot,
                        label: classes.switchLabel,
                    }}
                />
                <StartLabelSwitchFormik
                    name={`planificaciones[${index}].extender_automaticamente`}
                    label='Auto-extender'
                    classes={{
                        root: classes.switchLabelRoot,
                        label: classes.switchLabel,
                    }}
                />
            </div>
            <ButtonField
                style={{ marginTop: 16 }}
                className={classes.buttonFieldContent}
                value={planificacion.operarios.map((op) => op.operario.nombre).join(', ')}
                placeholder='Selecciona los operarios'
                onClick={() =>
                    setCurrentFieldComponent(OPERARIO, {
                        currentValue: planificacion.operarios.map((op) => op.operario),
                        setFieldValue: (operarios) => {
                            // console.log(operarios);
                            setFormFieldValue(
                                `planificaciones[${index}].operarios`,
                                operarios.map((operario) => {
                                    let op = planificacion.operarios.find((op) => op.operario.id === operario.id);
                                    if (!op)
                                        op = {
                                            visible: true,
                                            fecha_salida: null,
                                        };
                                    return {
                                        ...op,
                                        operario,
                                    };
                                }),
                            );
                        },
                    })
                }
            />
            <div className={classes.operariosList}>
                {planificacion.operarios.map((op, j) => (
                    <StartLabelSwitchFormik
                        key={j}
                        name={`planificaciones[${index}].operarios[${j}].visible`}
                        label={op.operario.nombre}
                        classes={{
                            root: classes.switchLabelRoot,
                            label: classes.switchLabel,
                        }}
                    />
                ))}
            </div>
            {itemErrors && (
                <div className={classes.errors}>
                    {Object.values(itemErrors).map((err, j) => (
                        <div key={j}>{err}</div>
                    ))}
                </div>
            )}
        </>
    );
}

PlanificacionFormItem.propTypes = {
    planificacion: PropTypes.any,
    index: PropTypes.number,
    errors: PropTypes.any,
    onRemove: PropTypes.any,
    isSingle: PropTypes.any,
    setCurrentFieldComponent: PropTypes.any,
};
