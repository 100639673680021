import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { tareasProvider } from '../../../api';
import { processTarea } from '../../../api/tareas';
import useSplash from '../../../contexts/SplashState';
import { BigButton } from '../../common/BigButton';
import { PageBodySection } from '../../common/PageBodySection';
import { FirmarView } from '../firmar/FirmarView';
import OpcionesContinuarView from '../opciones_continuar/OpcionesContinuarView';
import { GENERAL, PARTES_MANTENIMIENTO_RESUMEN, useTareaState } from '../TareaView';

const useStyles = makeStyles(
    (theme) => ({
        info: {
            fontSize: 10,
            color: '#818CAE',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(1),
            '& svg': {
                fontSize: 13,
                marginRight: theme.spacing(0.5),
            },
        },
        infoEquipoList: {
            backgroundColor: '#E3E6ED',
            position: 'relative',
            borderTop: '1px solid #D8DAE1',
            fontSize: 13,
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(1)}px`,
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        item: {
            borderBottom: '1px solid #D8DAE1',
            '&:first-of-type': {
                marginTop: -8,
            },
        },
        itemHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2, 3),
        },
        title: {
            flex: 1,
            fontSize: 13,
            fontWeight: 500,
            '& strong': {
                marginRight: theme.spacing(0.5),
            },
        },
        incidenciasSeccionTitle: {
            padding: theme.spacing(1, 3),
            fontSize: 14,
            backgroundColor: '#4D8AFF',
            color: 'white',
            fontWeight: 'bold',
        },
        equipoResultadoItem: {
            backgroundColor: '#E3E6ED',
            color: '#45486E',
            fontSize: 12,
            '&>div': {
                padding: theme.spacing(2, 3),
                borderTop: '1px solid #D8DAE1',
            },
        },
        equipoResultadoAcciones: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            '& strong': {
                marginRight: theme.spacing(0.5),
            },
        },
        signature: {
            border: '1px dashed #bababa',
            borderRadius: 4,
            maxHeight: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            '& img': {
                margin: theme.spacing(2),
                objectFit: 'cover',
                overflow: 'hidden',
            },
        },
        signatureName: {
            fontSize: 12,
            color: '#818cae',
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'PartesMantenimientoResumenView' },
);

export const PartesMantenimientoResumenView = () => {
    const classes = useStyles();
    const {
        id,
        tarea,
        setTarea,
        setView,
        viewParams: { firma: firmaSinGuardar },
    } = useTareaState();
    const [preguntas, setPreguntas] = useState([]);
    const [expandedSeccion, setExpandedSeccion] = useState(null);
    const { showCustomComponent } = useSplash();

    const isInfoExpanded = expandedSeccion === 'info';

    useEffect(() => {
        tareasProvider.getCuestionariosTarea(id).then((cuestionarios) => {
            const preguntas = {};

            cuestionarios.forEach((cuestionario) => {
                cuestionario.secciones.forEach(({ preguntas: preguntasSeccion, ...seccion }) => {
                    preguntasSeccion.forEach((pregunta) => {
                        preguntas[pregunta.id] = {
                            ...pregunta,
                            ...seccion,
                        };
                    });
                });
            });

            setPreguntas(preguntas);
        });
    }, [id]);

    const partesMantenimiento = useMemo(
        () =>
            tarea.partes_mantenimiento.map((parteMantenimiento) => ({
                id: parteMantenimiento.id,
                equipo: parteMantenimiento.equipo,
                respuestas: parteMantenimiento.respuestas.filter((respuesta) => respuesta.notas),
                notas: parteMantenimiento.notas,
            })),
        [tarea.partes_mantenimiento],
    );

    if (preguntas.length === 0) return null;

    const partesMantenimientoRevisar = partesMantenimiento.filter((p) => p.respuestas.length > 0);

    const firma = firmaSinGuardar || tarea.partes_mantenimiento[0].firma_cliente;
    const isFirmaExistente = firma && firma.image_url;

    return (
        <>
            <div className={clsx(classes.item, classes.infoEquipoItem)}>
                <div className={classes.itemHeader} onClick={() => setExpandedSeccion(isInfoExpanded ? null : 'info')}>
                    <Typography className={classes.title}>Equipos revisados</Typography>
                    {isInfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <Collapse in={isInfoExpanded}>
                    <div className={classes.infoEquipoList}>
                        {partesMantenimiento.map((parteMantenimiento) => (
                            <div key={parteMantenimiento.id}>
                                <span>{parteMantenimiento.equipo.nombre}</span>
                                <span>{parteMantenimiento.respuestas.length > 0 ? 'Ver notas' : 'OK'}</span>
                            </div>
                        ))}
                    </div>
                </Collapse>
            </div>
            {partesMantenimientoRevisar.length > 0 && (
                <>
                    <div className={clsx(classes.item, classes.incidenciasSeccionTitle)}>Detalles a destacar</div>
                    {partesMantenimientoRevisar.map((parteMantenimiento) => {
                        const isThisExpanded = expandedSeccion === parteMantenimiento.id;
                        return (
                            <div key={parteMantenimiento.id} className={classes.item}>
                                <div
                                    className={classes.itemHeader}
                                    onClick={() => setExpandedSeccion(isThisExpanded ? null : parteMantenimiento.id)}
                                >
                                    <Typography className={classes.title}>
                                        {parteMantenimiento.equipo.nombre}
                                    </Typography>
                                    {isThisExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </div>
                                <Collapse in={isThisExpanded}>
                                    <div className={classes.equipoResultadoItem}>
                                        {parteMantenimiento.notas && <div>{parteMantenimiento.notas}</div>}
                                        {parteMantenimiento.respuestas.map((respuesta, i) => {
                                            let valor = respuesta.valor;

                                            const pregunta = preguntas[respuesta.pregunta_id];
                                            if (pregunta.tipo === 'TEXT') {
                                                const valores = valor.split(',');
                                                valor = pregunta.columnas
                                                    .map((columna, i) =>
                                                        valores[i] ? `${columna}: ${valores[i]}` : null,
                                                    )
                                                    .filter(Boolean)
                                                    .join(' | ');
                                            }

                                            return (
                                                <div key={i} className={classes.equipoResultadoAcciones}>
                                                    <div>
                                                        <strong>Acción:</strong>
                                                        {pregunta.texto}
                                                    </div>
                                                    <div>
                                                        <strong>Resultado:</strong>
                                                        {valor}
                                                    </div>
                                                    <div>
                                                        <strong>Notas:</strong>
                                                        {respuesta.notas}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Collapse>
                            </div>
                        );
                    })}
                </>
            )}
            {firma ? (
                <PageBodySection style={{ marginTop: 'auto' }}>
                    <Typography variant='h4'>Firma del cliente</Typography>
                    <div className={classes.signature}>
                        <img src={firma.image_url ?? firma.imagen} />
                    </div>
                    <Typography className={classes.signatureName}>Firmado por: {firma.nombre}</Typography>
                    {!isFirmaExistente && (
                        <BigButton
                            onClick={() => {
                                tareasProvider.validarMantenimiento(id, firma).then((tarea) => {
                                    setTarea(processTarea(tarea));

                                    showCustomComponent(({ closeSplash }) => (
                                        <OpcionesContinuarView
                                            onClose={() => {
                                                setView(GENERAL, {});
                                                closeSplash();
                                            }}
                                        />
                                    ));
                                });
                            }}
                            style={{ width: '100%', marginBottom: 0 }}
                        >
                            VALIDAR
                        </BigButton>
                    )}
                </PageBodySection>
            ) : (
                <BigButton
                    style={{ margin: 'auto 24px 24px 24px' }}
                    onClick={() => {
                        showCustomComponent(({ closeSplash }) => (
                            <FirmarView
                                onSave={(firma) => {
                                    setView(PARTES_MANTENIMIENTO_RESUMEN, {
                                        firma,
                                    });
                                    closeSplash();
                                }}
                                onClose={closeSplash}
                            />
                        ));
                    }}
                >
                    FIRMAR MANTENIMIENTO
                </BigButton>
            )}
        </>
    );
};
