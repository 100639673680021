import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import isBefore from 'date-fns/isBefore';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../api';
import { processTareaForList } from '../../api/tareas';
import Navigation from '../common/Navigation';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import TabbedFilter from '../TabbedFilter';
import TareaCard from '../TareaCard';
import { TextField } from './common/TextField';

export const estadoLabels = {
    PENDIENTE: 'Nuevo',
    EN_PROGRESO: 'En progreso',
};

const filters = [
    {
        label: 'Todo',
        value: null,
    },
    ...Object.entries(estadoLabels).map(([value, label]) => ({ label, value })),
];

const useStyles = makeStyles(
    (theme) => ({
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        body: {
            paddingBottom: 80,
        },
    }),
    { name: 'TareaList' },
);

export const TareaList = () => {
    const [expanded, setExpanded] = useState(true);
    const classes = useStyles();
    const [tareas, setTareas] = useState(null);
    const [stateFilter, setStateFilter] = useState(null);
    const [textFilter, setTextFilter] = useState('');
    const history = useHistory();

    useEffect(() => {
        tareasProvider.getAll('pendientes').then((tareas) => {
            const tareaList = tareas.map(processTareaForList).reduce((tareaList, processedTareas) => {
                tareaList.push(...processedTareas);
                return tareaList;
            }, []);

            tareaList.sort((a, b) => (isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1));

            setTareas(tareaList);
        });
    }, []);

    const filteredTareas = tareas
        ? tareas.filter((tarea) => {
              if (stateFilter !== null && tarea.estado !== stateFilter) return false;

              if (textFilter === '') return true;

              const upperFilter = textFilter.toUpperCase();

              return (
                  tarea.descripcion.toUpperCase().includes(upperFilter) ||
                  tarea.cliente.toUpperCase().includes(upperFilter)
              );
          })
        : tareas;

    return (
        <React.Fragment>
            <PageHeader
                startButton={
                    <IconButton onClick={() => setExpanded((expanded) => !expanded)}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
                title='Mis servicios'
                onTitleClick={() => setExpanded((expanded) => !expanded)}
                // endButton={
                //     <DrawerMenu />
                // }
            >
                <Collapse in={expanded}>
                    <div className={classes.headerContent}>
                        <TabbedFilter
                            style={{ marginTop: 24 }}
                            onFilterClicked={setStateFilter}
                            filters={filters}
                            selectedFilter={stateFilter}
                        />
                        <TextField
                            value={textFilter}
                            onChange={(e) => setTextFilter(e.target.value)}
                            backgroundColor='rgba(255,255,255,0.15)'
                            startAdornment={<SearchIcon />}
                            placeholder={'Buscar'}
                            fieldStyle={{ margin: 0 }}
                            id='tareas-search-field'
                            variant='rounded'
                        />
                    </div>
                </Collapse>
            </PageHeader>
            <PageBody className={classes.body} bodyGap={8}>
                {filteredTareas ? (
                    <>
                        {filteredTareas.length === 0 ? (
                            <div
                                style={{
                                    fontSize: 17,
                                    color: '#818CAE',
                                    textAlign: 'center',
                                    marginTop: 64,
                                }}
                            >
                                No hay servicios que mostrar
                            </div>
                        ) : (
                            filteredTareas.map((tarea, i) => (
                                <TareaCard
                                    key={i}
                                    cliente={tarea.cliente}
                                    descripcion={tarea.descripcion}
                                    direccion={tarea.direccion_compacta}
                                    vehiculo={tarea.vehiculo}
                                    estado={tarea.estado}
                                    tiempo={tarea.tiempoHoy}
                                    tiempoAcumulado={tarea.tiempoAcumulado}
                                    onClick={() => history.push(`/tarea/${tarea.id}`)}
                                    numero={
                                        tarea.referencia_interna !== null && tarea.referencia_interna !== ''
                                            ? tarea.referencia_interna
                                            : tarea.numero
                                    }
                                    fechaInicio={tarea.fechaInicio}
                                    fechaFin={tarea.fechaFin}
                                    numOperarios={tarea.numOperarios}
                                    tipo={tarea.tipo}
                                />
                            ))
                        )}
                    </>
                ) : (
                    <>
                        <Skeleton variant='rect' height={124} style={{ margin: '8px 0' }} />
                        <Skeleton variant='rect' height={124} style={{ margin: '8px 0' }} />
                        <Skeleton variant='rect' height={124} style={{ margin: '8px 0' }} />
                    </>
                )}
            </PageBody>
            <Navigation />
        </React.Fragment>
    );
};
