import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useState } from 'react';

/* Define a context and a reducer for updating the context */
const SplashContext = createContext();

const defaultState = {
    open: false,
    icon: <CheckCircleIcon />,
    title: '',
    description: null,
    buttonText: 'Volver',
    onClick: null,
};

const useStyles = makeStyles(
    (theme) => ({
        wrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
        },
        root: {
            background: 'linear-gradient(180deg, #4C60FE 0%, #4DA1FF 100%), #4C60FE',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
        },
        icon: {
            width: 64,
            height: 64,
        },
        title: {
            fontSize: 24,
            marginTop: theme.spacing(2),
        },
        description: {
            fontSize: 14,
            textAlign: 'center',
            margin: theme.spacing(2, 4),
        },
        button: {
            marginTop: theme.spacing(3),
            color: 'white',
            background: '#213061',
            borderRadius: 21,
            height: 42,
            width: 120,
            fontSize: 14,
            textTransform: 'none',
        },
    }),
    { name: 'Splash' },
);

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const SplashProvider = ({ children }) => {
    const [state, setState] = useState({ ...defaultState });
    const classes = useStyles();

    return (
        <SplashContext.Provider value={[state, setState]}>
            {state.open && (
                <div className={classnames(classes.wrapper, { [classes.root]: !state.customComponent })}>
                    {state.customComponent ? (
                        state.customComponent({ closeSplash: () => setState((state) => ({ ...state, open: false })) })
                    ) : (
                        <>
                            {React.cloneElement(state.icon, { className: classes.icon })}
                            <Typography className={classes.title}>{state.title}</Typography>
                            {state.description && (
                                <Typography className={classes.description}>{state.description}</Typography>
                            )}
                            <Button
                                onClick={() => {
                                    setState((state) => ({ ...state, open: false }));
                                    state.onClick && state.onClick();
                                }}
                                className={classes.button}
                            >
                                {state.buttonText}
                            </Button>
                        </>
                    )}
                </div>
            )}
            {children}
        </SplashContext.Provider>
    );
};

/*
Default export is a hook that provides a simple API for updating the global state.
This also allows us to keep all of this state logic in this one file
*/

const useSplash = () => {
    const [state, setState] = useContext(SplashContext);

    const showCustomComponent = useCallback(
        function (customComponent) {
            setState({
                open: true,
                customComponent,
            });
        },
        [setState],
    );

    const showSplash = useCallback(
        function ({ icon, title, description, buttonText, onClick }) {
            setState({
                open: true,
                icon: icon || defaultState.icon,
                title: title || defaultState.title,
                description,
                buttonText: buttonText || defaultState.buttonText,
                onClick,
            });
        },
        [setState],
    );

    return {
        open: state.open,
        showSplash,
        showCustomComponent,
    };
};

export default useSplash;

SplashProvider.propTypes = {
    children: PropTypes.any,
};
