import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AlarmIcon from '@material-ui/icons/Alarm';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import isSameDay from 'date-fns/isSameDay';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuthState from '../../../contexts/AuthState';
import { formatDate, formatTiempo, formatTime, isAllDay } from '../../../utils';
import Button from '../../common/Button';
import SlidingDialog from '../../common/SlidingDialog';
import CalendarioIcon from '../../icons/Calendario';
import OperariosIcon from '../../icons/Operarios';
import EditarTarea from '../editar/EditarTarea';
import InfoChip from './InfoChip';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
        row: {
            display: 'flex',
            alignItems: 'flex-end',
            overflowX: 'hidden',
        },
        cliente: {
            fontSize: 20,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
        },
        descripcion: {
            fontSize: 16,
            fontWeight: 'normal',
        },
        tiempo: {
            textAlign: 'right',
            marginTop: 'auto',
            fontSize: 20,
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'column',
        },
        tiempoLabel: {
            fontSize: 12,
            alignSelf: 'flex-start',
        },
        infoChips: {
            margin: theme.spacing(2, 0, 4, 0),
            display: 'flex',
            flexWrap: 'wrap',
            gap: `0px ${theme.spacing(3)}px`,
        },
        actions: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'TareaResumenDialog' },
);

export default function TareaResumenDialog({ open, onClose, tarea, hideTiempo = false, onUpdate }) {
    const classes = useStyles();
    const history = useHistory();
    const [editTarea, setEditTarea] = React.useState(false);
    const snackbar = useSnackbar();

    const { fechaInicio, fechaFin } = tarea;

    const {
        isCompany,
        userInfo: {
            preferencias: { usar_hora_visita: usarHoraVisita },
        },
    } = useAuthState();

    const hasFechas = fechaInicio && fechaFin;
    const allDay = isAllDay(fechaInicio, fechaFin);
    let fechas = '';
    if (hasFechas) {
        if (allDay) {
            if (isSameDay(fechaInicio, fechaFin)) {
                fechas = formatDate(fechaInicio);
            } else {
                fechas = `${formatDate(fechaInicio)} a ${formatDate(fechaFin)}`;
            }
        } else {
            fechas = `${formatDate(fechaInicio)} de ${formatTime(fechaInicio)} a ${formatTime(fechaFin)}`;
        }
    }

    return (
        <>
            {isCompany && (
                <Dialog fullScreen open={editTarea}>
                    <EditarTarea
                        tareaId={tarea ? tarea.id : null}
                        onClose={() => setEditTarea(false)}
                        onSave={(tarea) => {
                            setEditTarea(false);
                            onClose();
                            snackbar.showMessage('El servicio se ha editado con éxito');
                            onUpdate && onUpdate(tarea);
                        }}
                        onDelete={() => {
                            setEditTarea(false);
                            onUpdate && onUpdate();
                        }}
                    />
                </Dialog>
            )}
            <SlidingDialog open={open} onClose={onClose} title={`Servicio ${tarea.numero}`}>
                <div className={classes.row}>
                    <Typography className={classes.cliente}>{tarea.cliente}</Typography>

                    {!hideTiempo && (
                        <Typography component='div' className={classes.tiempo}>
                            {formatTiempo(tarea.tiempoHoy)}
                        </Typography>
                    )}
                </div>
                <Typography className={classes.descripcion}>{tarea.descripcion}</Typography>
                <div className={classes.infoChips}>
                    <InfoChip Icon={LocationOnIcon} fullWidth>
                        {tarea.direccion_compacta}
                    </InfoChip>
                    <InfoChip Icon={CalendarioIcon} fullWidth>
                        {hasFechas ? fechas : tarea.libre ? 'Servicio libre' : 'Fuera de planificación'}
                        {tarea.confirmada && !usarHoraVisita && allDay && ' | Confirmada'}
                    </InfoChip>
                    {!usarHoraVisita && tarea.confirmada && !allDay && (
                        <InfoChip Icon={AlarmIcon} fullWidth>
                            Visita a las {formatTime(tarea.fechaInicio)} |{' '}
                            {tarea.confirmada ? 'Confirmada' : 'No confirmada'}
                        </InfoChip>
                    )}
                    {tarea.fechaVisita && (
                        <InfoChip Icon={AlarmIcon} fullWidth>
                            Visita a las {formatTime(tarea.fechaVisita)} |{' '}
                            {tarea.confirmada ? 'Confirmada' : 'No confirmada'}
                        </InfoChip>
                    )}
                    {tarea.numOperarios > 1 && !tarea.operarios && (
                        <InfoChip Icon={OperariosIcon}>{`+${tarea.numOperarios - 1}`}</InfoChip>
                    )}
                    {tarea.vehiculo && (
                        <InfoChip Icon={DriveEtaIcon} fullWidth={tarea.operarios}>
                            {tarea.vehiculo.codigo} | {tarea.vehiculo.nombre}
                        </InfoChip>
                    )}
                    {tarea.operarios && (
                        <InfoChip Icon={OperariosIcon}>{tarea.operarios.map((op) => op.nombre).join(', ')}</InfoChip>
                    )}
                </div>
                <div className={classes.actions}>
                    {isCompany && (
                        <Button color='primary' outline rounded fullWidth onClick={() => setEditTarea(true)}>
                            Editar servicio
                        </Button>
                    )}
                    <Button color='primaryFilled' rounded fullWidth onClick={() => history.push(`/tarea/${tarea.id}`)}>
                        Ver servicio
                    </Button>
                </div>
            </SlidingDialog>
        </>
    );
}

TareaResumenDialog.propTypes = {
    hideTiempo: PropTypes.bool,
    onClose: PropTypes.any,
    open: PropTypes.any,
    tarea: PropTypes.any,
    onUpdate: PropTypes.any,
};
