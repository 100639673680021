import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import { formatISODate } from '../utils';
import DataProvider from './DataProvider';
import { addTiempo, createTiempo } from './tareas-functions';

export const empty = {
    descripcion: '',
    fecha: null,
    cliente: null,
    cliente_id: null,
    direccion: '',
    telefono: '',
    operarios: [],
    jornadas: [],
    materiales: [],
    estado: '',
    notas: [],
    fotos: [],
    explicacion: '',
    detalles: '',
    has_self: false,
    proyecto: null,
    adjuntos: [],
    albaranes: [],
    partes_mantenimiento: [],
    planificaciones: [],
    solicitudes_material: [],
    checklists_tarea: [],
    documentos: [],
    subtareas: [],
    numero: '',
    referencia_interna: '',
    urgente: false,
    libre: false,
};

export const estadoLabels = {
    PENDIENTE: 'Nuevo',
    SIN_PLANIFICAR: 'Nuevo',
    EN_PROGRESO: 'En progreso',
    FINALIZADA: 'Terminado',
};

export const gastosLabels = {
    dietas: 'Dietas',
    desplazamiento: 'Desplazamiento',
    parking: 'Parking',
};

export function processTareaForCalendar(tarea) {
    const fechas = tarea.fechas;

    let tiempoHoy = null;
    let tiempoAcumulado = null;

    const hoy = formatISODate(new Date());

    if (fechas) {
        if (fechas[hoy]) tiempoHoy = createTiempo(fechas[hoy].minutos);

        Object.values(fechas).forEach(({ minutos }) => {
            tiempoAcumulado = addTiempo(tiempoAcumulado, createTiempo(minutos));
        });
    }

    return {
        ...tarea,
        fechaInicio: new Date(tarea.fecha),
        fechaFin: new Date(tarea.fecha_fin),
        tiempoHoy,
        tiempoAcumulado,
        fechas,
    };
}

export function processTareaForList(tarea) {
    let tiempoHoy = createTiempo(tarea.tiempo_hoy);
    let tiempoAcumulado = createTiempo(tarea.tiempo_acumulado);

    if (tarea.jornadas) {
        const hoy = formatISODate(new Date());
        const jornadasPropias = tarea.jornadas.filter((jornada) => jornada.propia);
        const jornadaHoy = jornadasPropias.find((jornada) => jornada.fecha === hoy);
        tiempoAcumulado = jornadasPropias.reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);
        tiempoHoy = jornadaHoy ? jornadaHoy.tiempo : null;
    }

    const tareaBase = {
        ...tarea,
        tiempoHoy,
        tiempoAcumulado,
    };

    if (tarea.libre) {
        return [tareaBase];
    }

    const expandedPlanificaciones = tarea.planificaciones.map((planificacion) => ({
        ...tareaBase,
        fechaInicio: new Date(planificacion.fecha_inicio),
        fechaFin: new Date(planificacion.fecha_fin),
        fechaVisita: planificacion.fecha_visita ? new Date(planificacion.fecha_visita) : null,
        numOperarios: planificacion.operarios.filter((op) => op.visible).length,
        vehiculo: planificacion.vehiculo,
    }));

    if (expandedPlanificaciones.length === 0) {
        return [];
    }

    const noCaducadas = expandedPlanificaciones.filter((planificacion) => !isPast(planificacion.fechaFin));

    if (noCaducadas.length > 0) {
        return noCaducadas;
    }

    return [expandedPlanificaciones[0]];
}

export function processTarea({ jornadas, ...tarea }) {
    const hoy = formatISODate(new Date());

    const jornadasPropias = jornadas.filter((jornada) => jornada.propia);
    const jornadaHoy = jornadasPropias.find((jornada) => jornada.fecha === hoy);
    const tiempoAcumulado = jornadasPropias.reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);
    const tiempoAcumuladoHoy = jornadas
        .filter((jornada) => jornada.fecha === hoy)
        .reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);

    if (tarea.partes_mantenimiento) {
        tarea.partes_mantenimiento.sort((a, b) => {
            const refA = a.equipo.referencia || a.equipo.nombre;
            const refB = b.equipo.referencia || b.equipo.nombre;
            return refA < refB ? -1 : 1;
        });
    }

    tarea.disabled = false;
    if (tarea.estado === 'FINALIZADA') {
        tarea.disabled = true;
    } else {
        const operario = tarea.operarios.find((op) => op.is_self);
        if (operario) {
            tarea.disabled = !operario.planificaciones.some((p) => p.visible);
            // tarea.disabled = !operario.planificaciones.some(
            //     (p) => p.visible && (isToday(new Date(p.fecha_fin)) || isFuture(new Date(p.fecha_fin))),
            // );
        } else {
            tarea.disabled = true;
        }
    }

    tarea.isUltimoOperario = !tarea.operarios.some(
        (op) =>
            !op.is_self &&
            op.planificaciones.some(
                (p) => p.visible && (isToday(new Date(p.fecha_fin)) || isFuture(new Date(p.fecha_fin))),
            ),
    );

    jornadasPropias.forEach((jornada) => {
        jornada.notas.forEach((nota) => {
            nota.propia = true;
        });
        jornada.fotos.forEach((foto) => {
            foto.propia = true;
        });
    });

    return {
        ...tarea,
        fecha: new Date(tarea.fecha),
        firma: null,
        tareasNotas: null,
        tareasExplicaciones: null,
        jornadas,
        jornadaHoy,
        tiempoHoy: jornadaHoy ? jornadaHoy.tiempo : null,
        tiempoAcumulado,
        tiempoAcumuladoHoy,
        jornadasPropias,
    };
}

class TareasProvider extends DataProvider {
    constructor() {
        super('tareas', empty);
    }

    getTiempoOperarios = async (id, fecha) => {
        const selectedDate = fecha || formatISODate(new Date());
        return this.getAll(`${id}/tiempo-operarios/${selectedDate}`);
    };

    updateExplicacion = async (id, explicacion) => {
        return this.actionOnId(id, 'explicacion', { texto: explicacion }, { method: 'post' });
    };

    updateJornadaNotas = async (jornadaId, notas, incidencia = false) => {
        return this.getAll(`jornadas/${jornadaId}/notas`, {
            method: 'post',
            body: JSON.stringify({ texto: notas, incidencia }),
        });
    };

    updateJornadaExplicacion = async (jornadaId, notas) => {
        return this.getAll(`jornadas/${jornadaId}/explicacion`, {
            method: 'post',
            body: JSON.stringify({ texto: notas }),
        });
    };

    updateNotas = async (id, notas) => {
        return this.actionOnId(id, 'notas', { texto: notas }, { method: 'post' });
    };

    getCuestionariosParteMantenimiento = async (parteMantenimientoId) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/cuestionarios`);
    };

    getCuestionariosTarea = async (tareaId) => {
        return this.action(`${tareaId}/cuestionarios`);
    };

    setParteMantenimientoTerminado = async (parteMantenimientoId, terminado, firma, apto = false) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/terminado`, {
            method: 'post',
            body: JSON.stringify({ terminado, ...firma, apto }),
        });
    };

    setParteMantenimientoDefault = async (parteMantenimientoId) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/default`, { method: 'post' });
    };

    updateParteMantenimientoRespuesta = async (parteMantenimientoId, preguntaId, valueString) => {
        return this.action(`partes-mantenimiento/${parteMantenimientoId}/respuestas/${preguntaId}`, {
            method: 'post',
            body: JSON.stringify({ valor: valueString }),
        });
    };

    updateParteMantenimientoRespuestaNotas = async (parteMantenimientoId, preguntaId, notas) => {
        if (!preguntaId) {
            return this.action(`partes-mantenimiento/${parteMantenimientoId}/notas`, {
                method: 'post',
                body: JSON.stringify({ notas }),
            });
        }

        return this.action(`partes-mantenimiento/${parteMantenimientoId}/respuestas/${preguntaId}`, {
            method: 'post',
            body: JSON.stringify({ notas }),
        });
    };

    validarMantenimiento = async (tareaId, firma) => {
        return this.action(`${tareaId}/validar-mantenimiento`, { method: 'post', body: JSON.stringify(firma) });
    };

    notificarIncidencia = async (jornadaId) => {
        return this.action(`jornadas/${jornadaId}/notificar-incidencia`, { method: 'post' });
    };

    deleteFoto = async (fotoId) => {
        return this.getAll(`fotos/${fotoId}`, { method: 'delete' });
    };
}

export const dataProvider = new TareasProvider();
