import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { preparacionesMaterialProvider } from '../../../../api';
import useAuthState from '../../../../contexts/AuthState';
import useSplash from '../../../../contexts/SplashState';
import { PageBodySection } from '../../../common/PageBodySection';
import { useTareaState } from '../../TareaView';
import { AddSolicitud } from './AddSolicitud';
import { AddSolicitudLineas } from './AddSolicitudLineas';
import ChooseSolicitudType from './ChooseSolicitudType';
import NotificarSolicitud from './NotificarSolicitud';
import { SolicitudMaterial } from './SolicitudMaterial';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            '&:last-of-type': {
                marginBottom: theme.spacing(2),
            },
        },
        addSolicitud: {
            display: 'flex',
            // margin: theme.spacing(2, 0, 0, 0),
        },
        addSolicitudTexto: {
            flex: 1,
            boxSizing: 'border-box',
            borderRadius: 4,
            border: '1px dashed #B5BCD1',
            display: 'flex',
            alignItems: 'center',
            color: '#818CAE',
            fontSize: 12,
            paddingLeft: theme.spacing(1.5),
        },
        addButton: {
            marginLeft: 8,
            height: 40,
            minWidth: 44,
            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
        },
    }),
    { name: 'SolicitudesMaterial' },
);

export default function SolicitudesMaterial({ customAddComponent, headerComponent }) {
    const classes = useStyles();
    const history = useHistory();
    const { showCustomComponent } = useSplash();
    const [notificarSolicitudId, setNotificarSolicitudId] = useState(false);
    const snackbar = useSnackbar();
    const {
        userInfo: {
            preferencias: {
                enviar_email_solicitud_material: enviarEmail,
                usar_preparacion_materiales_lista: usarPreparacionMaterialesLista = false,
            },
        },
    } = useAuthState();

    const match = useRouteMatch();

    const { id: tareaId, tarea, setTarea, setViewParams, viewParams } = useTareaState();
    const { updatedSolicitudId = null, isNew = false, solicitud = null } = viewParams;
    useEffect(() => {
        if (!match.isExact && solicitud === null) {
            setViewParams({ ...viewParams, solicitud: null });
            history.replace(match.url);
        }
    }, [match, solicitud]);

    function onSave(solicitudId, isNew = false) {
        if (!solicitudId) return;
        if (enviarEmail) setNotificarSolicitudId(solicitudId);

        preparacionesMaterialProvider.getSolicitudMaterial(solicitudId).then((solicitud) => {
            setTarea((tarea) => ({
                ...tarea,
                solicitudes_material: isNew
                    ? [solicitud, ...tarea.solicitudes_material]
                    : tarea.solicitudes_material.map((s) => (s.id === solicitudId ? solicitud : s)),
            }));
        });
    }

    useEffect(() => {
        if (!updatedSolicitudId || !match.isExact) return;

        setViewParams({ updatedSolicitudId: null, isNew: false });
        onSave(updatedSolicitudId, isNew);
    }, [updatedSolicitudId, isNew, match.isExact]);

    const canAdd = tarea.solicitudes_material.filter((s) => s.operario.is_self).every((s) => s.procesada) || true;

    if (!match.isExact && solicitud) {
        return <AddSolicitudLineas setViewParams={setViewParams} solicitud={solicitud} tareaId={tareaId} />;
    }

    function onAddSolicitud() {
        if (!usarPreparacionMaterialesLista) {
            showCustomComponent(({ closeSplash }) => (
                <AddSolicitud
                    onClose={(solicitudId) => {
                        closeSplash();
                        onSave(solicitudId, true);
                    }}
                    headerComponent={headerComponent}
                />
            ));
        } else {
            showCustomComponent(({ closeSplash }) => (
                <ChooseSolicitudType
                    onClose={closeSplash}
                    onListaClicked={() => {
                        closeSplash();

                        setViewParams({
                            tareaId,
                            solicitud: { id: null, notas: '', lineas: [] },
                        });
                        history.push(`${match.url}/new`);
                    }}
                    onTextoClicked={() => {
                        showCustomComponent(({ closeSplash }) => (
                            <AddSolicitud
                                onClose={(solicitudId) => {
                                    closeSplash();
                                    onSave(solicitudId, true);
                                }}
                                headerComponent={headerComponent}
                            />
                        ));
                    }}
                />
            ));
        }
    }

    return (
        <>
            <NotificarSolicitud
                open={Boolean(notificarSolicitudId)}
                onClose={() => setNotificarSolicitudId(false)}
                onAccept={() => {
                    setNotificarSolicitudId(false);
                    return preparacionesMaterialProvider
                        .notificarSolicitudMaterial(notificarSolicitudId)
                        .then(() => snackbar.showMessage('Se ha enviado la notificación con éxito'));
                }}
            />
            {canAdd &&
                (customAddComponent ? (
                    React.cloneElement(customAddComponent, { onClick: onAddSolicitud })
                ) : (
                    <PageBodySection className={classes.root}>
                        <div className={classes.addSolicitud}>
                            <Typography className={classes.addSolicitudTexto}>
                                No hay solicitudes de material
                            </Typography>

                            <Button className={classes.addButton} onClick={onAddSolicitud}>
                                <AddIcon style={{ color: 'white', fontSize: 20 }} />
                            </Button>
                        </div>
                    </PageBodySection>
                ))}
            {tarea.solicitudes_material.map((solicitud, i) => (
                <SolicitudMaterial
                    solicitud={solicitud}
                    key={i}
                    disabled={!solicitud.operario.is_self || solicitud.procesada}
                    onSave={onSave}
                    onClick={() => {
                        if (solicitud.lineas.length > 0) {
                            setViewParams({ tareaId, solicitud });
                            history.push(`${match.url}/${solicitud.id}`);
                        } else {
                            showCustomComponent(({ closeSplash }) => (
                                <AddSolicitud
                                    id={solicitud.id}
                                    currentSolicitud={solicitud.notas}
                                    onClose={(solicitudId) => {
                                        closeSplash();
                                        onSave(solicitudId);
                                    }}
                                    headerComponent={headerComponent}
                                />
                            ));
                        }
                    }}
                />
            ))}
        </>
    );
}

SolicitudesMaterial.propTypes = {
    customAddComponent: PropTypes.any,
    headerComponent: PropTypes.any,
};
