import { makeStyles } from '@material-ui/core/styles';
import * as classnames from 'classnames';
import PropTypes from 'prop-types';
import { formatTiempo } from '../../utils';

const useStyles = makeStyles((theme) => ({
    resumenTiempo: {
        display: 'flex',
        color: 'white',
        '&>div': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
}));

export function ResumenTiempo({ laborables, realizadas, extras, className, style }) {
    const styles = useStyles();

    if (!laborables || !realizadas || !extras) return null;

    return (
        <div className={classnames(styles.resumenTiempo, className)} style={style}>
            <div>
                <strong>{typeof laborables === 'number' ? `${laborables}h` : formatTiempo(laborables)}</strong>
                Laborables
            </div>
            <div>
                <strong>{formatTiempo(realizadas)}</strong>
                Realizadas
            </div>
            <div>
                <strong>{formatTiempo(extras)}</strong>
                Extras
            </div>
        </div>
    );
}

ResumenTiempo.propTypes = {
    className: PropTypes.any,
    extras: PropTypes.any,
    laborables: PropTypes.any,
    realizadas: PropTypes.any,
    style: PropTypes.any,
};
