import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderRadius: 20,
            fontWeight: 500,
            fontSize: 9,
            color: 'white',
            textTransform: 'uppercase',
            backgroundColor: (props) => theme.palette[props.color]?.main ?? theme.palette.error.main,
            padding: theme.spacing(0.5, 1),
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            '& .MuiSvgIcon-root': {
                fontSize: 14,
            },
        },
    }),
    { name: 'LabelChip' },
);

export default function LabelChip({ label, icon, color, className }) {
    const classes = useStyles({ color });
    return (
        <span className={clsx(classes.root, className)}>
            {icon}
            {label}
        </span>
    );
}

LabelChip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.any,
    className: PropTypes.string,
};
