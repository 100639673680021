import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});
const Dialog = withStyles(
    (theme) => ({
        scrollPaper: {
            alignItems: 'flex-end',
        },
        paper: {
            width: '100%',
            margin: 0,
            borderRadius: 0,
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
            backgroundColor: theme.palette.neutral.dark,
            color: 'white',
            // '& *': {
            //     color: 'inherit',
            // },
        },
    }),
    { name: 'Dialog' },
)(MuiDialog);

const DialogTitle = withStyles(
    (theme) => ({
        root: {
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            '& .MuiTypography-root': {
                flex: 1,
                color: 'inherit',
                fontSize: 14,
                fontWeight: 'bold',
                textTransform: 'capitalize',
            },
            '& .MuiIconButton-root': {
                color: 'inherit',
                padding: theme.spacing(0.5),
            },
        },
    }),
    { name: 'DialogTitle' },
)(MuiDialogTitle);

const DialogContent = withStyles(
    (theme) => ({
        root: {
            padding: (props) => (props.removePadding ? 0 : 16),
        },
    }),
    { name: 'DialogContent' },
)(({ removePadding, ...props }) => <MuiDialogContent {...props} />);

export default function SlidingDialog({
    open,
    title,
    removeTypography = false,
    onClose,
    removePadding = false,
    children,
    extraHeaderComponent,
    className,
}) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            classes={{ paper: className }}
        >
            <DialogTitle disableTypography>
                {removeTypography ? title : <Typography>{title}</Typography>}
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {extraHeaderComponent}
            <DialogContent removePadding={removePadding}>{children}</DialogContent>
        </Dialog>
    );
}

SlidingDialog.propTypes = {
    children: PropTypes.any,
    onClose: PropTypes.any,
    open: PropTypes.any,
    removePadding: PropTypes.bool,
    title: PropTypes.any,
    removeTypography: PropTypes.bool,
    className: PropTypes.any,
    extraHeaderComponent: PropTypes.any,
};
