import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailIcon from '@material-ui/icons/Email';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function NotificarIncidencia({ open, onAccept, onClose }) {
    const [sending, setSending] = useState(false);
    return (
        <Dialog open={open}>
            <DialogTitle
                disableTypography
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 600,
                }}
            >
                <EmailIcon style={{ fontSize: 60, color: '#e2e2e2', marginBottom: 8 }} />
                ¿Quieres notificar a administración la incidencia?
            </DialogTitle>
            <DialogActions style={{ justifyContent: 'space-between', padding: 20 }}>
                <Button onClick={onClose}>No</Button>
                <Button
                    onClick={() => {
                        setSending(true);

                        onAccept().then(() => {
                            setSending(false);
                            onClose();
                        });
                    }}
                    variant='contained'
                    color='primary'
                    autoFocus
                    disabled={sending}
                >
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NotificarIncidencia.propTypes = {
    onAccept: PropTypes.any,
    onClose: PropTypes.any,
    open: PropTypes.any,
};
