import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { tareasProvider } from '../../../api';
import useSplash from '../../../contexts/SplashState';
import { format } from '../../../utils';
import { ButtonDialog } from '../../common/ButtonDialog';
import { EditDialogView } from '../../common/EditDialogView';
import { PageBody } from '../../common/PageBody';
import { PageHeader } from '../../common/PageHeader';
import NotasIcon from '../../icons/Notas';
import { NOTAS, TareaHeader, useTareaState } from '../TareaView';

const useStyles = makeStyles(() => ({
    wrapper: {
        width: '100%',
        height: '100%',
        '& .react-transform-component': {
            height: '100%',
        },
    },
}));

export function FotoSlideshow({ tareaId, fotos, fotoIdx, onBack, onDelete }) {
    const classes = useStyles();

    const [currentIdx, setCurrentIdx] = useState(fotoIdx);

    const { showCustomComponent } = useSplash();
    useEffect(() => setCurrentIdx(fotoIdx), [fotoIdx]);

    const foto = fotos[currentIdx];

    if (!foto) return null;

    const author = foto.operario;
    // eslint-disable-next-line quotes
    const date = format(new Date(foto.fecha), "dd/MM/yyyy' a las 'HH:mm");

    return (
        <React.Fragment>
            <PageHeader
                title={`Foto ${currentIdx + 1} de ${fotos.length}`}
                startButton={
                    <IconButton onClick={onBack}>
                        <ArrowBackIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Typography variant='h2' style={{ flex: 1 }}>
                        por {author}
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3' style={{ flex: 1 }}>
                        {date}
                    </Typography>

                    {foto.propia && onDelete && (
                        <>
                            <ButtonDialog
                                title='¿Estás seguro que quieres borrar esta foto?'
                                okText='Borrar'
                                button={
                                    <IconButton size='small' disabled={!foto.propia}>
                                        <DeleteIcon style={{ color: 'white' }} />
                                    </IconButton>
                                }
                                onAccept={() => {
                                    tareasProvider.deleteFoto(foto.id).then((res) => {
                                        if (onDelete) return onDelete(res);
                                    });
                                }}
                            />
                            <IconButton
                                size='small'
                                disabled={!foto.propia}
                                onClick={() => {
                                    showCustomComponent(({ closeSplash }) => (
                                        <EditDialogView
                                            header={<TareaHeader title='Pie de foto' />}
                                            onClose={() => {
                                                closeSplash();
                                            }}
                                            currentText={foto.texto}
                                            onChange={(nota) =>
                                                tareasProvider
                                                    .actionOnId(tareaId, `fotos/${foto.id}`, { texto: nota })
                                                    .then((res) => {
                                                        if (onDelete) return onDelete(res);
                                                    })
                                            }
                                        />
                                    ));
                                }}
                            >
                                <NotasIcon style={{ color: 'white' }} />
                            </IconButton>
                        </>
                    )}
                </div>
                {foto.texto && (
                    <Typography variant='h3' style={{ marginTop: 16, whiteSpace: 'pre-wrap' }}>
                        {foto.texto}
                    </Typography>
                )}
            </PageHeader>
            <PageBody paddingTop={0}>
                <TransformWrapper
                    options={{
                        limitToBounds: true,
                        limitToWrapper: true,
                    }}
                    doubleClick={{ mode: 'reset' }}
                    onPanningStop={({ positionX, scale }) => {
                        const limitX = document.body.clientWidth * 0.3;
                        const margin = 10;
                        const minX = -(limitX + document.body.clientWidth * (scale - 1) - margin);
                        const maxX = limitX - margin;

                        if (positionX <= minX && currentIdx < fotos.length - 1) setCurrentIdx((idx) => idx + 1);

                        if (positionX >= maxX && currentIdx > 0) setCurrentIdx((idx) => idx - 1);
                    }}
                >
                    {() => {
                        return (
                            <>
                                <div className={classes.wrapper}>
                                    <TransformComponent>
                                        <img
                                            src={foto.image_url}
                                            alt={`por ${author} el ${date}`}
                                            style={{ width: '100%', objectFit: 'cover' }}
                                        />
                                    </TransformComponent>
                                </div>
                            </>
                        );
                    }}
                </TransformWrapper>
            </PageBody>
        </React.Fragment>
    );
}

FotoSlideshow.propTypes = {
    fotoIdx: PropTypes.any,
    fotos: PropTypes.any,
    onBack: PropTypes.any,
    onDelete: PropTypes.any,
    tareaId: PropTypes.any,
};

export function FotoSlideshowView() {
    const { id, viewParams, setView, setFotos, setTarea } = useTareaState();
    const { fotos, fotoIdx } = viewParams;

    return (
        <FotoSlideshow
            tareaId={id}
            fotos={fotos}
            fotoIdx={fotoIdx}
            onBack={() => setView(NOTAS)}
            onDelete={({ jornada_id: jornadaId, fotos }) => {
                setTarea((tarea) => ({
                    ...tarea,
                    jornadas: tarea.jornadas.map((j) =>
                        j.id !== jornadaId
                            ? j
                            : {
                                  ...j,
                                  fotos,
                              },
                    ),
                }));
                setFotos(fotos);
            }}
        />
    );
}
