import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import * as classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { tareasProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import { formatDate, formatTiempo } from '../../utils';
import { PageHeader } from '../common/PageHeader';
import { BigSwitch } from '../Switch';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        '& li': {
            justifyContent: 'center',
        },
        '& li span': {
            display: 'none',
        },
    },
    title: {
        textAlign: 'center',
        marginTop: theme.spacing(6),
        position: 'relative',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '26px',
    },
    subtitle: {
        fontSize: 16,
        lineHeight: '24px',
        textAlign: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    operarioList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    operarioItem: {
        display: 'flex',
        alignItems: 'center',
    },
    operarioItemLabel: {
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: 4,
        justifyContent: 'space-between',
        color: '#818CAE',
        fontSize: 13,
        fontWeight: 500,
        padding: theme.spacing(1.5, 2),
        flex: 1,
        marginRight: theme.spacing(1),
        opacity: 0.5,
        '&.checked': {
            opacity: 1,
        },
    },
}));

export default function UpdateOperariosTime({ closeSplash, id, fecha, tiempo, onSave }) {
    const classes = useStyles();

    const [tiempoOperarios, setTiempoOperarios] = useState(null);
    const [selectedOperarios, setSelectedOperarios] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        tareasProvider.getTiempoOperarios(id, fecha).then((result) => {
            if (result.length === 0) {
                onSave();
                return;
            }

            setTiempoOperarios(result);
            // setSelectedOperarios(result.filter(r => r.minutos === null).map(r => r.id));
        });
    }, [id, fecha]);

    function toggleOperario(id) {
        setSelectedOperarios((selectedOperarios) => {
            const idx = selectedOperarios.indexOf(id);
            if (idx < 0) return [...selectedOperarios, id];

            return [...selectedOperarios.slice(0, idx), ...selectedOperarios.slice(idx + 1)];
        });
    }

    if (tiempoOperarios === null) return <PageHeader fill />;

    return (
        <PageHeader
            title={'Actualizar tiempo de otros operarios'}
            fill
            startButton={
                <IconButton onClick={closeSplash}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            }
            endButton={
                <IconButton
                    onClick={() => {
                        setSaving(true);
                        onSave(selectedOperarios);
                    }}
                    disabled={saving}
                >
                    <DoneIcon style={{ color: 'white' }} />
                </IconButton>
            }
        >
            <Typography variant='h3' className={classes.title}>
                Has registrado {formatTiempo(tiempo)} en el servicio a fecha {formatDate(fecha || new Date())}
            </Typography>

            <Typography variant='h3' className={classes.subtitle}>
                Selecciona los operarios que han realizado el mismo tiempo que tu en esta fecha para actualizar también
                su tiempo.
            </Typography>

            <div className={classes.operarioList}>
                {tiempoOperarios.map((operario) => {
                    const checked = selectedOperarios.includes(operario.id);
                    return (
                        <div key={operario.id} className={classes.operarioItem}>
                            <div className={classnames(classes.operarioItemLabel, { checked })}>
                                <span>{operario.nombre}</span>
                                <span>{formatTiempo(checked ? tiempo : createTiempo(operario.minutos))}</span>
                            </div>
                            <BigSwitch checked={checked} onChange={(ev) => toggleOperario(operario.id)} />
                        </div>
                    );
                })}
            </div>
        </PageHeader>
    );
}

UpdateOperariosTime.propTypes = {
    closeSplash: PropTypes.any,
    fecha: PropTypes.any,
    id: PropTypes.any,
    onSave: PropTypes.any,
    tiempo: PropTypes.any,
};
