import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { proyectosProvider } from '../../../api';
import { SplashProvider } from '../../../contexts/SplashState';
import SolicitudesMaterial from '../../tareas/materiales/solicitudes/SolicitudesMaterial';
import { TareaStateContext } from '../../tareas/TareaView';
import AddSolicitudButton from '../common/AddSolicitudButton';

const useStyles = makeStyles(
    (theme) => ({
        tarea: {
            '&:not(:first-of-type)': {
                borderTop: '0.5px solid #818cae60',
            },
        },
        tareaTitle: {
            margin: theme.spacing(0, 2),
        },
    }),
    { name: 'SolicitudesMaterialList' },
);

export default function SolicitudesMaterialList({ proyectoId, pageHeader }) {
    const classes = useStyles();
    const [tareas, setTareas] = useState([]);
    const [viewParams, setViewParams] = useState({});

    useEffect(() => {
        proyectosProvider.getAll(`${proyectoId}/solicitudes-material`).then(setTareas);
    }, []);

    const editandoSolicitud = viewParams.solicitud;

    return (
        <>
            {tareas.map(
                (tarea) =>
                    (!editandoSolicitud || viewParams.tareaId === tarea.id) && (
                        <div key={tarea.id} className={classes.tarea}>
                            <TareaStateContext.Provider
                                value={{
                                    id: tarea.id,
                                    tarea,
                                    setTarea: (updatedTarea) => {
                                        setTareas((oldTareas) =>
                                            oldTareas.map((t) =>
                                                t.id === tarea.id
                                                    ? typeof updatedTarea === 'function'
                                                        ? updatedTarea(t)
                                                        : updatedTarea
                                                    : t,
                                            ),
                                        );
                                    },
                                    viewParams,
                                    setViewParams,
                                }}
                            >
                                <SplashProvider>
                                    <SolicitudesMaterial
                                        customAddComponent={<AddSolicitudButton title={tarea.descripcion} />}
                                        headerComponent={pageHeader}
                                    />
                                </SplashProvider>
                            </TareaStateContext.Provider>
                        </div>
                    ),
            )}
        </>
    );
}

SolicitudesMaterialList.propTypes = {
    proyectoId: PropTypes.any,
    pageHeader: PropTypes.any,
};
