import differenceInSeconds from 'date-fns/differenceInSeconds';
import DataProvider from './DataProvider';
import { createTiempo } from './tareas-functions';

export const empty = {};

export const estadosFichaje = {
    PENDIENTE: 'PENDIENTE',
    REVISADO: 'REVISADO',
    VERIFICADO: 'VERIFICADO',
    BLOQUEADO: 'BLOQUEADO',
};

class FichajesProvider extends DataProvider {
    constructor() {
        super('fichajes', empty);
    }

    getFichajesMonth = async (year, month) => {
        return this.getAll(`${year}/${month}`).then(updateHorasFichajes);
    };

    getMarcajesHoy = async () => {
        return this.action('marcajes-hoy-new');
    };

    _fichar = async (coords) => {
        return this.action('fichar', {
            method: 'post',
            body: JSON.stringify({ coords }),
        });
    };

    ficharEntrada = async (coords) => {
        return await this._fichar(coords);
    };

    ficharSalida = async (coords) => {
        return await this._fichar(coords);
    };

    ficharPausa = async (marcajePausa) =>
        this.action('fichar-pausa', {
            method: 'post',
            body: JSON.stringify(marcajePausa),
        });

    verificarFichajes = async (ids) => {
        return this.action('revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        }).then(updateHorasFichajes);
    };

    firmaMesPendiente = async (year, month, operarioId) =>
        this.action(`firmar-mes-token/${year}/${month}/${operarioId}`);

    firmarMes = async (year, month, firma, operarioId) => {
        const url = operarioId ? `firmar-mes-token/${year}/${month}/${operarioId}` : `firmar-mes/${year}/${month}`;

        return this.action(url, {
            method: 'post',
            body: JSON.stringify({
                firma,
            }),
        });
    };

    solicitarRevisionFichajes = async (ids) => {
        return this.action('solicitar-revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        }).then(updateHorasFichajes);
    };

    solicitarRevisionFichaje = async (id, motivo) => {
        return this.action('solicitar-revisar', {
            method: 'post',
            body: JSON.stringify({
                id,
                motivo,
            }),
        });
    };
}

function getTiempoFichaje(fichaje) {
    let tiempoTotal = 0;

    fichaje.marcajes.forEach((marcaje) => {
        if (!marcaje.hora_entrada || !marcaje.hora_salida) return;

        const d = differenceInSeconds(new Date(marcaje.hora_salida), new Date(marcaje.hora_entrada));

        tiempoTotal += d;
    });

    return Math.round(tiempoTotal / 60);
}

function updateHorasFichaje(fichaje) {
    const minutosDia = 8 * 60;
    const tiempo = getTiempoFichaje(fichaje);
    const extras = tiempo > minutosDia ? tiempo - minutosDia : 0;

    return {
        ...fichaje,
        total_horas: createTiempo(tiempo),
        total_extras: createTiempo(extras),
    };
}

function updateHorasFichajes(fichajes) {
    return fichajes.map(updateHorasFichaje);
}

export function getTiempoTotalMarcajes(marcajes) {
    let tiempoTotal = 0;

    marcajes.forEach((marcaje) => {
        if (!marcaje.hora_entrada || !marcaje.hora_salida) return;

        const d = differenceInSeconds(new Date(marcaje.hora_salida), new Date(marcaje.hora_entrada));

        tiempoTotal += d;
    });

    return createTiempo(Math.round(tiempoTotal / 60));
}

// export const dataProvider = new DataProvider('fichajes', empty);
export const dataProvider = new FichajesProvider();
