import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import isBefore from 'date-fns/isBefore';
import { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { proyectosProvider } from '../../../api';
import { processTareaForCalendar } from '../../../api/tareas';
import { useFetchData } from '../../../hooks/useFetchData';
import LoadingScreen from '../../common/LoadingScreen';
import { PageBody } from '../../common/PageBody';
import { PageHeader } from '../../common/PageHeader';
import NotasList from '../NotasList';
import PresupuestosList from '../PresupuestosList';
import SolicitudesMaterialList from '../SolicitudesMaterialList';
import TareaItemList from '../TareasList/TareaItemList';
import MenuSlidingDialog from './MenuSlidingDialog';

const pathTitles = {
    '/proyectos/:id': 'Servicios del proyecto',
    '/proyectos/:id/presupuestos': 'Presupuestos del proyecto',
    '/proyectos/:id/observaciones': 'Observaciones del proyecto',
    '/proyectos/:id/solicitudes-material': 'Material del proyecto',
};

export default function ProyectoFicha() {
    const { id } = useParams();
    const match = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(
        () =>
            proyectosProvider.getById(id).then((proyecto) => {
                const tareaList = proyecto.tareas.map(processTareaForCalendar);
                tareaList.sort((a, b) => (isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1));

                proyecto.tareas = tareaList;

                return proyecto;
            }),
        [id],
    );
    const { data: proyecto, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const title = pathTitles[match.path] || 'Proyecto';

    const pageHeader = (
        <PageHeader
            title={title}
            startButton={
                <IconButton onClick={() => history.goBack()}>
                    <ArrowBackIcon style={{ color: 'white' }} />
                </IconButton>
            }
            endButton={<MenuSlidingDialog />}
        >
            {proyecto && (
                <>
                    <Typography variant='h2'>{proyecto.cliente}</Typography>
                    <Typography variant='h3'>{proyecto.nombre}</Typography>
                </>
            )}
        </PageHeader>
    );
    return (
        <>
            {pageHeader}
            {proyecto ? (
                <Switch>
                    <Route path='/proyectos/:id' exact>
                        <PageBody bodyGap={8}>
                            <TareaItemList tareas={proyecto ? proyecto.tareas : []} hideTiempo />
                        </PageBody>
                    </Route>
                    <Route path='/proyectos/:id/presupuestos'>
                        <PageBody bodyGap={8}>
                            <PresupuestosList
                                presupuestos={proyecto ? proyecto.presupuestos : []}
                                onUpdate={fetchData}
                            />
                        </PageBody>
                    </Route>
                    <Route path='/proyectos/:id/observaciones'>
                        <PageBody paddingTop={0}>
                            <NotasList proyectoId={id} />
                        </PageBody>
                    </Route>
                    <Route path='/proyectos/:id/solicitudes-material'>
                        <PageBody paddingTop={0}>
                            <SolicitudesMaterialList proyectoId={id} pageHeader={pageHeader} />
                        </PageBody>
                    </Route>
                </Switch>
            ) : (
                <LoadingScreen />
            )}
        </>
    );
}
