import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { proyectosProvider } from '../../../api';
import ItemCardList from '../../common/ItemCardList';
import ProyectoItemCardList from './ProyectoItemCardList';

export const ProyectosList = () => {
    const [proyectos, setProyectos] = useState(null);
    const history = useHistory();

    useEffect(() => {
        proyectosProvider.getAll('for-operario').then(setProyectos);
    }, []);

    const filterProyectos = useCallback(function (proyecto, textFilter) {
        if (textFilter === '') return true;

        const upperFilter = textFilter.toUpperCase();

        return (
            proyecto.nombre.toUpperCase().includes(upperFilter) || proyecto.cliente.toUpperCase().includes(upperFilter)
        );
    }, []);

    return (
        <ItemCardList filterFn={filterProyectos} title='Lista de proyectos' items={proyectos}>
            {({ filteredItems }) => (
                <ProyectoItemCardList
                    proyectos={filteredItems}
                    onClick={(proyecto) => history.push(`/proyectos/${proyecto.id}`)}
                />
            )}
        </ItemCardList>
    );
};
