import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FeedbackIcon from '@material-ui/icons/Feedback';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import VacacionesIcon from '../icons/Vacaciones';
import NuevaAusencia from './NuevaAusencia';
import NuevaVacaciones from './NuevaVacaciones';

const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'flex',
        alignItems: 'center',
    },
    tabButton: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 64,
        fontSize: 14,
        color: '#8F95AF',
        '& svg': {
            color: 'inherit',
            marginBottom: theme.spacing(0.5),
        },
        '&.active': {
            color: '#2196F3',
            backgroundColor: 'white',
            borderRadius: 12,
        },
    },
}));

export const NuevaSolicitudView = ({ solicitud = {} }) => {
    const classes = useStyles();
    const history = useHistory();
    const [tipoSolicitud, setTipoSolicitud] = useState(solicitud.tipo || 'VACACIONES');

    return (
        <React.Fragment>
            <PageHeader
                title='Nueva solicitud'
                startButton={
                    <IconButton onClick={() => history.push('/solicitudes')}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            ></PageHeader>
            <PageBody>
                <div className={classes.tabs}>
                    <div
                        onClick={() => setTipoSolicitud('VACACIONES')}
                        className={classnames(classes.tabButton, tipoSolicitud === 'VACACIONES' ? 'active' : null)}
                    >
                        <VacacionesIcon />
                        Vacaciones
                    </div>
                    <div
                        onClick={() => setTipoSolicitud('AUSENCIA')}
                        className={classnames(classes.tabButton, tipoSolicitud === 'AUSENCIA' ? 'active' : null)}
                    >
                        <FeedbackIcon />
                        Ausencia
                    </div>
                </div>
                {tipoSolicitud === 'VACACIONES' ? <NuevaVacaciones solicitud={solicitud} /> : <NuevaAusencia />}
            </PageBody>
        </React.Fragment>
    );
};

NuevaSolicitudView.propTypes = {
    solicitud: PropTypes.object,
};
