import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { tareasProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import useSplash from '../../../contexts/SplashState';
import { EditDialogView } from '../../common/EditDialogView';
import { SwitchWithLabel } from '../../common/SwitchWithLabel';
import { TareaHeader } from '../TareaView';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: (props) => `8px ${props.disabled ? 8 : 24}px 4px 8px`,
        backgroundColor: 'white',
        borderRadius: 8,
        marginBottom: theme.spacing(2),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 24,
    },
    editIcon: {
        position: 'absolute',
        right: 4,
        top: 'calc(50% - 9px)',
        color: '#B8BABE',
        fontSize: 18,
    },
    nota: {
        color: '#818CAE',
        fontSize: 13,
        whiteSpace: 'pre-line',
    },
    operario: {
        color: '#2196F3',
        fontWeight: 700,
        fontSize: 10,
        textAlign: 'right',
        marginTop: 'auto',
    },
}));

const useStylesNota = makeStyles(
    (theme) => ({
        incidencia: {
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'AddNota' },
);

function EditNota({ jornada, currentNotas, onClose }) {
    const classes = useStylesNota();
    const [updatedNota, setUpdatedNota] = useState(null);
    const {
        userInfo: {
            preferencias: { usar_incidencias: usarIncidencias },
        },
    } = useAuthState();

    const jornadaId = jornada.id;

    const incidencia = updatedNota?.incidencia ?? currentNotas.incidencia;
    const texto = updatedNota?.texto ?? currentNotas.texto;

    const bloqueada = jornada.revisada || jornada.facturada;

    return (
        <EditDialogView
            header={<TareaHeader title='Observaciones' />}
            onClose={() => onClose(updatedNota)}
            onChange={(newTextoValue) => {
                return tareasProvider.updateJornadaNotas(jornadaId, newTextoValue, incidencia).then(setUpdatedNota);
            }}
            currentText={currentNotas.texto}
        >
            {usarIncidencias && !bloqueada && (
                <SwitchWithLabel
                    onChange={(ev) => {
                        const newIncidenciaValue = ev.target.checked;
                        tareasProvider.updateJornadaNotas(jornadaId, texto, newIncidenciaValue).then((updatedNotas) => {
                            setUpdatedNota(updatedNotas);
                        });
                    }}
                    checked={incidencia}
                    label='Marcar como incidencia'
                    className={classes.incidencia}
                />
            )}
        </EditDialogView>
    );
}

export function Nota({ jornada, nota, canEdit, onSave }) {
    const classes = useStyles({ disabled: !canEdit });
    const { showCustomComponent } = useSplash();

    return (
        <div
            className={classes.root}
            onClick={() => {
                if (!canEdit) return;

                showCustomComponent(({ closeSplash }) => (
                    <EditNota
                        jornada={jornada}
                        currentNotas={nota}
                        onClose={(updatedNota) => {
                            closeSplash();
                            if (!updatedNota) return;

                            onSave(updatedNota);
                        }}
                    />
                ));
            }}
        >
            {canEdit && <ChevronRightIcon className={classes.editIcon} />}
            <Typography className={classes.nota}>{nota.texto}</Typography>
            {/* <Typography className={classes.operario}>por {nota.propia ? 'ti' : nota.operario}</Typography> */}
        </div>
    );
}

Nota.propTypes = {
    canEdit: PropTypes.any,
    jornada: PropTypes.any,
    nota: PropTypes.any,
    onSave: PropTypes.any,
};

EditNota.propTypes = {
    currentNotas: PropTypes.any,
    incidencia: PropTypes.any,
    jornada: PropTypes.any,
    onClose: PropTypes.any,
};
