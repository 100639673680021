import IconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useAsyncDebounce, useGlobalFilter, useRowSelect, useTable } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import ListItem from '../tareas/common/ListItem';
import { TextField } from '../tareas/common/TextField';
import { PageBody } from './PageBody';
import { PageHeader } from './PageHeader';

export function GlobalFilter({ searchPlaceholder, globalFilter, setGlobalFilter, ...props }) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <TextField
            fieldStyle={{ marginTop: 16 }}
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            backgroundColor='#DBE2FF'
            startAdornment={<SearchIcon />}
            placeholder={searchPlaceholder}
            id='global-filter-input'
            {...props}
        />
    );
}

function customGlobalFilter(rows, ids, filterValue) {
    const searchTerms = filterValue.toLowerCase().trim().split(/\s+/);

    return rows.filter((row) => {
        return searchTerms.every((term) =>
            ids.some((id) => {
                const cellValue = row.values[id];
                return String(cellValue).toLowerCase().includes(term);
            }),
        );
    });
}

const ListItemIcon = withStyles((theme) => ({
    root: {
        minWidth: 36,
    },
}))(MuiListItemIcon);

const ListItemText = withStyles((theme) => ({
    inset: {
        paddingLeft: 36,
    },
}))(MuiListItemText);

const ResumenSeleccionados = withStyles(
    (theme) => ({
        root: {
            color: 'white',
            fontSize: 10,
        },
        item: {
            '& + &::before': {
                content: '", "',
            },
        },
    }),
    { name: 'ResumenSeleccionados' },
)(({ rows, classes }) => {
    return (
        <div className={classes.root}>
            {rows.length > 0 && (
                <>
                    Has seleccionado:&nbsp;
                    {rows.map((row, i) => (
                        <span key={i} className={classes.item}>
                            {row.cells[0].render('Cell')}
                        </span>
                    ))}
                </>
            )}
        </div>
    );
});

export default function ListView({
    title,
    subtitle,
    setFieldValue,
    searchPlaceholder,
    onClose,
    currentValue,
    items,
    columns,
    multiple = false,
    canAdd = false,
    autoOk = false,
    children,
    customEndButton,
}) {
    const [hasClicked, setHasClicked] = useState(false);
    const initialSelectedRowIds = {};
    if (currentValue) {
        const currentRows = currentValue instanceof Array ? currentValue : [currentValue];
        currentRows.forEach(
            (row) => (initialSelectedRowIds[items.findIndex((item) => item.id === row.id && row.id !== null)] = true),
        );
    }

    const {
        rows,
        prepareRow,
        state: { selectedRowIds, globalFilter },
        setGlobalFilter,
        toggleAllRowsSelected,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: items,
            globalFilter: customGlobalFilter,
            initialState: {
                selectedRowIds: initialSelectedRowIds,
            },
        },
        useRowSelect,
        useGlobalFilter,
    );

    function acceptAndClose() {
        const selectedItems = selectedFlatRows.map((row) => row.original);
        setFieldValue(multiple ? selectedItems : selectedItems[0]);
        onClose && onClose();
    }

    useEffect(() => {
        if (!autoOk || !hasClicked) return;

        const selectedItems = selectedFlatRows.map((row) => row.original);
        if (selectedItems.length === 0) return;

        setFieldValue(multiple ? selectedItems : selectedItems[0]);
        onClose && onClose();
    }, [hasClicked, selectedFlatRows, multiple, autoOk]);

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            if (canAdd && index === rows.length) {
                return (
                    <ListItem
                        style={style}
                        onClick={() => {
                            const columnName = columns[0].id || columns[0].accessor;
                            const obj = {
                                id: null,
                                [columnName]: globalFilter,
                            };
                            setFieldValue(multiple ? [obj] : obj);
                            onClose && onClose();
                        }}
                    >
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Añadir "${globalFilter}"`} />
                    </ListItem>
                );
            }

            const row = rows[index];
            prepareRow(row);
            return (
                <ListItem
                    {...row.getRowProps({
                        style,
                    })}
                    onClick={() => {
                        if (!multiple) toggleAllRowsSelected(false);
                        row.toggleRowSelected();

                        setHasClicked(true);
                    }}
                >
                    {row.isSelected && (
                        <ListItemIcon>
                            <DoneIcon />
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={row.cells[0].render('Cell')}
                        secondary={row.cells[1] && row.cells[1].render('Cell')}
                        inset={!row.isSelected}
                    />
                </ListItem>
            );
        },
        [prepareRow, rows, selectedRowIds, multiple, toggleAllRowsSelected],
    );

    const resumen = useMemo(() => {
        if (!multiple) return null;

        selectedFlatRows.forEach(prepareRow);

        return <ResumenSeleccionados rows={selectedFlatRows} />;
    }, [selectedFlatRows, prepareRow]);

    return (
        <React.Fragment>
            <PageHeader
                title={title}
                subtitle={subtitle}
                startButton={
                    onClose && (
                        <IconButton onClick={onClose}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    )
                }
                endButton={
                    customEndButton || (
                        <IconButton onClick={acceptAndClose} disabled={selectedFlatRows.length === 0}>
                            <DoneIcon style={{ color: 'white' }} />
                        </IconButton>
                    )
                }
            >
                <GlobalFilter
                    searchPlaceholder={searchPlaceholder}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                {resumen}
            </PageHeader>
            <PageBody paddingTop={0} style={{ overflowY: 'hidden' }}>
                {children}
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <MuiList disablePadding>
                                <FixedSizeList
                                    className='List'
                                    height={height}
                                    itemCount={rows.length + (canAdd && globalFilter ? 1 : 0)}
                                    itemSize={75}
                                    width={width}
                                >
                                    {RenderRow}
                                </FixedSizeList>
                            </MuiList>
                        )}
                    </AutoSizer>
                </div>
            </PageBody>
        </React.Fragment>
    );
}

ListView.propTypes = {
    children: PropTypes.any,
    autoOk: PropTypes.bool,
    canAdd: PropTypes.bool,
    columns: PropTypes.any,
    currentValue: PropTypes.any,
    items: PropTypes.any,
    multiple: PropTypes.bool,
    onClose: PropTypes.any,
    searchPlaceholder: PropTypes.any,
    setFieldValue: PropTypes.any,
    title: PropTypes.any,
    subtitle: PropTypes.any,
    customEndButton: PropTypes.any,
};

GlobalFilter.propTypes = {
    globalFilter: PropTypes.any,
    searchPlaceholder: PropTypes.any,
    setGlobalFilter: PropTypes.any,
};
