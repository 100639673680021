import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: '#fff',
            borderRadius: 8,
            margin: theme.spacing(1),
            padding: theme.spacing(0.5, 1.5),
            fontSize: 13,
            color: '#818CAE',
            '&.Mui-error': {
                border: '2px solid #D84A3A',
            },
        },
        readOnly: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'RoundedInput' },
);

export const RoundedReadOnly = ({ style, className, endAdornment, ...props }) => {
    const styles = useStyles();

    const [field, ,] = useField(props);

    return (
        <div style={style} className={clsx(styles.root, styles.readOnly, className)} {...props}>
            {field.value}
            {endAdornment}
        </div>
    );
};

export const RoundedInput = ({ style, className, ...props }) => {
    const styles = useStyles();

    const [{ value, ...field }, meta] = useField(props);

    return (
        <InputBase
            value={value || ''}
            {...field}
            style={style}
            className={clsx(styles.root, className)}
            error={Boolean(meta.error)}
            {...props}
        />
    );
};

RoundedInput.propTypes = {
    className: PropTypes.any,
    style: PropTypes.any,
};

RoundedReadOnly.propTypes = {
    className: PropTypes.any,
    endAdornment: PropTypes.any,
    style: PropTypes.any,
};
