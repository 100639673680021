import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { format, formatTime, isAllDay } from '../../../utils';
import CalendarioIcon from '../../icons/Calendario';
import TareaResumenDialog from '../TareaResumenDialog';
import TareaItem from './TareaItem';

const useStyles = makeStyles(
    (theme) => ({
        emptyHero: {
            fontSize: 17,
            color: 'white',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 250,
            '& .MuiSvgIcon-root': {
                color: 'white',
                fontSize: 64,
                marginBottom: theme.spacing(2),
            },
            '& .MuiTypography-body1': {
                color: 'white',
                fontWeight: 500,
                fontSize: 16,
                lineHeight: '24px',
            },
            '& .MuiTypography-body2': {
                color: 'white',
                fontWeight: 400,
                fontSize: 12,
                opacity: 0.5,
            },
        },
        solicitudItem: {
            backgroundColor: '#14aaf5',
            '& .MuiTypography-root': {
                color: 'white',
            },
        },
    }),
    { name: 'BaseTareaItemList' },
);

export default function TareaItemList({ showOperarios, ...props }) {
    function getTareaItemProps(tarea, props) {
        const { showFecha, hideTiempo } = props;

        const allDay = tarea.libre || tarea.fechaInicio === null || isAllDay(tarea.fechaInicio, tarea.fechaFin);

        const fecha = tarea.fechaVisita ? new Date(tarea.fechaVisita) : new Date(tarea.fechaInicio);
        let titulo = showFecha && !tarea.libre ? `${format(fecha, 'dd/MM')} ` : '';
        if (!allDay) titulo = `${titulo}${formatTime(fecha)}`;
        if (titulo.length > 0) titulo = `${titulo} | `;
        titulo = `${titulo}${tarea.cliente}`;

        let estado = tarea.estado;
        if (tarea.sin_resolver) estado = 'HAS_INCIDENCIA';

        if (tarea.urgente && tarea.estado !== 'FINALIZADA') estado = 'URGENTE';

        return {
            cliente: titulo,
            descripcion: tarea.descripcion,
            tiempo: hideTiempo ? null : tarea.tiempoHoy,
            estado,
            operarios: showOperarios ? tarea.operarios : null,
        };
    }

    return <BaseTareaItemList {...props} getTareaItemProps={getTareaItemProps} />;
}

TareaItemList.propTypes = {
    showOperarios: PropTypes.any,
};

export function BaseTareaItemList({ tareas, solicitudes, getTareaItemProps, ...props }) {
    const classes = useStyles();
    const [selectedTarea, setSelectedTarea] = React.useState(false);

    const handleClickOpen = (tarea) => () => {
        setSelectedTarea(tarea);
    };

    const handleClose = () => {
        setSelectedTarea(false);
    };

    return (
        <React.Fragment>
            {tareas.length === 0 && (!solicitudes || solicitudes.length === 0) ? (
                <div className={classes.emptyHero}>
                    <CalendarioIcon />
                    <Typography variant='body1'>No hay servicios</Typography>
                    <Typography variant='body2'>Aquí se muestran los servicios planificados en esta fecha.</Typography>
                </div>
            ) : (
                <>
                    {tareas.map((tarea, i) => (
                        <TareaItem key={i} onClick={handleClickOpen(tarea)} {...getTareaItemProps(tarea, props)} />
                    ))}
                    {solicitudes &&
                        solicitudes.map((solicitud, i) => {
                            const allDay = isAllDay(solicitud.fecha_inicio, solicitud.fecha_fin);
                            const tipo = solicitud.tipo === 'VACACIONES' ? 'Vacaciones' : 'Ausencia';
                            const fechas = allDay
                                ? 'Todo el dia'
                                : `${formatTime(solicitud.fecha_inicio)} -> ${formatTime(solicitud.fecha_fin)}`;
                            const titulo = `${fechas} | ${tipo}`;
                            return (
                                <TareaItem
                                    key={i}
                                    cliente={titulo}
                                    descripcion={solicitud.motivo}
                                    className={classes.solicitudItem}
                                />
                            );
                        })}
                </>
            )}
            <TareaResumenDialog
                open={Boolean(selectedTarea)}
                tarea={selectedTarea}
                onClose={handleClose}
                hideTiempo={props.hideTiempo}
            />
        </React.Fragment>
    );
}

BaseTareaItemList.propTypes = {
    showFecha: PropTypes.bool,
    hideTiempo: PropTypes.bool,
    solicitudes: PropTypes.any,
    tareas: PropTypes.any,
    getTareaItemProps: PropTypes.any,
};

TareaItemList.propTypes = {
    showFecha: PropTypes.bool,
    hideTiempo: PropTypes.bool,
};
