import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import { useTareaState } from '../TareaView';
import { MaterialesJornada } from './MaterialesJornada';
//
// const MaterialSchema = Yup.object().shape({
//     jornadas: Yup.array().of(
//         Yup.object().shape({
//             materiales: Yup.array().of(
//                 Yup.object().shape({
//                     descripcion: Yup.string().required('Requerido'),
//                     unidades: Yup.number().required('Requerido'),
//                 }),
//             ),
//         }),
//     ),
// });

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}));

export function MaterialUtilizado({ jornadas, setJornadas }) {
    const styles = useStyles();
    // const { values } = useFormikContext();
    const todayStr = formatISODate(new Date());
    const hasToday = jornadas.findIndex((jornada) => jornada.fecha === todayStr) >= 0;
    const { setGoBackCheck } = useTareaState();

    const [changedJornadas, setChangedJornadas] = useState([]);

    useEffect(() => {
        setChangedJornadas(jornadas.map(() => false));

        setGoBackCheck(function () {
            return true;
        });
    }, [jornadas]);

    const onChange = useCallback(
        (idx, changed) => {
            if (changedJornadas[idx] === changed) return;
            setChangedJornadas((value) => value.map((c, i) => (i === idx ? changed : c)));

            setGoBackCheck(function () {
                return changedJornadas.some((c) => c);
            });
        },
        [changedJornadas],
    );

    return (
        <>
            {!hasToday && (
                <PageBodySection className={styles.root}>
                    <Typography className={styles.title}>Hoy</Typography>
                    <Typography className={styles.info}>
                        <InfoIcon /> Registra el tiempo para poder añadir el material de hoy.
                    </Typography>
                </PageBodySection>
            )}
            {jornadas.map((jornada, i) => (
                <MaterialesJornada
                    key={i}
                    index={i}
                    jornada={jornada}
                    onSaveMateriales={(materiales) =>
                        setJornadas((jornadas) => jornadas.map((j) => (j.id === jornada.id ? { ...j, materiales } : j)))
                    }
                    onChange={onChange}
                />
            ))}
        </>
    );
}

MaterialUtilizado.propTypes = {
    jornadas: PropTypes.any,
    setJornadas: PropTypes.any,
};
