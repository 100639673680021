import MuiDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GestureIcon from '@material-ui/icons/Gesture';
import MenuIcon from '@material-ui/icons/Menu';
import FichajesIcon from '@material-ui/icons/Timer';
import clsx from 'clsx';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthState, { usePermisos, usePreferencias } from '../../contexts/AuthState';
import CalendarioDayIcon from '../icons/CalendarioDay';
import CalendarioMonthIcon from '../icons/CalendarioMonth';
import CalendarioWeekIcon from '../icons/CalendarioWeek';
import ListaIcon from '../icons/Lista';
import VacacionesIcon from '../icons/Vacaciones';
const useStyles = makeStyles(
    (theme) => ({
        drawerMenu: {
            width: 300,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        logo: {
            margin: theme.spacing(2),
            width: 101,
            height: 36,
        },
        version: {
            '& .MuiTypography-root': {
                fontSize: 12,
                textAlign: 'center',
            },
        },
    }),
    { name: 'DrawerMenu' },
);

const Drawer = withStyles(
    (theme) => ({
        paper: {
            backgroundColor: theme.palette.neutral.dark,
        },
    }),
    { name: 'Drawer' },
)(MuiDrawer);

const List = withStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'List' },
)(MuiList);

const ListItem = withStyles(
    (theme) => ({
        root: {
            '&.Mui-selected': {
                backgroundColor: '#3451FA',
            },
        },
    }),
    { name: 'ListItem' },
)(MuiListItem);

const ListItemText = withStyles(
    (theme) => ({
        primary: {
            color: 'white',
        },
    }),
    { name: 'ListItemText' },
)(MuiListItemText);

const ListItemIcon = withStyles(
    (theme) => ({
        root: {
            color: 'white',
            minWidth: 40,
        },
    }),
    { name: 'ListItemIcon' },
)(MuiListItemIcon);

export default function DrawerMenu() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();

    const { logout, isCompany } = useAuthState();

    const [
        esResponsableOperarios,
        esResponsableProyectos,
        usarDocumentos,
        usarFirmaAlbaranesPendientes,
        usarSoloControlHorario,
    ] = usePreferencias(
        'es_responsable_operarios',
        'es_responsable_proyectos',
        'usar_documentos',
        'usar_firma_albaranes_pendientes',
        'usar_solo_control_horario',
    );
    const [crearServicios, editarServicios, fichar, verDocumentacion] = usePermisos(
        'crear_servicios',
        'editar_servicios',
        'fichar',
        'ver_documentacion',
    );

    return (
        <>
            <IconButton onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <div
                    className={clsx(classes.drawerMenu)}
                    role='presentation'
                    onClick={() => setDrawerOpen(false)}
                    onKeyDown={() => setDrawerOpen(false)}
                >
                    <img src='/images/logo-drawer.png' className={classes.logo} />
                    <List>
                        {!usarSoloControlHorario && (
                            <>
                                <ListItem
                                    button
                                    onClick={() => history.push('/lista')}
                                    selected={pathname === '/lista'}
                                >
                                    <ListItemIcon>
                                        <ListaIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Lista' />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => history.push('/control-jornada-day')}
                                    selected={pathname.startsWith('/control-jornada-day')}
                                >
                                    <ListItemIcon>
                                        <CalendarioDayIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Dia' />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => history.push('/control-jornada-week')}
                                    selected={pathname.startsWith('/control-jornada-week')}
                                >
                                    <ListItemIcon>
                                        <CalendarioWeekIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Semana' />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => history.push('/control-jornada-month')}
                                    selected={pathname.startsWith('/control-jornada-month')}
                                >
                                    <ListItemIcon>
                                        <CalendarioMonthIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Mes' />
                                </ListItem>
                            </>
                        )}
                        {fichar && (
                            <ListItem
                                button
                                onClick={() => history.push('/fichajes')}
                                selected={pathname.startsWith('/fichajes')}
                                style={{ marginTop: 'auto' }}
                            >
                                <ListItemIcon>
                                    <FichajesIcon />
                                </ListItemIcon>
                                <ListItemText primary='Fichajes' />
                            </ListItem>
                        )}
                        <ListItem
                            button
                            onClick={() => history.push('/solicitudes')}
                            selected={pathname.startsWith('/solicitudes')}
                            style={{ marginTop: !fichar ? 'auto' : 0 }}
                        >
                            <ListItemIcon>
                                <VacacionesIcon />
                            </ListItemIcon>
                            <ListItemText primary='Vacaciones y ausencias' />
                        </ListItem>

                        {!usarSoloControlHorario && (
                            <>
                                {esResponsableProyectos && (
                                    <ListItem
                                        button
                                        onClick={() => history.push('/proyectos')}
                                        selected={pathname.startsWith('/proyectos')}
                                        style={{ marginTop: 'auto' }}
                                    >
                                        <ListItemIcon>
                                            <FolderOpenIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Proyectos' />
                                    </ListItem>
                                )}
                                {esResponsableOperarios && (
                                    <ListItem
                                        button
                                        onClick={() => history.push('/calendario-planificacion')}
                                        selected={pathname.startsWith('/calendario-planificacion')}
                                        style={{ marginTop: !esResponsableProyectos ? 'auto' : 0 }}
                                    >
                                        <ListItemIcon>
                                            <CalendarioMonthIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Calendario de planificación' />
                                    </ListItem>
                                )}
                            </>
                        )}
                        {usarDocumentos && verDocumentacion && (
                            <ListItem
                                button
                                onClick={() => history.push('/documentos')}
                                selected={pathname.startsWith('/documentos')}
                            >
                                <ListItemIcon>
                                    <FolderOpenIcon />
                                </ListItemIcon>
                                <ListItemText primary='Documentos' />
                            </ListItem>
                        )}

                        {!usarSoloControlHorario && (
                            <>
                                {usarFirmaAlbaranesPendientes && (
                                    <ListItem button onClick={() => history.push('/albaranes')}>
                                        <ListItemIcon>
                                            <GestureIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Albaranes para firmar' />
                                    </ListItem>
                                )}

                                {crearServicios && (
                                    <ListItem
                                        button
                                        onClick={() => history.push('/tarea/nueva')}
                                        selected={pathname.startsWith('/tarea/nueva')}
                                        style={{ marginTop: 'auto' }}
                                    >
                                        <ListItemIcon>
                                            <AddIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Añadir servicio' />
                                    </ListItem>
                                )}
                                {isCompany && editarServicios && (
                                    <ListItem
                                        button
                                        onClick={() => history.push('/tareas-admin')}
                                        selected={pathname.startsWith('/tareas-admin')}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Editar servicios' />
                                    </ListItem>
                                )}
                            </>
                        )}

                        <ListItem
                            button
                            onClick={() => {
                                logout();
                            }}
                            style={{ marginTop: 'auto' }}
                        >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary='Cerrar sesión' />
                        </ListItem>
                        <ListItem button={false}>
                            <ListItemText
                                primary={`Version ${process.env.REACT_APP_VERSION}`}
                                className={classes.version}
                            />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </>
    );
}
