import MuiButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

const buttonColors = {
    transparent: {
        background: 'transparent',
        color: '#1F2A4B',
    },
    normal: {
        background: '#E9EAEF',
        color: '#8F95AF',
    },
    success: {
        background: '#1BC47D',
        color: 'white',
    },
    error: {
        background: '#F24822',
        color: 'white',
    },
    info: {
        background: '#2196f3',
        color: 'white',
    },
    dark: {
        background: '#8F95AF',
        color: 'white',
    },
    primary: {
        background: 'transparent',
        color: '#4C60FE',
    },
    primaryFilled: {
        background: '#4C60FE',
        color: 'white',
    },
    disabled: {
        background: 'rgba(255, 255, 255, 0.1)',
        color: 'rgba(255, 255, 255, 0.3)',
    },
    white: {
        background: 'transparent',
        color: 'white',
    },
};

const Button = withStyles(
    (theme) => ({
        root: {
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: (props) => (props.size === 'small' ? 12 : 14),
            fontWeight: 500,
            padding: theme.spacing(1.5, 2),
            lineHeight: 1.5,
            borderRadius: (props) => (props.rounded ? 30 : props.radius ?? 8),
            width: (props) => (props.fullWidth ? '100%' : 'auto'),
            height: (props) => (props.size === 'small' ? 32 : 'auto'),
            color: (props) => buttonColors[props.color || 'normal'].color,
            border: (props) =>
                `1.5px solid ${buttonColors[props.color || 'normal'][props.outline ? 'color' : 'background']}`,
            backgroundColor: (props) =>
                props.outline ? 'transparent' : buttonColors[props.color || 'normal'].background,
            '&:hover': {
                backgroundColor: (props) =>
                    props.outline ? 'transparent' : buttonColors[props.color || 'normal'].background,
            },
            '&:active': {
                backgroundColor: (props) =>
                    props.outline ? 'transparent' : buttonColors[props.color || 'normal'].background,
            },
            '&:focus': {
                backgroundColor: (props) =>
                    props.outline ? 'transparent' : buttonColors[props.color || 'normal'].background,
            },
        },
    }),
    { name: 'Button' },
)(({ color, radius, outline, rounded, ...props }) => React.createElement(MuiButton, props));

export default Button;
