import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'material-ui-snackbar-provider/lib';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../api';
import { estadoLabels, processTarea } from '../../api/tareas';
import { SplashProvider } from '../../contexts/SplashState';
import { formatISODate } from '../../utils';
import LoadingScreen from '../common/LoadingScreen';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import { AlbaranesView } from './AlbaranesView';
import { CerrarView } from './CerrarView';
import { AddExplicacion } from './explicaciones/AddExplicacion';
import { TareasExplicaciones } from './explicaciones/TareasExplicaciones';
import { FirmarView } from './firmar/FirmarView';
import { Gastos } from './gastos/Gastos';
import MenuSlidingDialog from './info/MenuSlidingDialog';
import { JornadasList } from './JornadasList';
import MaterialTarea from './materiales/MaterialTarea';
import { AddSolicitudLineas } from './materiales/solicitudes/AddSolicitudLineas';
import { AddNota } from './notas/AddNota';
import { FotoSlideshowView } from './notas/FotoSlideshowView';
import { TareasNotas } from './notas/TareasNotas';
import { ParteMantenimientoView } from './partes_mantenimiento/ParteMantenimientoView';
import { PartesMantenimientoListView } from './partes_mantenimiento/PartesMantenimientoListView';
import { PartesMantenimientoResumenView } from './partes_mantenimiento/PartesMantenimientoResumenView';
import { VistaGeneral } from './VistaGeneral';

export const GENERAL = 'general';
export const LISTA_JORNADAS = 'jornadas';
export const MATERIAL_UTILIZADO = 'materiales';
export const SOLICITUD_MATERIAL_ADD = 'solicitud-material-add';
export const NOTAS = 'notas';
export const NOTAS_ADD = 'notas-add';
export const EXPLICACION = 'explicacion';
export const EXPLICACION_ADD = 'explicacion-add';
export const GASTOS = 'gastos';
export const FOTO = 'fotos';
export const CERRAR = 'cerrar';
export const FIRMAR = 'firmar';
export const ALBARANES = 'albaranes';
export const PARTES_MANTENIMIENTO_LIST = 'partes-mantenimiento';
export const PARTE_MANTENIMIENTO = 'parte-mantenimiento';
export const PARTES_MANTENIMIENTO_RESUMEN = 'partes-mantenimiento-resumen';

const viewTitles = {
    [GENERAL]: (tarea) =>
        `Servicio ${
            tarea.referencia_interna && tarea.referencia_interna !== '' ? tarea.referencia_interna : tarea.numero
        }`,
    [LISTA_JORNADAS]: 'Jornadas',
    [MATERIAL_UTILIZADO]: 'Material utilizado',
    [SOLICITUD_MATERIAL_ADD]: 'Solicitud de material',
    [NOTAS]: 'Observaciones',
    [NOTAS_ADD]: 'Observaciones',
    [FOTO]: 'Foto 1 de 3',
    [EXPLICACION]: 'Descripción del servicio',
    [EXPLICACION_ADD]: 'Descripción del servicio',
    [GASTOS]: 'Otros gastos',
    [CERRAR]: 'Albarán de servicio',
    [FIRMAR]: 'Conformidad',
    [ALBARANES]: 'Albaranes de servicio',
    [PARTES_MANTENIMIENTO_LIST]: 'Revisón de equipos',
    [PARTE_MANTENIMIENTO]: 'Revisión del equipo',
    [PARTES_MANTENIMIENTO_RESUMEN]: 'Resúmen de mantenimiento',
};

export const TareaStateContext = createContext();

export function useTareaState() {
    return useContext(TareaStateContext);
}

export default function TareaViewComponent({ id, selectedJornadaDate }) {
    const history = useHistory();
    const [tarea, setTarea] = useState(null);
    const [refreshCount, setRefreshCount] = useState(0);
    const [view, setView] = useState(selectedJornadaDate ? LISTA_JORNADAS : GENERAL);
    const [viewParams, setViewParams] = useState({});
    const beforeGoBackRef = useRef(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        setView(selectedJornadaDate ? LISTA_JORNADAS : GENERAL);
    }, [selectedJornadaDate]);

    const refresh = () => setRefreshCount((count) => count + 1);

    useEffect(() => {
        tareasProvider.getById(id).then((tarea) => {
            setTarea(processTarea(tarea));
        });
    }, [id, refreshCount]);

    if (!tarea) return <LoadingScreen />;

    function setViewWithParams(view, params) {
        setView(view);
        if (params) setViewParams(params);
    }

    function setFotos(fotos) {
        setTarea((tarea) => ({
            ...tarea,
            fotos,
        }));

        if (fotos.length === 0) {
            setViewWithParams(NOTAS, {});
        } else {
            setViewParams((params) => ({ ...params, fotos, fotoIdx: Math.min(params.fotoIdx, fotos.length - 1) }));
        }
    }

    function addFoto(jornadaId, newFoto) {
        setTarea((tarea) => ({
            ...tarea,
            jornadas: tarea.jornadas.map((jornada) =>
                jornada.id === jornadaId
                    ? {
                          ...jornada,
                          fotos: [...jornada.fotos, newFoto],
                      }
                    : jornada,
            ),
        }));
    }

    function openFotoSlideshow(fotos, fotoIdx) {
        setViewWithParams(FOTO, { fotos, fotoIdx });
    }

    let Component;
    let componentParams = {};

    switch (view) {
        case FOTO:
            Component = FotoSlideshowView;
            break;
        case FIRMAR:
            Component = FirmarView;
            componentParams = {
                onClose: () => setView(CERRAR),
                onSave: (firma) => {
                    setViewWithParams(CERRAR, { ...viewParams, firma });
                },
            };
            break;
        default:
            Component = TareaView;
            break;
    }

    function setGoBackCheck(fn) {
        beforeGoBackRef.current = fn;
    }

    function goBack() {
        if (beforeGoBackRef.current && !beforeGoBackRef.current()) {
            snackbar.showMessage('Por favor, guarda los cambios antes de volver.', null, null, { severity: 'warning' });
            return;
        }

        setGoBackCheck(null);

        if (view === GENERAL || view === LISTA_JORNADAS) {
            history.goBack();
            return;
        }

        if (view === NOTAS_ADD) {
            setView(NOTAS);
            return;
        }

        if (view === SOLICITUD_MATERIAL_ADD) {
            setViewWithParams(MATERIAL_UTILIZADO, { ...viewParams, showSolicitudesMaterial: true });
            return;
        }

        if (view === MATERIAL_UTILIZADO) {
            if (viewParams.solicitud) {
                setViewWithParams(MATERIAL_UTILIZADO, {
                    ...viewParams,
                    solicitud: null,
                    showSolicitudesMaterial: true,
                });
            } else {
                setViewWithParams(GENERAL, {});
            }
            return;
        }

        if (view === EXPLICACION_ADD) {
            setView(EXPLICACION);
            return;
        }

        if (view === CERRAR) {
            setView(ALBARANES);
            return;
        }

        if (view === PARTE_MANTENIMIENTO) {
            setView(PARTES_MANTENIMIENTO_LIST);
            return;
        }

        setView(GENERAL);
    }

    return (
        <TareaStateContext.Provider
            value={{
                id,
                selectedJornadaDate,
                tarea,
                setTarea: (tareaOrFn) => {
                    setTarea((tarea) => {
                        const hoy = formatISODate(new Date());
                        const newTarea = typeof tareaOrFn === 'function' ? tareaOrFn(tarea) : tareaOrFn;

                        const jornadasPropias = newTarea.jornadas.filter((jornada) => jornada.propia);
                        newTarea.jornadaHoy = jornadasPropias.find((jornada) => jornada.fecha === hoy);

                        return newTarea;
                    });
                },
                view,
                viewParams,
                openFotoSlideshow,
                setFotos,
                addFoto,
                setView: setViewWithParams,
                setViewParams,
                refresh,
                setGoBackCheck,
                goBack,
            }}
        >
            <SplashProvider>
                <Component {...componentParams} />
            </SplashProvider>
        </TareaStateContext.Provider>
    );
}

function TareaView() {
    const { id, selectedJornadaDate, tarea, setTarea, view, viewParams, setView, refresh } = useTareaState();

    const editable = !tarea.disabled;

    const viewBodies = {
        [GENERAL]: <VistaGeneral id={id} tarea={tarea} setView={setView} onSave={refresh} editable={editable} />,
        [LISTA_JORNADAS]: (
            <JornadasList
                id={id}
                tarea={tarea}
                jornadas={tarea.jornadasPropias}
                editable={editable}
                refresh={refresh}
                selectedJornadaDate={selectedJornadaDate}
            />
        ),
        [MATERIAL_UTILIZADO]: <MaterialTarea />,
        [SOLICITUD_MATERIAL_ADD]: <AddSolicitudLineas />,
        // [MATERIAL_UTILIZADO]:
        //     <MaterialUtilizado
        //         id={id}
        //         jornadas={tarea.jornadas.map(jornada => ({
        //             id: jornada.id,
        //             fecha: jornada.fecha,
        //             materiales: jornada.materiales,
        //             facturada: jornada.facturada,
        //             propia: jornada.propia,
        //             operario: jornada.operario,
        //         }))}
        //         setJornadas={jornadas => {
        //             setTarea(tarea => ({
        //                 ...tarea,
        //                 jornadas: typeof (jornadas) === 'function' ? jornadas(tarea.jornadas) : jornadas,
        //             }))
        //         }}
        //         editable={editable}
        //     />,
        [NOTAS]: (
            <TareasNotas
                setAddNotaView={(jornada) => setView(NOTAS_ADD, { jornada })}
                tareasNotas={tarea.tareasNotas}
                setTareasNotas={(tareasNotas) => {
                    setTarea((tarea) => ({
                        ...tarea,
                        tareasNotas: typeof tareasNotas === 'function' ? tareasNotas(tarea.tareasNotas) : tareasNotas,
                    }));
                }}
                editable={editable}
            />
        ),
        [NOTAS_ADD]: (
            <AddNota
                jornada={viewParams.jornada}
                id={id}
                currentNotas={viewParams.jornada && viewParams.jornada.notas[0]}
                onSave={(newNota) => {
                    const { jornada } = viewParams;

                    setTarea((tarea) => {
                        return {
                            ...tarea,
                            jornadas: tarea.jornadas.map((oldJornada) =>
                                oldJornada.id === jornada.id
                                    ? {
                                          ...oldJornada,
                                          notas: [newNota],
                                      }
                                    : oldJornada,
                            ),
                        };
                    });
                }}
            />
        ),
        [EXPLICACION]: (
            <TareasExplicaciones
                setAddExplicacionView={(jornada) => setView(EXPLICACION_ADD, { jornada })}
                tareasExplicaciones={tarea.tareasExplicaciones}
                setTareasExplicaciones={(tareasExplicaciones) => {
                    setTarea((tarea) => ({
                        ...tarea,
                        tareasExplicaciones:
                            typeof tareasExplicaciones === 'function'
                                ? tareasExplicaciones(tarea.tareasExplicaciones)
                                : tareasExplicaciones,
                    }));
                }}
                editable={editable}
            />
        ),
        [EXPLICACION_ADD]: (
            <AddExplicacion
                id={id}
                currentExplicacion={viewParams.jornada && viewParams.jornada.explicacion}
                onSave={(newExplicacion) => {
                    const { jornada } = viewParams;

                    setTarea((tarea) => {
                        return {
                            ...tarea,
                            jornadas: tarea.jornadas.map((oldJornada) =>
                                oldJornada.id === jornada.id
                                    ? {
                                          ...oldJornada,
                                          explicacion: newExplicacion,
                                      }
                                    : oldJornada,
                            ),
                        };
                    });
                }}
            />
        ),
        [GASTOS]: (
            <Gastos
                id={id}
                jornadas={tarea.jornadas.map((jornada) => ({
                    id: jornada.id,
                    fecha: jornada.fecha,
                    gastos: jornada.gastos ?? { desplazamiento: '0', dietas: '0', parking: '0' },
                    facturada: jornada.facturada,
                    revisada: jornada.revisada,
                    propia: jornada.propia,
                    operario: tarea.operarios.length > 1 && jornada.operario,
                }))}
                setJornadas={(jornadas) => {
                    setTarea((tarea) => ({
                        ...tarea,
                        jornadas: typeof jornadas === 'function' ? jornadas(tarea.jornadas) : jornadas,
                    }));
                }}
                editable={editable}
            />
        ),
        [CERRAR]: <CerrarView />,
        [ALBARANES]: <AlbaranesView />,
        [PARTES_MANTENIMIENTO_LIST]: <PartesMantenimientoListView />,
        [PARTE_MANTENIMIENTO]: <ParteMantenimientoView />,
        [PARTES_MANTENIMIENTO_RESUMEN]: <PartesMantenimientoResumenView />,
    };

    return (
        <React.Fragment>
            <TareaHeader />
            <PageBody paddingTop={view === FIRMAR ? 0 : 0}>{viewBodies[view]}</PageBody>
        </React.Fragment>
    );
}

export function TareaHeader({ startButton, title: customTitle }) {
    const { tarea, view, goBack } = useTareaState();

    const title = customTitle ?? viewTitles[view];

    return (
        <PageHeader
            title={typeof title === 'function' ? title(tarea) : title}
            startButton={
                startButton ?? (
                    <IconButton onClick={goBack}>
                        <ArrowBackIcon style={{ color: 'white' }} />
                    </IconButton>
                )
            }
            endButton={<MenuSlidingDialog />}
        >
            <div
                style={{
                    display: 'flex',
                }}
            >
                <Typography variant='h2' style={{ flex: 1 }}>
                    {tarea.cliente}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <Typography variant='h3' style={{ flex: 1 }}>
                    {tarea.descripcion}
                </Typography>
                <span
                    style={{
                        backgroundColor: 'white',
                        color: '#4C62FE',
                        fontSize: 8,
                        fontWeight: 500,
                        width: 70,
                        height: 20,
                        borderRadius: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {estadoLabels[tarea.estado]}
                </span>
            </div>
        </PageHeader>
    );
}

TareaViewComponent.propTypes = {
    id: PropTypes.any,
    selectedJornadaDate: PropTypes.any,
};

TareaHeader.propTypes = {
    startButton: PropTypes.any,
    title: PropTypes.any,
};
