import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            fontSize: 12,
            // alignSelf: 'flex-start',
            padding: theme.spacing(0.25, 0.5),
            borderRadius: 4,
            color: 'white',
        },
    }),
    { name: 'ItemChip' },
);

export default function EstadoChip({ estado, className }) {
    const classes = useStyles();

    return <span className={classNames(classes.root, className)}>{estado}</span>;
}

EstadoChip.propTypes = {
    estado: PropTypes.string.isRequired,
    className: PropTypes.string,
};
