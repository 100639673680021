import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { FieldArray, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import useAuthState from '../../../contexts/AuthState';
import { deepEqual } from '../../../utils';
import { LineaMaterial } from './LineaMaterial';

const useStyles = makeStyles(
    (theme) => ({
        addButton: {
            fontSize: 11,
            backgroundColor: '#E9ECEE',
            textTransform: 'none',
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        sinMaterial: {
            fontSize: 13,
            color: '#818CAE',
            border: '1px dashed #818CAE',
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            borderRadius: 8,
        },
    }),
    { name: 'MaterialesForm' },
);

export default function MaterialesForm({
    elementId,
    materiales,
    canEdit,
    onChange,
    onSave,
    preventDeleteAllLineas = false,
}) {
    const { userInfo: { preferencias: { usar_telematel: usarTelematel = false } = {} } = {} } = useAuthState();

    const MaterialSchema = useMemo(
        () =>
            Yup.object().shape({
                materiales: Yup.array().of(
                    Yup.object().shape({
                        descripcion: Yup.string().required('Falta la descripción'),
                        unidades: (usarTelematel
                            ? Yup.number().typeError('Las unidades deben ser numéricas')
                            : Yup.string()
                        ).required('Faltan las unidades'),
                    }),
                ),
            }),
        [usarTelematel],
    );

    const styles = useStyles();
    const snackbar = useSnackbar();
    const [saving, setSaving] = useState(false);

    const collectionName = 'materiales';

    return (
        <Formik
            initialValues={{
                materiales,
            }}
            enableReinitialize={true}
            validationSchema={MaterialSchema}
        >
            {({ setFieldError, setValues, values, errors }) => {
                const changed = useMemo(
                    () => !deepEqual(values.materiales, materiales),
                    [values.materiales, materiales],
                );

                useEffect(() => {
                    if (changed && onChange) {
                        onChange(changed);
                    }
                }, [changed]);

                const hasErrors = errors.materiales !== undefined && errors.materiales.length > 0;
                return (
                    <FieldArray
                        name={collectionName}
                        render={({ push, remove }) => {
                            function handleAddExtraMateriales(selectedMateriales) {
                                selectedMateriales.forEach(({ id, descripcion }) => {
                                    push({
                                        descripcion,
                                        unidades: '',
                                        material_interno_id: id,
                                    });
                                });
                            }

                            return (
                                <>
                                    <div id={elementId}>
                                        {values.materiales.length > 0 ? (
                                            values.materiales.map((material, i) => (
                                                <LineaMaterial
                                                    key={i}
                                                    name={`${collectionName}[${i}]`}
                                                    disabled={!canEdit || Boolean(material.albaran_compra_linea_id)}
                                                    material={material}
                                                    onDelete={
                                                        preventDeleteAllLineas && values.materiales.length === 1
                                                            ? null
                                                            : () => remove(i)
                                                    }
                                                    onAddExtra={handleAddExtraMateriales}
                                                    onReset={() => onChange(false)}
                                                />
                                            ))
                                        ) : (
                                            <Typography className={styles.sinMaterial}>
                                                No hay material para este dia
                                            </Typography>
                                        )}
                                    </div>
                                    {canEdit && (
                                        <>
                                            <Button
                                                variant='contained'
                                                className={styles.addButton}
                                                disableElevation
                                                startIcon={<AddIcon />}
                                                onClick={() => {
                                                    push({ descripcion: '', unidades: '' });
                                                    setTimeout(() => {
                                                        document
                                                            .querySelector(
                                                                `#${elementId}>div:last-of-type input:first-of-type`,
                                                            )
                                                            .focus();
                                                    }, 200);
                                                }}
                                            >
                                                Añadir
                                            </Button>

                                            {changed && (
                                                <>
                                                    <Button
                                                        variant='contained'
                                                        className={styles.addButton}
                                                        disableElevation
                                                        startIcon={<DoneIcon />}
                                                        onClick={() => {
                                                            setSaving(true);

                                                            return onSave(values.materiales)
                                                                .catch((err) => {
                                                                    function processErrors(obj, baseKey) {
                                                                        for (const [field, errors] of Object.entries(
                                                                            obj,
                                                                        )) {
                                                                            let fieldName = field;
                                                                            if (baseKey)
                                                                                fieldName = `${baseKey}.${fieldName}`;
                                                                            if (Array.isArray(errors)) {
                                                                                setFieldError(
                                                                                    fieldName,
                                                                                    errors.join('\n'),
                                                                                );
                                                                            } else {
                                                                                processErrors(errors, fieldName);
                                                                            }
                                                                        }
                                                                    }

                                                                    if (err.status === 400) {
                                                                        if (err.body.message instanceof Object) {
                                                                            processErrors(err.body.message, '');
                                                                        } else {
                                                                            snackbar.showMessage(err.body.message);
                                                                        }
                                                                    } else {
                                                                        console.error(err);
                                                                        snackbar.showMessage('Ha ocurrido un error');
                                                                    }
                                                                })
                                                                .finally(() => setSaving(false));
                                                        }}
                                                        disabled={!changed || saving || hasErrors}
                                                    >
                                                        Guardar
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        className={styles.addButton}
                                                        disableElevation
                                                        startIcon={<CloseIcon />}
                                                        onClick={() => {
                                                            setValues({ materiales });
                                                            onChange(false);
                                                        }}
                                                        disabled={!changed}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            );
                        }}
                    />
                );
            }}
        </Formik>
    );
}

MaterialesForm.propTypes = {
    canEdit: PropTypes.any,
    elementId: PropTypes.any,
    materiales: PropTypes.any,
    onChange: PropTypes.any,
    onSave: PropTypes.any,
    preventDeleteAllLineas: PropTypes.bool,
};
