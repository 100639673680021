import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ProyectoItemCardList from '../proyectos/ProyectosList/ProyectoItemCardList';
import TareaItemList from '../tareas/TareaItemList';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            color: 'white',
            backgroundColor: '#23395F',
            borderRadius: 8,
            height: 36,
            padding: theme.spacing(0, 0, 0, 1),
            marginBottom: theme.spacing(1),
            '& .MuiTypography-root': {
                fontWeight: 400,
                flex: 1,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: 'white',
            },
        },
    }),
    { name: 'ProyectosTab' },
);

export default function ProyectosTab({ proyectos }) {
    const classes = useStyles();
    const [selectedProyecto, setSelectedProyecto] = useState(null);

    if (!selectedProyecto) {
        return <ProyectoItemCardList proyectos={proyectos} onClick={setSelectedProyecto} />;
    }

    return (
        <>
            <div className={classes.header}>
                <FolderOpenIcon />{' '}
                <Typography variant='subtitle2'>Servicios del proyecto {selectedProyecto.nombre}</Typography>
                <IconButton onClick={() => setSelectedProyecto(null)}>
                    <CloseIcon size='small' />
                </IconButton>
            </div>
            <TareaItemList tareas={selectedProyecto.tareas} />
        </>
    );
}

ProyectosTab.propTypes = {
    proyectos: PropTypes.array.isRequired,
};
