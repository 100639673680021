import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { PageHeader } from '../../../common/PageHeader';
import { RoundedButton } from '../../../common/RoundedButton';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
            alignItems: 'center',

            flex: 1,
        },
        message: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(1.5)}px`,
            flex: 1,
            maxWidth: 230,
            color: 'white',
            '& .MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
            },
            '& .MuiSvgIcon-root': {
                fontSize: 64,
            },
        },
    }),
    { name: 'ChooseSolicitudType' },
);

export default function ChooseSolicitudType({ onClose, onListaClicked, onTextoClicked }) {
    const classes = useStyles();
    return (
        <PageHeader
            title={'Selecciona una opción'}
            fill
            startButton={
                <IconButton onClick={onClose}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            }
        >
            <div className={classes.root}>
                <div className={classes.message}>
                    <InfoIcon />
                    <Typography>¿Cómo quieres añadir los materiales?</Typography>
                </div>
                <RoundedButton onClick={onListaClicked}>Lista de material</RoundedButton>
                <RoundedButton onClick={onTextoClicked}>Texto</RoundedButton>
            </div>
        </PageHeader>
    );
}

ChooseSolicitudType.propTypes = {
    onClose: PropTypes.any,
    onListaClicked: PropTypes.any,
    onTextoClicked: PropTypes.any,
};
