import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { formatDate, formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import LabelChip from '../notas/LabelChip';
import { Explicacion } from './Explicacion';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    descripcion: {
        color: '#45486E',
        fontSize: 16,
    },
    fecha: {
        color: '#818CAE',
        fontSize: 11,
        marginBottom: theme.spacing(1.5),
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        marginBottom: theme.spacing(2),
        '& span': {
            fontWeight: 'normal',
        },
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(0.5)}px`,
    },
    addExplicacion: {
        display: 'flex',
        margin: theme.spacing(2, 0),
    },
    addExplicacionTexto: {
        flex: 1,
        boxSizing: 'border-box',
        borderRadius: 4,
        border: '1px dashed #B5BCD1',
        display: 'flex',
        alignItems: 'center',
        color: '#818CAE',
        fontSize: 12,
        paddingLeft: theme.spacing(1.5),
    },
    addButton: {
        marginLeft: 8,
        height: 40,
        minWidth: 44,
        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
    },
}));

export function JornadaExplicacion({ jornada, setAddExplicacionView, disabled, style }) {
    const classes = useStyles();

    const isPropia = jornada.propia;

    const todayStr = formatISODate(new Date());
    const isToday = jornada.fecha === todayStr;

    const bloqueada = jornada.facturada || jornada.revisada;
    const canEdit = !disabled && isPropia && !bloqueada;

    return (
        <PageBodySection className={classes.root} style={style}>
            <Typography className={classes.title}>
                {isToday ? 'Hoy' : formatDate(new Date(jornada.fecha))}{' '}
                {jornada.operario && <span>({jornada.operario})</span>}
                {bloqueada && <LabelChip label={jornada.facturada ? 'Facturado' : 'Revisado'} icon={<LockIcon />} />}
            </Typography>

            {canEdit && jornada.explicacion === null && (
                <div className={classes.addExplicacion}>
                    <Typography className={classes.addExplicacionTexto}>No hay descripción del servicio</Typography>

                    <Button onClick={setAddExplicacionView} className={classes.addButton}>
                        <AddIcon style={{ color: 'white', fontSize: 20 }} />
                    </Button>
                </div>
            )}

            {(jornada.explicacion !== null || bloqueada) && (
                <Explicacion
                    explicacion={jornada.explicacion}
                    setAddExplicacionView={canEdit ? setAddExplicacionView : null}
                />
            )}
        </PageBodySection>
    );
}

JornadaExplicacion.propTypes = {
    disabled: PropTypes.any,
    jornada: PropTypes.any,
    setAddExplicacionView: PropTypes.any,
    style: PropTypes.any,
};
