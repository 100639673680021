import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

export const RoundedButton = withStyles(
    (theme) => ({
        root: {
            borderRadius: 30,
            minWidth: 250,
            maxWidth: 250,
            height: 42,
            color: 'white',
            backgroundColor: '#213061',
            alignSelf: 'center',
            '&:active, &:focus': {
                backgroundColor: '#213061',
            },
            '&.Mui-disabled': {
                color: 'white',
                opacity: 0.5,
            },
        },
        label: {
            fontWeight: 500,
            fontSize: 14,
        },
        outlined: {
            color: 'white',
            backgroundColor: 'transparent',
            borderColor: 'white',
            '&:active, &:focus': {},
        },
    }),
    { name: 'RoundedButton' },
)(Button);
