import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '../../common/Button';
import { PageBody } from '../../common/PageBody';
import { useTareaState } from '../TareaView';
import InfoPageBodySection from './InfoPageBodySection';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
        },
        text: {
            whiteSpace: 'pre-wrap',
            fontWeight: 'normal',
        },
    }),
    { name: 'ContactoPage' },
);

export default function ContactoPage() {
    const classes = useStyles();
    const { tarea } = useTareaState();

    function openMaps(direccion, placeId) {
        const params = {
            destination: direccion,
        };

        if (placeId) params.destination_place_id = placeId;

        const url = `https://www.google.com/maps/dir/?api=1&${new URLSearchParams(params).toString()}`;
        window.open(url, '_blank');
    }

    const telefono = tarea.direccion.telefono || tarea.telefono;

    return (
        <PageBody className={classes.root} paddingTop={0}>
            <InfoPageBodySection>
                <Typography variant='body2'>Dirección del servicio</Typography>
                <Typography variant='body2' className={classes.text}>
                    {tarea.direccion.direccion_completa}
                </Typography>
                {tarea.direccion.notas && (
                    <Typography variant='body2' className={classes.text}>
                        {tarea.direccion.notas}
                    </Typography>
                )}
                <Button
                    color='info'
                    fullWidth
                    onClick={() => {
                        openMaps(tarea.direccion.direccion_completa, tarea.direccion.google_place_id);
                    }}
                >
                    Ir con Google Maps
                </Button>
            </InfoPageBodySection>
            <InfoPageBodySection>
                <Typography variant='body2'>Teléfono</Typography>
                <Typography variant='body2' className={classes.text}>
                    {telefono || 'No especificado'}
                </Typography>
                <Button
                    color='success'
                    disabled={!telefono}
                    onClick={() => {
                        window.open('tel://' + telefono.replace(/[^0-9]+/g, ''));
                    }}
                    fullWidth
                >
                    Llamar
                </Button>
            </InfoPageBodySection>
        </PageBody>
    );
}
