import { ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import useAuthState from '../../../contexts/AuthState';
import Button from '../../common/Button';
import { PageBodySection } from '../../common/PageBodySection';
import { useTareaState } from '../TareaView';
import { MaterialUtilizado } from './MaterialUtilizado';
import SolicitudesMaterial from './solicitudes/SolicitudesMaterial';

const useStyles = makeStyles(
    (theme) => ({
        viewSelector: {
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
        },
    }),
    { name: 'MaterialTarea' },
);

const MATERIAL_UTILIZADO = 'material-utilizado';
const SOLICITUDES_MATERIAL = 'solicitudes-material';

export default function MaterialTarea() {
    const classes = useStyles();
    const { id, tarea, setTarea, viewParams } = useTareaState();
    const editable = tarea.estado !== 'FINALIZADA';

    const { userInfo: { preferencias: { usar_solicitudes_material: usarSolicitudesMaterial = false } = {} } = {} } =
        useAuthState();

    const [view, setView] = useState(viewParams.showSolicitudesMaterial ? SOLICITUDES_MATERIAL : MATERIAL_UTILIZADO);

    return (
        <>
            {usarSolicitudesMaterial && !viewParams.solicitud && (
                <PageBodySection className={classes.viewSelector}>
                    <ButtonGroup size='small'>
                        <Button
                            color={view === MATERIAL_UTILIZADO ? 'info' : 'normal'}
                            onClick={() => setView(MATERIAL_UTILIZADO)}
                        >
                            Material utilizado
                        </Button>
                        <Button
                            color={view === SOLICITUDES_MATERIAL ? 'info' : 'normal'}
                            onClick={() => setView(SOLICITUDES_MATERIAL)}
                        >
                            Solicitudes de material
                        </Button>
                    </ButtonGroup>
                </PageBodySection>
            )}
            {view === MATERIAL_UTILIZADO ? (
                <MaterialUtilizado
                    id={id}
                    jornadas={tarea.jornadas.map((jornada) => ({
                        id: jornada.id,
                        fecha: jornada.fecha,
                        materiales: jornada.materiales,
                        facturada: jornada.facturada,
                        revisada: jornada.revisada,
                        propia: jornada.propia,
                        operario: jornada.operario,
                    }))}
                    setJornadas={(jornadas) => {
                        setTarea((tarea) => ({
                            ...tarea,
                            jornadas: typeof jornadas === 'function' ? jornadas(tarea.jornadas) : jornadas,
                        }));
                    }}
                    editable={editable}
                />
            ) : (
                <SolicitudesMaterial />
            )}
        </>
    );
}
