import MuiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { clientesProvider, tareasProvider } from '../../api';
import { gastosLabels, processTarea } from '../../api/tareas';
import { addTiempo } from '../../api/tareas-functions';
import useAuthState, { usePermisos, usePreferencias } from '../../contexts/AuthState';
import useSplash from '../../contexts/SplashState';
import { formatTiempo } from '../../utils';
import { BigButton } from '../common/BigButton';
import { ButtonDialog } from '../common/ButtonDialog';
import { EditDialogView } from '../common/EditDialogView';
import { PageBodySection } from '../common/PageBodySection';
import { StartLabelSwitch } from '../common/SwitchWithLabel';
import FirmaContainer from './FirmaContainer';
import { FIRMAR, GENERAL, TareaHeader, useTareaState } from './TareaView';

const useStyles = makeStyles(
    (theme) => ({
        tiempo: {
            display: 'flex',
            '& h4': {
                flex: 1,
            },
            '& span': {
                color: '#818cae',
            },
        },
        explicacion: {
            fontSize: 14,
            color: '#818cae',
            opacity: 0.5,
            marginBottom: theme.spacing(2),
            whiteSpace: 'pre',
        },
        lista: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            '&>div': {
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: 13,
                color: '#818cae',
            },
        },
        sendCopySwitch: {
            marginTop: 'auto',
            marginBottom: theme.spacing(2),
        },
        buttons: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        cerrarButton: {},
        cerrarSinFirmarButton: {
            textTransform: 'none',
            color: '#818cae',
            fontSize: 13,
        },
        emailEnvios: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(1.5),
            '& .MuiTypography-root': {
                flex: 1,
                fontSize: 12,
                color: '#818cae',
            },
        },
    }),
    { name: 'CerrarView' },
);

export function CerrarView() {
    const { id, tarea, setTarea, setView, viewParams } = useTareaState();

    const albaran = viewParams.albaran;
    const isFirmaExistente = albaran && albaran.firma && albaran.firma.image_url;
    // const firma = isFirmaExistente ? albaran.firma : viewParams.firma;
    const clienteId = tarea.cliente_id;

    const enviarAlbaranes = usePermisos('enviar_albaranes');
    const enviarMailCliente = usePreferencias('enviar_mail_cliente') && enviarAlbaranes;

    const enviarCopia = tarea.enviarCopia !== undefined ? tarea.enviarCopia : enviarMailCliente;
    const setEnviarCopia = (checked) => setTarea((tarea) => ({ ...tarea, enviarCopia: checked }));

    function onAlbaranGenerado(tarea) {
        setTarea(processTarea(tarea));
        setView(GENERAL, {});
    }

    return (
        <BaseCerrarView
            tareaId={id}
            albaran={viewParams.albaran}
            firma={isFirmaExistente ? albaran.firma : viewParams.firma}
            firmaExistente={isFirmaExistente ? albaran.firma : null}
            clienteId={clienteId}
            jornadas={tarea.jornadas}
            enviarCopia={enviarCopia}
            setEnviarCopia={setEnviarCopia}
            onRepetirFirma={() => setView(FIRMAR, viewParams)}
            onAlbaranGenerado={onAlbaranGenerado}
        />
    );
}

export function BaseCerrarView({
    albaran,
    firma,
    firmaExistente,
    tareaId,
    clienteId,
    jornadas: jornadasAlbaran,
    enviarCopia,
    setEnviarCopia,
    onRepetirFirma,
    onAlbaranGenerado,
}) {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const [cerrando, setCerrando] = useState(false);
    const [emailEnvios, setEmailEnvios] = useState(false);
    const { showCustomComponent } = useSplash();

    const enviarAlbaranes = usePermisos('enviar_albaranes');
    const isFirmaExistente = Boolean(firmaExistente);

    const {
        userInfo: { preferencias },
    } = useAuthState();
    // eslint-disable-next-line camelcase
    const desglosarHoras = preferencias?.desglosar_horas_operario;

    useEffect(() => {
        clientesProvider.getEmailEnvios(clienteId).then((res) => setEmailEnvios(res.email_envios_list));
    }, [clienteId]);

    const { jornadas, explicacion } = useMemo(() => {
        const albaranId = albaran ? albaran.id : null;
        const jornadas = jornadasAlbaran.filter((jornada) => jornada.albaran_id === albaranId);

        const explicacion = albaran
            ? albaran.explicacion
            : jornadas
                  .map((jornada) => jornada.explicacion)
                  .filter(Boolean)
                  .join('\n\n');

        return { jornadas, explicacion };
    }, [jornadasAlbaran, albaran]);

    const tiempoAcumuladoTotal = jornadas.reduce((tiempo, jornada) => addTiempo(tiempo, jornada.tiempo), null);

    const listaMateriales = jornadas.map((jornada) => jornada.materiales).flat();
    const materiales = listaMateriales.map((m, i) => (
        <div style={{ marginBottom: 4 }} key={i}>
            <span>{m.descripcion}</span>
            <span>{m.unidades}</span>
        </div>
    ));

    const tiposGastos = Object.keys(gastosLabels);
    const listaGastos = Object.fromEntries(tiposGastos.map((tipo) => [tipo, 0]));

    const tiemposPorOperario = {};

    jornadas.forEach((jornada) => {
        tiemposPorOperario[jornada.operario] = addTiempo(tiemposPorOperario[jornada.operario], jornada.tiempo);

        if (jornada.gastos === null) return;

        tiposGastos.forEach((tipo) => {
            if (jornada.gastos[tipo]) listaGastos[tipo] += parseFloat(jornada.gastos[tipo]);
        });
    });

    const gastos = Object.entries(listaGastos)
        .filter(([, dias]) => dias > 0)
        .map(([key, dias]) => (
            <div style={{ marginBottom: 4 }} key={key}>
                <span>{gastosLabels[key]}</span>
                <span>{dias % 1 !== 0 ? dias.toFixed(2) : dias}</span>
            </div>
        ));

    // const enviarCopia = tarea.enviarCopia !== undefined ? tarea.enviarCopia : enviarMailCliente;
    // const setEnviarCopia = (checked) => setTarea((tarea) => ({ ...tarea, enviarCopia: checked }));

    const degloseTiempo = Object.entries(tiemposPorOperario).map(([operario, tiempo], i) => (
        <div style={{ marginBottom: 4 }} key={i}>
            <span>{operario}</span>
            <span>{formatTiempo(tiempo)}</span>
        </div>
    ));

    const cerrarParams = {
        albaranId: albaran ? albaran.id : null,
        ids: jornadas.map((j) => j.id),
        enviarCopia,
        // coordenadas,
    };

    return (
        <>
            <PageBodySection>
                <div className={classes.tiempo}>
                    <Typography variant='h4'>Tiempo trabajado</Typography>
                    <Typography variant='h4' component='span'>
                        {formatTiempo(tiempoAcumuladoTotal)}
                    </Typography>
                </div>
                {desglosarHoras && <div className={classes.lista}>{degloseTiempo}</div>}
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Descripción</Typography>
                <Typography className={classes.explicacion}>{explicacion}</Typography>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Material utilizado</Typography>
                <div className={classes.lista}>{materiales}</div>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Otros gastos</Typography>
                <div className={classes.lista}>{gastos}</div>
            </PageBodySection>
            {firma && (
                <PageBodySection>
                    <FirmaContainer
                        onRepetirFirma={onRepetirFirma}
                        firma={firma}
                        imageSource={isFirmaExistente ? firma.image_url : firma.imagen}
                    />
                </PageBodySection>
            )}
            {!isFirmaExistente && (
                <PageBodySection className={classes.buttons} style={{ paddingTop: 16 }}>
                    {enviarAlbaranes && (
                        <>
                            <StartLabelSwitch
                                name='send_email'
                                label='Enviar copia del albarán de servicio al cliente'
                                checked={enviarCopia}
                                onChange={(ev) => setEnviarCopia(ev.target.checked)}
                                className={classes.sendCopySwitch}
                            />
                            <div className={classes.emailEnvios}>
                                <Typography>Email: {emailEnvios}</Typography>
                                <IconButton
                                    size='small'
                                    onClick={() => {
                                        showCustomComponent(({ closeSplash }) => (
                                            <EditDialogView
                                                header={<TareaHeader title='Editar email cliente' />}
                                                onClose={(emails) => {
                                                    closeSplash();
                                                    setEmailEnvios(emails);
                                                }}
                                                currentText={emailEnvios}
                                                onChange={(emails) =>
                                                    clientesProvider.setEmailEnvios(clienteId, emails)
                                                }
                                            />
                                        ));
                                    }}
                                >
                                    <EditIcon size='small' />
                                </IconButton>
                            </div>
                        </>
                    )}

                    {firma ? (
                        <BigButton
                            onClick={() => {
                                setCerrando(true);
                                const url = albaran ? `albaranes/${albaran.id}/firmar` : `${tareaId}/albaranes`;

                                tareasProvider
                                    .action(url, {
                                        method: 'post',
                                        body: JSON.stringify({
                                            ...firma,
                                            ...cerrarParams,
                                        }),
                                    })
                                    .then((result) => {
                                        setCerrando(false);
                                        return onAlbaranGenerado(result);
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        snackbar.showMessage(err.message);
                                        setCerrando(false);
                                    });
                            }}
                            disabled={cerrando}
                        >
                            GUARDAR ALBARÁN
                        </BigButton>
                    ) : (
                        <BigButton onClick={onRepetirFirma}>FIRMAR</BigButton>
                    )}
                    {!albaran && (
                        <ButtonDialog
                            title='¿Estás seguro que quieres guardar este albarán sin firmar?'
                            // content='Debes estar seguro que no realizarás mas horas en este trabajo.'
                            okText='Guardar'
                            button={
                                <MuiButton
                                    className={classes.cerrarSinFirmarButton}
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={cerrando}
                                >
                                    Guardar sin firmar
                                </MuiButton>
                            }
                            onAccept={() => {
                                setCerrando(true);
                                tareasProvider
                                    .actionOnId(tareaId, 'albaranes', cerrarParams)
                                    .then((result) => {
                                        setCerrando(false);
                                        return onAlbaranGenerado(result);
                                    })
                                    .catch(() => {
                                        setCerrando(false);
                                    });
                            }}
                        />
                    )}
                </PageBodySection>
            )}
        </>
    );
}

BaseCerrarView.propTypes = {
    albaran: PropTypes.any,
    tareaId: PropTypes.any,
    clienteId: PropTypes.any,
    firma: PropTypes.any,
    firmaExistente: PropTypes.any,
    jornadas: PropTypes.any,
    enviarCopia: PropTypes.any,
    setEnviarCopia: PropTypes.any,
    onRepetirFirma: PropTypes.any,
    onAlbaranGenerado: PropTypes.any,
};
