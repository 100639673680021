import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import debounce from '@material-ui/core/utils/debounce';
import LockIcon from '@material-ui/icons/Lock';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { tareasProvider } from '../../../api';
import { formatDate, formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import LabelChip from '../notas/LabelChip';
import { RoundedInput } from '../RoundedInput';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(0.5)}px`,
    },
    lineaGasto: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            fontSize: 13,
            flex: 1,
        },
    },
    gastoInput: {
        textAlign: 'right',
        width: 80,
    },
}));

export function GastosJornada({ index, jornada, editable, onSaveGasto }) {
    const styles = useStyles();

    const todayStr = formatISODate(new Date());
    const isToday = jornada.fecha === todayStr;

    const collectionName = `jornadas[${index}].gastos`;

    const [{ value }, { initialValue, error }, { setError }] = useField(collectionName);

    const debouncedSave = useCallback(
        debounce(
            (value) =>
                tareasProvider
                    .action(`jornadas/${jornada.id}/gastos`, {
                        method: 'post',
                        body: JSON.stringify(value),
                    })
                    .then((data) => {
                        onSaveGasto(index, value);
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            setError(err.message);
                        }
                    }),
            1000,
        ),
        [jornada.id],
    );

    useEffect(() => {
        const keys = Object.keys(value);
        const changed = keys.some((key) => value[key] !== initialValue[key]);

        if (!changed) return;

        debouncedSave(value);
    }, [value, initialValue]);

    const bloqueada = jornada.facturada || jornada.revisada;
    const canEdit = editable && !bloqueada;

    return (
        <PageBodySection className={styles.root}>
            <Typography className={styles.title}>
                {isToday ? 'Hoy' : formatDate(new Date(jornada.fecha))}{' '}
                {jornada.operario && <span>({jornada.operario})</span>}
                {bloqueada && <LabelChip label={jornada.facturada ? 'Facturado' : 'Revisado'} icon={<LockIcon />} />}
            </Typography>

            <div className={styles.lineaGasto}>
                <Typography color={error?.dietas ? 'error' : 'default'}>
                    Dietas {error?.dietas ? '(Inválido)' : null}
                </Typography>
                <RoundedInput className={styles.gastoInput} name={`${collectionName}.dietas`} disabled={!canEdit} />
            </div>
            <div className={styles.lineaGasto}>
                <Typography color={error?.desplazamiento ? 'error' : 'default'}>
                    Desplazamiento {error?.desplazamiento ? '(Inválido)' : null}
                </Typography>
                <RoundedInput
                    className={styles.gastoInput}
                    name={`${collectionName}.desplazamiento`}
                    disabled={!canEdit}
                />
            </div>
            <div className={styles.lineaGasto}>
                <Typography color={error?.parking ? 'error' : 'default'}>
                    Parking {error?.parking ? '(Inválido)' : null}
                </Typography>
                <RoundedInput className={styles.gastoInput} name={`${collectionName}.parking`} disabled={!canEdit} />
            </div>
        </PageBodySection>
    );
}

GastosJornada.propTypes = {
    index: PropTypes.any,
    jornada: PropTypes.any,
    onSaveGasto: PropTypes.any,
    editable: PropTypes.bool,
};
