import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { TimeIcon } from '@material-ui/pickers/_shared/icons/TimeIcon';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { createTiempo } from '../../api/tareas-functions';
import { formatTiempo } from '../../utils';
import SlidingDialog from '../common/SlidingDialog';

const useStyles = makeStyles(
    (theme) => ({
        item: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #23395F',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(2)}px`,
            '&:first-of-type': {
                marginTop: theme.spacing(2),
            },
        },
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            fontSize: 14,
            fontWeight: 500,
            flex: 1,
        },
        time: {
            fontSize: 18,
            fontWeight: 500,
        },
        subItems: {
            gap: `${theme.spacing(4)}px`,
        },
        subItem: {
            display: 'flex',
            flexDirection: 'column',
        },
        label2: {
            fontSize: 12,
            fontWeight: 400,
        },
        time2: {
            fontSize: 16,
            fontWeight: 500,
        },
    }),
    { name: 'ResumenSliderDialog' },
);

export default function ResumenSliderDialog({ title, resumen }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    function onClose() {
        setOpen(false);
    }

    const { horasEfectivas, horasTrabajadas, horasExtras, horasLaborables, horasVacaciones, horasAusencias } =
        useMemo(() => {
            const agregado = resumen.reduce(
                (acc, item) => {
                    acc.horasEfectivas = acc.horasEfectivas + item.laborables.efectivas;
                    acc.horasTrabajadas = acc.horasTrabajadas + item.jornada;
                    acc.horasLaborables = acc.horasLaborables + item.laborables.horario;
                    acc.horasVacaciones = acc.horasVacaciones + item.laborables.vacaciones;
                    acc.horasAusencias = acc.horasAusencias + item.laborables.ausencias;
                    return acc;
                },
                {
                    horasEfectivas: 0,
                    horasTrabajadas: 0,
                    horasLaborables: 0,
                    horasVacaciones: 0,
                    horasAusencias: 0,
                },
            );

            agregado.horasExtras = Math.max(0, agregado.horasTrabajadas - agregado.horasEfectivas);

            return agregado;
        }, [resumen]);

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <TimeIcon />
            </IconButton>
            <SlidingDialog open={open} onClose={onClose} title={title} removePadding>
                <div className={classes.item}>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Horas de trabajo efectivas:</Typography>
                        <Typography className={classes.time}>{formatTiempo(createTiempo(horasEfectivas))}</Typography>
                    </div>
                    <div className={clsx(classes.row, classes.subItems)}>
                        <div className={classes.subItem}>
                            <Typography className={classes.label2}>Laborables</Typography>
                            <Typography className={classes.time2}>
                                {formatTiempo(createTiempo(horasLaborables))}
                            </Typography>
                        </div>
                        <div className={classes.subItem}>
                            <Typography className={classes.label2}>Vacaciones</Typography>
                            <Typography className={classes.time2}>
                                {formatTiempo(createTiempo(horasVacaciones))}
                            </Typography>
                        </div>
                        <div className={classes.subItem}>
                            <Typography className={classes.label2}>Ausencias</Typography>
                            <Typography className={classes.time2}>
                                {formatTiempo(createTiempo(horasAusencias))}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Horas trabajadas:</Typography>
                        <Typography className={classes.time}>{formatTiempo(createTiempo(horasTrabajadas))}</Typography>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.row}>
                        <Typography className={classes.label}>Horas extras:</Typography>
                        <Typography className={classes.time}>{formatTiempo(createTiempo(horasExtras))}</Typography>
                    </div>
                </div>
            </SlidingDialog>
        </>
    );
}

ResumenSliderDialog.propTypes = {
    resumen: PropTypes.any,
    title: PropTypes.any,
};
