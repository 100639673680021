import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import { addMinutes, startOfDay } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fichajesProvider } from '../../../api';
import { getTiempoTotalMarcajes } from '../../../api/fichajes';
import { createTiempo, getMinutos } from '../../../api/tareas-functions';
import useSplash from '../../../contexts/SplashState';
import { format, formatFullDateTime, formatTiempo } from '../../../utils';
import Button from '../../common/Button';
import { ButtonDialog } from '../../common/ButtonDialog';
import EntradaIcon from '../../icons/Entrada';
import SalidaIcon from '../../icons/Salida';
import { BaseEditTime } from '../../tareas/EditTime';
import SolicitarRevisionButton from '../SolicitarRevisionButton';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(1),
            flex: 1,
        },
        loading: {
            color: 'white',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(3)}px`,
            '& .MuiCircularProgress-root': {
                color: 'white',
            },
        },
        totalHoy: {
            color: 'white',
            fontSize: 20,
            padding: theme.spacing(1.5, 2),
            borderRadius: 24,
            lineHeight: '20px',
            marginBottom: theme.spacing(3),
            alignSelf: 'center',
            '&.success': {
                backgroundColor: theme.palette.success.main,
            },
            '&.warning': {
                backgroundColor: theme.palette.warning.main,
            },
            '&.error': {
                backgroundColor: theme.palette.error.main,
            },
        },
        lista: {
            marginBottom: theme.spacing(2),
            width: '100%',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
        },
        marcajeWrapper: {
            padding: theme.spacing(2, 0),
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid rgba(255, 255, 255, 0.5)',
            '&:last-of-type': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
            },
        },
        marcaje: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
            '&>div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            },
            '& .MuiTypography-body1': {
                fontSize: 14,
                fontWeight: 400,
            },
            '& .MuiTypography-body2': {
                fontSize: 32,
                fontWeight: 400,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 32,
            },
        },
        marcajePausa: {
            textAlign: 'center',
        },
        dialogContentText: {
            fontSize: 16,
            color: '#213061',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& div + div': {
                marginTop: theme.spacing(1.5),
            },
        },
        buttons: {
            marginTop: 'auto',
            padding: theme.spacing(0, 2, 2, 2),
        },
    }),
    { name: 'ListaFichajesHoy' },
);

export default function ListaFichajesHoy({ style, className }) {
    const classes = useStyles();
    const [fichaje, setFichaje] = useState(null);
    const [coordenadas, setCoordenadas] = useState(null);
    const { showCustomComponent } = useSplash();
    const snackbar = useSnackbar();

    const fetchMarcajes = useCallback(() => fichajesProvider.getMarcajesHoy().then(setFichaje), []);

    const marcajes = fichaje ? fichaje.marcajes : null;
    const marcajePausa = fichaje ? fichaje.marcaje_pausa : null;
    const minutosHorario = fichaje ? fichaje.horario : 0;

    useEffect(() => {
        fetchMarcajes();
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                function ({ coords: { latitude, longitude, accuracy } }) {
                    setCoordenadas({ latitude, longitude, accuracy });
                },
                function () {
                    setCoordenadas(null);
                },
                {
                    enableHighAccuracy: true,
                },
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }
    }, []);

    const tiempoHoy = useMemo(() => (marcajes ? getTiempoTotalMarcajes(marcajes) : 0), [marcajes]);
    const minutosHoy = getMinutos(tiempoHoy);
    const tiempoHoyClass = minutosHoy === 0 ? 'error' : minutosHoy >= minutosHorario ? 'success' : 'warning';

    const puedeEntrar = marcajes && (marcajes.length === 0 || marcajes[marcajes.length - 1].hora_salida !== null);

    const ficharEntrada = () =>
        fichajesProvider
            .ficharEntrada(coordenadas)
            .then(fetchMarcajes)
            .catch((err) => snackbar.showMessage(err.message));
    const ficharSalida = () =>
        fichajesProvider
            .ficharSalida(coordenadas)
            .then(fetchMarcajes)
            .catch((err) => snackbar.showMessage(err.message));

    const minutesSinceStartOfDay = differenceInMinutes(new Date(), startOfDay(new Date()));
    const tiempoNow = createTiempo(minutesSinceStartOfDay);

    const canRegistrarDescanso =
        marcajes &&
        marcajes.length > 0 &&
        marcajes[marcajes.length - 1].hora_salida === null &&
        (!marcajePausa || !marcajePausa.hora_salida);

    return (
        <div className={classnames(className, classes.root)} style={style}>
            {marcajes === null ? (
                <div className={classes.loading}>
                    <CircularProgress />
                    <Typography variant='h3'>Cargando fichajes</Typography>
                </div>
            ) : (
                <>
                    <div className={classnames(classes.totalHoy, tiempoHoyClass)}>{formatTiempo(tiempoHoy)}</div>

                    {marcajes.length > 0 && (
                        <div className={classes.lista}>
                            {marcajes.map((marcaje, i) => {
                                const pausaInside =
                                    marcajePausa &&
                                    marcajePausa.hora_entrada >= marcaje.hora_entrada &&
                                    (!marcaje.hora_salida || marcajePausa.hora_salida <= marcaje.hora_salida);

                                return (
                                    <div key={i} className={classes.marcajeWrapper}>
                                        <div className={classes.marcaje}>
                                            <div>
                                                <Typography variant='body1'>Entrada</Typography>
                                                <Typography variant='body2'>
                                                    {format(marcaje.hora_entrada, 'HH:mm')}
                                                </Typography>
                                            </div>

                                            <ArrowForwardIcon />

                                            <div>
                                                <Typography variant='body1'>Salida</Typography>
                                                <Typography variant='body2'>
                                                    {marcaje.hora_salida
                                                        ? format(marcaje.hora_salida, 'HH:mm')
                                                        : '--:--'}
                                                </Typography>
                                            </div>
                                        </div>
                                        {pausaInside && marcajePausa.hora_entrada && (
                                            <Typography variant='body1' className={classes.marcajePausa}>
                                                {marcajePausa.hora_salida
                                                    ? `Descanso registrado de ${format(
                                                          marcajePausa.hora_entrada,
                                                          'HH:mm',
                                                      )} a ${format(marcajePausa.hora_salida, 'HH:mm')}`
                                                    : `Descanso iniciado a las ${format(
                                                          marcajePausa.hora_entrada,
                                                          'HH:mm',
                                                      )}`}
                                            </Typography>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div className={classes.buttons}>
                        {puedeEntrar ? (
                            <ButtonDialog
                                button={
                                    <Button color='primaryFilled' rounded style={{ marginBottom: 16 }} fullWidth>
                                        Fichar entrada
                                    </Button>
                                }
                                onAccept={ficharEntrada}
                                title='¿Estás seguro que quieres fichar la entrada?'
                                content={
                                    <div className={classnames(classes.lista, classes.dialogContentText)}>
                                        <div className='entrada'>
                                            <EntradaIcon /> Entrada
                                        </div>
                                        <div>{formatFullDateTime(new Date())}</div>
                                    </div>
                                }
                                okText='Fichar entrada'
                                okColor='success'
                            />
                        ) : (
                            <ButtonDialog
                                button={
                                    <Button color='primaryFilled' rounded style={{ marginBottom: 16 }} fullWidth>
                                        Fichar salida
                                    </Button>
                                }
                                onAccept={ficharSalida}
                                title='¿Estás seguro que quieres fichar la salida?'
                                content={
                                    <div className={classnames(classes.lista, classes.dialogContentText)}>
                                        <div className='salida'>
                                            <SalidaIcon /> Salida
                                        </div>
                                        <div>{formatFullDateTime(new Date())}</div>
                                    </div>
                                }
                                okText='Fichar salida'
                                okColor='error'
                            />
                        )}

                        {canRegistrarDescanso && (
                            <Button
                                style={{ marginBottom: 16 }}
                                color='primary'
                                rounded
                                outline
                                fullWidth
                                onClick={() => {
                                    showCustomComponent(({ closeSplash }) => (
                                        <BaseEditTime
                                            title='Selecciona el tiempo'
                                            selectedDate={new Date()}
                                            onClose={closeSplash}
                                            tiempo={tiempoNow}
                                            applyInterval={false}
                                            onAccept={(tiempo) => {
                                                closeSplash();

                                                const minutos = tiempo.horas * 60 + tiempo.minutos;
                                                const fecha = addMinutes(startOfDay(new Date()), minutos);

                                                const newMarcajePausa =
                                                    marcajePausa && marcajePausa.hora_entrada
                                                        ? {
                                                              hora_salida: fecha,
                                                              coords_salida: coordenadas,
                                                          }
                                                        : {
                                                              hora_entrada: fecha,
                                                              coords_entrada: coordenadas,
                                                          };

                                                return fichajesProvider
                                                    .ficharPausa(newMarcajePausa)
                                                    .then(fetchMarcajes)
                                                    .catch((err) => snackbar.showMessage(err.message));
                                            }}
                                        />
                                    ));
                                }}
                            >
                                {marcajePausa && marcajePausa.hora_entrada
                                    ? 'Registrar fin de descanso'
                                    : 'Registrar descanso'}
                            </Button>
                        )}

                        {fichaje.id && <SolicitarRevisionButton fichaje={fichaje} onUpdate={fetchMarcajes} />}
                    </div>
                </>
            )}
        </div>
    );
}

ListaFichajesHoy.propTypes = {
    className: PropTypes.any,
    style: PropTypes.any,
};
