import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { fichajesProvider } from '../../api';
import { format } from '../../utils';
import Button from '../common/Button';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import { BaseFirmarView } from '../tareas/firmar/FirmarView';

const useStyles = makeStyles(
    (theme) => ({
        firmaContainer: {
            background: 'white',
            paddingBottom: theme.spacing(2),
            marginTop: 'auto',
        },
        signature: {
            border: '1px dashed #bababa',
            borderRadius: 4,
            maxHeight: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            '& img': {
                margin: theme.spacing(2),
                objectFit: 'cover',
                overflow: 'hidden',
            },
        },
        signatureName: {
            fontSize: 12,
            color: '#818cae',
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'FirmaExterna' },
);

export default function FirmaExterna() {
    const classes = useStyles();
    const history = useHistory();
    const { token } = useParams();
    function base64ToJson(base64String) {
        const json = Buffer.from(base64String, 'base64').toString();
        return JSON.parse(json);
    }
    const decodedToken = base64ToJson(token);
    const { operario_id: operarioId, nombre, year, month } = decodedToken;
    // const [pendiente, setPendiente] = useState(null);
    const [firma, setFirma] = useState(null);
    const fecha = new Date(year, month - 1);

    function checkPendiente() {
        fichajesProvider.firmaMesPendiente(year, month, operarioId).then(({ pendiente }) => {
            // setPendiente(pendiente);
            if (pendiente === false) history.replace('/');
        });
    }

    useEffect(() => {
        checkPendiente();
    }, []);

    return (
        <>
            <CssBaseline />
            {firma ? (
                <>
                    <PageHeader title={`Hoja de horas de ${format(fecha, 'MMMM yyyy')}`} />
                    <PageBody>
                        <Typography variant='h4'>Firma de {nombre}</Typography>
                        <div className={classes.signature}>
                            <img src={firma.imagen} />
                        </div>

                        <Button
                            color='primaryFilled'
                            rounded
                            fullWidth
                            style={{ marginTop: 'auto' }}
                            onClick={() => {
                                fichajesProvider
                                    .firmarMes(year, month, firma, operarioId)
                                    .then(() => history.replace('/'));
                            }}
                        >
                            Guardar firma
                        </Button>
                    </PageBody>
                </>
            ) : (
                <BaseFirmarView
                    defaultNombre={nombre}
                    descripcion={`Hoja de horas de ${format(fecha, 'MMMM yyyy')}`}
                    nombreDisabled
                    onSave={(firma) => {
                        setFirma(firma);
                    }}
                />
            )}
        </>
    );
}
