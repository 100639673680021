import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SlidingDialog from '../../common/SlidingDialog';

export default function MenuSlidingDialog() {
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    function onClose() {
        setOpen(false);
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <MoreVertIcon />
            </IconButton>
            <SlidingDialog open={open} onClose={onClose} title='Información del proyecto' removePadding>
                <List>
                    <ListItem
                        button
                        onClick={() => {
                            history.replace(`/proyectos/${id}`);
                            onClose();
                        }}
                    >
                        <ListItemText primary='Servicios' />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            history.replace(`/proyectos/${id}/presupuestos`);
                            onClose();
                        }}
                    >
                        <ListItemText primary='Presupuestos' />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            history.replace(`/proyectos/${id}/observaciones`);
                            onClose();
                        }}
                    >
                        <ListItemText primary='Observaciones y fotos' />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            history.replace(`/proyectos/${id}/solicitudes-material`);
                            onClose();
                        }}
                    >
                        <ListItemText primary='Solicitudes de material' />
                    </ListItem>
                </List>
            </SlidingDialog>
        </>
    );
}
