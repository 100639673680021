import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { BigButton } from '../../common/BigButton';
import { GlobalFilter } from '../../common/ListView';
import { PARTES_MANTENIMIENTO_RESUMEN, PARTE_MANTENIMIENTO, useTareaState } from '../TareaView';

const PENDIENTE = 'PENDIENTE';
const EN_PROGRESO = 'EN_PROGRESO';
const APTO = 'APTO';
const NO_APTO = 'NO_APTO';

const estadoLabels = {
    [PENDIENTE]: 'Pendiente',
    [EN_PROGRESO]: 'En progreso',
    [APTO]: 'Apto',
    [NO_APTO]: 'No apto',
};

const useStyles = makeStyles(
    (theme) => ({
        partesList: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #D8DAE1',
            padding: theme.spacing(1, 2),
        },
        itemContent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontSize: 14,
            fontWeight: 600,
        },
        subtitle: {
            fontSize: 13,
        },
        chip: {
            fontSize: 8,
            padding: theme.spacing(0.5, 1),
            marginRight: theme.spacing(2),
            borderRadius: 10,
            textTransform: 'uppercase',
            width: 65,
            textAlign: 'center',
            [`&.${PENDIENTE}`]: {
                backgroundColor: '#6B6C7E',
                color: '#FEE5E5',
            },
            [`&.${EN_PROGRESO}`]: {
                backgroundColor: '#FFECCF',
                color: '#F3AC3D',
            },
            [`&.${APTO}`]: {
                backgroundColor: '#CFF3E9',
                color: '#2CBD96',
            },
            [`&.${NO_APTO}`]: {
                backgroundColor: '#FEE5E5',
                color: '#E94F4F',
            },
        },
        button: {
            margin: theme.spacing(2),
            marginTop: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
        },
        info: {
            alignSelf: 'flex-start',
            fontSize: 10,
            color: '#818CAE',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(1),
            '& svg': {
                fontSize: 13,
                marginRight: theme.spacing(0.5),
            },
        },
    }),
    { name: 'PartesMantenimientoListView' },
);

export const PartesMantenimientoListView = () => {
    const classes = useStyles();
    const { tarea, setView } = useTareaState();
    const [filter, setFilter] = useState('');

    const allTerminado = tarea.partes_mantenimiento.every((parte) => parte.terminado);

    const partesMantenimiento = useMemo(() => {
        if (!filter) return tarea.partes_mantenimiento;

        const lowerFilter = filter.trim().toLowerCase();
        if (lowerFilter === '') return tarea.partes_mantenimiento;

        return tarea.partes_mantenimiento.filter(
            (p) =>
                p.equipo.nombre.toLowerCase().includes(filter) ||
                p.equipo.tipo_equipo.toLowerCase().includes(filter) ||
                (p.equipo.ubicacion && p.equipo.ubicacion.toLowerCase().includes(filter)),
        );
    }, [tarea.partes_mantenimiento, filter]);

    return (
        <>
            <GlobalFilter
                setGlobalFilter={setFilter}
                globalFilter={filter}
                searchPlaceholder='Nombre, ubicacion, tipo'
                fieldStyle={{
                    // marginTop: -8,
                    marginBottom: 0,
                    borderBottom: '1px solid #D8DAE1',
                }}
                backgroundColor='transparent'
            />
            <div className={classes.partesList}>
                {partesMantenimiento.map((parteMantenimiento, index) => {
                    const terminado = parteMantenimiento.terminado;
                    const apto = parteMantenimiento.apto;
                    const empezado = parteMantenimiento.respuestas.length > 0;

                    let estado = PENDIENTE;
                    if (terminado) estado = apto ? APTO : NO_APTO;
                    else if (empezado) estado = EN_PROGRESO;

                    const equipo = parteMantenimiento.equipo;
                    let subtitle = equipo.tipo_equipo;
                    if (equipo.ubicacion && equipo.ubicacion !== '') subtitle = `${subtitle} | ${equipo.ubicacion}`;

                    return (
                        <div
                            key={index}
                            className={classes.item}
                            onClick={() => setView(PARTE_MANTENIMIENTO, { parteMantenimiento })}
                        >
                            <div className={classes.itemContent}>
                                <Typography className={classes.title}>{parteMantenimiento.equipo.nombre}</Typography>
                                <Typography className={classes.subtitle}>{subtitle}</Typography>
                            </div>
                            <div className={clsx(classes.chip, estado)}>{estadoLabels[estado]}</div>
                            <ChevronRightIcon />
                        </div>
                    );
                })}
            </div>
            <div className={classes.button}>
                {!allTerminado && (
                    <Typography className={classes.info}>
                        <InfoIcon /> Aún faltan revisiones por terminar
                    </Typography>
                )}
                <BigButton
                    style={{ margin: 0 }}
                    onClick={() => setView(PARTES_MANTENIMIENTO_RESUMEN)}
                    disabled={!allTerminado}
                >
                    RESÚMEN DEL MANTENIMIENTO
                </BigButton>
            </div>
        </>
    );
};
