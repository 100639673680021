import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: (props) => (props.noMargin ? 0 : 16),
        },
        label: {
            color: '#213061',
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
        },
        childContainer: (props) => ({
            borderRadius: props.variant === 'default' ? 8 : 100,
            backgroundColor: props.backgroundColor || 'white',
            fontSize: 14,
            color: '#818CAE',
            display: 'flex',
            height: 40,
        }),
    }),
    { name: 'Field' },
);

export const Field = ({
    label,
    children,
    backgroundColor,
    style,
    variant = 'default',
    noMargin = false,
    classes = {},
}) => {
    const styles = useStyles({ backgroundColor, variant, noMargin });

    return (
        <div className={clsx(styles.root, classes.root)} style={style}>
            {label && (
                <Typography variant='body1' className={clsx(styles.label, classes.label)}>
                    {label}
                </Typography>
            )}
            <div className={clsx(styles.childContainer, classes.container)}>{children}</div>
        </div>
    );
};

Field.propTypes = {
    backgroundColor: PropTypes.any,
    children: PropTypes.any,
    classes: PropTypes.object,
    label: PropTypes.any,
    style: PropTypes.any,
    variant: PropTypes.string,
    noMargin: PropTypes.bool,
};
