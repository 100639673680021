import { Typography } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { solicitudesProvider } from '../../api';
import useSplash from '../../contexts/SplashState';
import { formatDate } from '../../utils';
import Button from '../common/Button';
import { ButtonDialog } from '../common/ButtonDialog';
import { DateRangePicker } from '../common/DateRangePicker';
import { Input } from '../common/Input';
import ResumenItem from './ResumenItem';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            height: 30,
            margin: theme.spacing(0.5, 0),
        },
        label: {
            color: '#213061',
            fontSize: 16,
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(1.5),
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        addButton: {
            color: '#8F95AF',
            fontSize: 12,
            marginLeft: 'auto',
        },
        solicitarButton: {
            marginTop: 'auto',
            marginBottom: theme.spacing(2),
        },
        dateRange: {
            display: 'flex',
            // alignItems: 'center',
            marginBottom: theme.spacing(1),
            '& .MuiInput-root': {
                background: 'white',
                borderRadius: 4,
            },
            '& .MuiInput-input': {
                fontSize: 13,
                padding: 10,
            },
            '&&& .MuiInput-underline:after': {
                borderBottom: 'none',
            },
            '&&& .MuiInput-underline:before': {
                borderBottom: 'none',
            },
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        errors: {
            fontSize: '0.75rem',
            color: '#f44336',
            marginBottom: theme.spacing(2),
        },
        confirmContentItem: {
            fontSize: 14,
            color: '#8F95AF',
            display: 'flex',
            '& span': {
                flex: 1,
                whiteSpace: 'nowrap',
            },
            '& + &': {
                marginTop: theme.spacing(1),
            },
        },
    }),
    { name: 'NuevaSolicitudVacaciones' },
);

const SolicitudVacacionesSchema = Yup.object().shape({
    motivo: Yup.string().required('Requerido'),
    dias: Yup.array().of(
        Yup.object().shape({
            fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
            fecha_fin: Yup.date()
                .typeError('La fecha de fin debe ser una fecha')
                .required('Requerido')
                .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
        }),
    ),
});

export default function NuevaVacaciones({ solicitud }) {
    const classes = useStyles();
    const history = useHistory();
    const { showSplash } = useSplash();
    const [diasDisponibles, setDiasDisponibles] = useState(0);

    useEffect(() => {
        solicitudesProvider.getResumen().then((resumen) => setDiasDisponibles(resumen.vacaciones.dias.disponibles));
    }, []);
    const snackbar = useSnackbar();

    return (
        <>
            <ResumenItem label='Días disponibles de vacaciones' value={diasDisponibles} style={{ marginTop: 32 }} />
            <Formik
                initialValues={{
                    motivo: solicitud.motivo || '',
                    dias: [
                        {
                            fecha_inicio: solicitud.fecha_inicio || new Date(),
                            fecha_fin: solicitud.fecha_fin || new Date(),
                        },
                    ],
                }}
                enableReinitialize={true}
                validationSchema={SolicitudVacacionesSchema}
                onSubmit={({ dias, ...values }, actions) => {
                    // eslint-disable-next-line camelcase
                    const cleanDias = dias.map(({ fecha_inicio, fecha_fin }) => {
                        fecha_inicio.setHours(0, 0, 0, 0);
                        fecha_fin.setHours(23, 59, 59, 0);

                        return { fecha_inicio, fecha_fin };
                    });

                    solicitudesProvider
                        .solicitarVacaciones({ dias: cleanDias, ...values })
                        .then(() => {
                            actions.setSubmitting(false);

                            showSplash({
                                title: 'Vacaciones solicitadas',
                                description: 'Tus vacaciones serán revisadas por administración',
                                onClick: () => history.push('/solicitudes'),
                            });
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    actions.setFieldError(field, errors.join('\n'));
                                }
                            }
                            actions.setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                }}
            >
                {({ values, errors, setFieldValue, submitForm, isValid }) => {
                    return (
                        <Form className={classes.form}>
                            <Typography className={classes.label}>Motivo de las vacaciones</Typography>
                            <Input name='motivo' placeholder='Ej: Verano, Navidad, Personales, ...' disableUnderline />
                            <Typography className={classes.label}>Días solicitados</Typography>
                            <FieldArray
                                name='dias'
                                render={({ push, remove }) => (
                                    <>
                                        {values.dias.map((dia, i) => {
                                            const itemErrors = errors.dias && errors.dias[i];

                                            return (
                                                <React.Fragment key={i}>
                                                    <div className={classes.dateRange}>
                                                        <DateRangePicker
                                                            fecha={dia.fecha_inicio}
                                                            allDay={true}
                                                            setFieldValue={setFieldValue}
                                                            fechaInicioKey={`dias[${i}].fecha_inicio`}
                                                            fechaFinKey={`dias[${i}].fecha_fin`}
                                                            showError={false}
                                                        />
                                                        <ButtonDialog
                                                            title='¿Estás seguro que quieres borrar este periodo?'
                                                            okText='Borrar'
                                                            button={
                                                                <IconButton size='small'>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            }
                                                            onAccept={() => remove(i)}
                                                        />
                                                    </div>
                                                    {itemErrors && (
                                                        <div className={classes.errors}>
                                                            {Object.values(itemErrors).map((err, j) => (
                                                                <div key={j}>{err}</div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                        <MuiButton
                                            startIcon={<AddIcon />}
                                            className={classes.addButton}
                                            onClick={() => push({ fecha_inicio: new Date(), fecha_fin: new Date() })}
                                        >
                                            Añadir
                                        </MuiButton>
                                    </>
                                )}
                            />
                            <ButtonDialog
                                title='¿Estás seguro de solicitar estos días de vacaciones?'
                                content={values.dias.map((dia, i) => (
                                    <Typography className={classes.confirmContentItem} key={i}>
                                        <span>{formatDate(dia.fecha_inicio)}</span>
                                        <span>a</span>
                                        <span>{formatDate(dia.fecha_fin)}</span>
                                    </Typography>
                                ))}
                                button={
                                    <Button className={classes.solicitarButton} color='info'>
                                        Solicitar vacaciones
                                    </Button>
                                }
                                okText='Solicitar'
                                onAccept={submitForm}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

NuevaVacaciones.propTypes = {
    solicitud: PropTypes.any,
};
