import { Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import { operariosProvider, tareasProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import { isAllDay } from '../../../utils';
import LoadingScreen from '../../common/LoadingScreen';
import Calendario from '../common/Calendario';
import SelectClienteView from '../common/SelectClienteView';
import SelectDireccionView from '../common/SelectDireccionView';
import SelectProyectoView from '../common/SelectProyectoView';
import GeneralView, { createEmptyPlanificacion } from './GeneralView';
import SelectOperarioView from './SelectOperarioView';

export const GENERAL = 0;
export const CLIENTE = 1;
export const FECHA = 2;
export const OPERARIO = 3;
export const DIRECCION = 4;
export const PROYECTO = 5;

const viewComponents = {
    [GENERAL]: GeneralView,
    [FECHA]: Calendario,
    [CLIENTE]: SelectClienteView,
    [OPERARIO]: SelectOperarioView,
    [DIRECCION]: SelectDireccionView,
    [PROYECTO]: SelectProyectoView,
};

const TareaSchema = Yup.object().shape({
    descripcion: Yup.string().required('Requerido'),
    planificaciones: Yup.array().of(
        Yup.object().shape({
            fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
            fecha_fin: Yup.date()
                .typeError('La fecha de fin debe ser una fecha')
                .required('Requerido')
                .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
        }),
    ),
});

export default function EditarTarea({ tareaId, onClose, onSave, onDelete }) {
    const [tarea, setTarea] = useState(null);
    const [currentFieldComponent, setCurrentFieldComponent] = useState({
        id: 0,
        props: {},
    });
    const { isCompany } = useAuthState();
    const { state: { fecha: selectedDate = new Date(), operarios: selectedOperarios = [] } = {} } = useLocation();
    const snackbar = useSnackbar();
    const [selfOperario, setSelfOperario] = useState(null);

    useEffect(() => {
        if (isCompany) return;

        operariosProvider.getAll('as_options').then((operarios) => {
            const self = operarios.find((op) => op.is_self);
            setSelfOperario(self);
        });
    }, [isCompany]);

    useEffect(() => {
        if (tareaId === null) {
            setTarea({
                id: null,
                descripcion: '',
                cliente: null,
                direccion: null,
                proyecto: null,
                planificaciones: [createEmptyPlanificacion(selectedDate, selectedOperarios)],
            });
            return;
        }

        setTarea(null);

        tareasProvider.getById(tareaId).then((tarea) => {
            setTarea({
                ...tarea,
                planificaciones: tarea.planificaciones.map((planificacion) => ({
                    ...planificacion,
                    allDay: isAllDay(planificacion.fecha_inicio, planificacion.fecha_fin),
                    fecha_inicio: new Date(planificacion.fecha_inicio),
                    fecha_fin: new Date(planificacion.fecha_fin),
                })),
            });
        });
    }, [tareaId]);

    if (!tarea) return <LoadingScreen />;

    const setFieldValue = (field) => (ev) => {
        const value = ev.target ? ev.target.value : ev;

        setTarea((tarea) => ({
            ...tarea,
            [field]: value,
        }));
    };

    const Component = viewComponents[currentFieldComponent.id];

    return (
        <Formik
            initialValues={{
                id: tareaId,
                cliente: tarea.cliente_id ? { id: tarea.cliente_id, nombre: tarea.cliente } : null,
                direccion: tarea.direccion,
                proyecto: tarea.proyecto,
                descripcion: tarea.descripcion,
                planificaciones: tarea.planificaciones,
                selfOperario,
            }}
            validationSchema={TareaSchema}
            enableReinitialize
            onSubmit={({ planificaciones, descripcion, ...values }, actions) => {
                const payload = {
                    descripcion,
                    // eslint-disable-next-line camelcase
                    planificaciones: planificaciones.map(({ fecha_inicio, fecha_fin, allDay, ...rest }) => {
                        if (allDay) {
                            fecha_inicio.setHours(0, 0, 0, 0);
                            fecha_fin.setHours(23, 59, 59, 0);
                        }
                        return { ...rest, fecha_inicio, fecha_fin };
                    }),
                };

                if (tareaId) {
                    payload.cliente_id = tarea.cliente_id;
                    payload.direccion_id = tarea.direccion.id;
                    payload.proyecto_id = tarea.proyecto ? tarea.proyecto.id : null;
                } else {
                    payload.cliente_id = values.cliente.id;
                    payload.direccion_id = values.direccion.id;
                    payload.proyecto_id = values.proyecto ? values.proyecto.id : null;
                    payload.cliente = { nombre: values.cliente.nombre };
                    payload.direccion = { nombre: values.direccion.nombre };
                    payload.proyecto = values.proyecto ? { nombre: values.proyecto.nombre } : null;
                }

                tareasProvider
                    .save(payload, tareaId)
                    .then(onSave)
                    .catch((err) => {
                        actions.setSubmitting(false);
                        console.error(err);
                        snackbar.showMessage('Ha ocurrido un error al guardar');
                    });
            }}
        >
            <Component
                onClose={onClose}
                onSave={onSave}
                onDelete={onDelete}
                tarea={tarea}
                tareaId={tareaId}
                setFieldValue={setFieldValue}
                setCurrentFieldComponent={(componentId, componentProps) =>
                    setCurrentFieldComponent({ id: componentId, props: componentProps || {} })
                }
                {...currentFieldComponent.props}
            />
        </Formik>
    );
}

EditarTarea.propTypes = {
    onClose: PropTypes.any,
    onDelete: PropTypes.any,
    onSave: PropTypes.any,
    tareaId: PropTypes.any,
};
