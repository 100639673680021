import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

export const BigButton = ({ style, children, ...props }) => (
    <Button
        style={{
            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
            color: 'white',
            textTransform: 'none',
            fontSize: 15,
            padding: 16,
            borderRadius: 8,
            marginTop: 12,
            marginBottom: 24,
            ...style,
            opacity: props.disabled ? 0.4 : 1.0,
        }}
        {...props}
    >
        {children}
    </Button>
);

BigButton.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.any,
    style: PropTypes.any,
};
