import { BaseTareaItemList } from '../../tareas/TareaItemList';

export default function TareaItemList(props) {
    function getTareaItemProps(tarea) {
        let estado = tarea.estado;
        if (tarea.sin_resolver) estado = 'HAS_INCIDENCIA';

        if (tarea.urgente) estado = 'URGENTE';

        return {
            cliente: tarea.cliente,
            descripcion: tarea.descripcion,
            estado,
        };
    }

    return <BaseTareaItemList {...props} getTareaItemProps={getTareaItemProps} />;
}
