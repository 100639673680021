import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(
    (theme) => ({
        squareButton: {
            height: '100%',
            minWidth: 44,
            minHeight: 40,
            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
            '&.disabled': {
                opacity: 0.4,
            },
        },
    }),
    { name: 'DeleteDialog' },
);

export function DeleteDialog({ onDelete, disabled }) {
    const styles = useStyles();

    return (
        <ButtonDialog
            onConfirm={onDelete}
            disabled={disabled}
            text='¿Estás seguro que quieres eliminar este marcaje?'
            icon={<DeleteIcon style={{ fontSize: 60, color: '#e2e2e2', marginBottom: 8 }} />}
            okText='Eliminar'
            button={
                <Button disabled={disabled} className={clsx(styles.squareButton, disabled ? 'disabled' : null)}>
                    <DeleteIcon style={{ color: 'white', fontSize: 20 }} />
                </Button>
            }
        />
    );
}

export function ButtonDialog({ onConfirm, icon, text, okText, disabled, button = null }) {
    const [open, setOpen] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);

    useEffect(() => {
        if (!open) return;

        setIsConfirming(false);
    }, [open]);

    const clonedButton = React.cloneElement(button, { onClick: () => setOpen(true), disabled });
    return (
        <>
            {clonedButton}
            <Dialog open={open}>
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    {icon}
                    {text}
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'space-between', padding: 20 }}>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            setIsConfirming(true);
                            onConfirm();
                            setOpen(false);
                        }}
                        variant='contained'
                        color='primary'
                        autoFocus
                        disabled={isConfirming}
                    >
                        {okText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ButtonDialog.propTypes = {
    button: PropTypes.any,
    disabled: PropTypes.any,
    icon: PropTypes.any,
    okText: PropTypes.any,
    onConfirm: PropTypes.any,
    text: PropTypes.any,
};

DeleteDialog.propTypes = {
    disabled: PropTypes.any,
    onDelete: PropTypes.any,
};
