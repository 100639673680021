import { IconButton } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PreviousIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthState from '../../contexts/AuthState';
import DrawerMenu from '../common/DrawerMenu';
import Navigation from '../common/Navigation';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import CalendarioIcon from '../icons/Calendario';
import Resumen from './Resumen';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: 18,
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            color: 'white',
        },
    },
    addButton: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
    },
    fecha: {
        color: 'inherit',
    },
}));

export const ResumenView = () => {
    const classes = useStyles();
    const history = useHistory();
    const [year, setYear] = useState(new Date().getFullYear());

    const {
        userInfo: {
            preferencias: { usar_nueva_ui_operarios: usarNuevaUI },
        },
    } = useAuthState();

    const goPreviousYear = () => setYear((year) => year - 1);
    const goNextYear = () => setYear((year) => year + 1);

    return (
        <React.Fragment>
            <PageHeader
                title='Vacaciones y Ausencias'
                endButton={
                    <>
                        <IconButton
                            onClick={() => {
                                history.push('/solicitudes/calendario');
                            }}
                        >
                            <CalendarioIcon
                                style={{ color: 'white', fontSize: 22, marginRight: usarNuevaUI ? -8 : 8 }}
                            />
                        </IconButton>
                        {usarNuevaUI && <DrawerMenu />}
                    </>
                }
            >
                <div className={classes.subtitle}>
                    <IconButton onClick={goPreviousYear}>
                        <PreviousIcon />
                    </IconButton>
                    <Typography className={classes.fecha}>{year}</Typography>
                    <IconButton onClick={goNextYear}>
                        <NextIcon />
                    </IconButton>
                </div>
            </PageHeader>
            <PageBody style={{ position: 'relative' }}>
                <Resumen year={year} />
                <Fab
                    color='primary'
                    size='medium'
                    aria-label='add'
                    className={classes.addButton}
                    onClick={() => history.push('/solicitudes/nueva')}
                >
                    <AddIcon />
                </Fab>
            </PageBody>
            {!usarNuevaUI && <Navigation />}
        </React.Fragment>
    );
};
