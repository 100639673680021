import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import { GastosJornada } from './GastosJornada';

const GastoSchema = Yup.object().shape({
    jornadas: Yup.array().of(
        Yup.object().shape({
            gastos: Yup.object().shape({
                desplazamiento: Yup.number().typeError('Escribe un número válido'),
                parking: Yup.number().typeError('Escribe un número válido'),
                dietas: Yup.number().typeError('Escribe un número válido'),
            }),
        }),
    ),
});

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}));

export const Gastos = ({ jornadas, setJornadas, editable }) => {
    const styles = useStyles();
    const todayStr = formatISODate(new Date());
    const hasToday = jornadas.findIndex((jornada) => jornada.propia && jornada.fecha === todayStr) >= 0;

    return (
        <Formik
            initialValues={{
                jornadas,
            }}
            enableReinitialize={true}
            validationSchema={GastoSchema}
        >
            {({ values }) => (
                <>
                    {!hasToday && (
                        <PageBodySection className={styles.root}>
                            <Typography className={styles.title}>Hoy</Typography>
                            <Typography className={styles.info}>
                                <InfoIcon /> Registra el tiempo para poder añadir el gasto de hoy.
                            </Typography>
                        </PageBodySection>
                    )}
                    {values.jornadas.map((jornada, i) => (
                        <GastosJornada
                            key={i}
                            index={i}
                            editable={editable}
                            jornada={jornada}
                            onSaveGasto={(idx, gastos) =>
                                setJornadas((jornadas) => {
                                    return [
                                        ...jornadas.slice(0, i),
                                        {
                                            ...jornadas[i],
                                            gastos,
                                        },
                                        ...jornadas.slice(i + 1),
                                    ];
                                })
                            }
                        />
                    ))}
                </>
            )}
        </Formik>
    );
};

Gastos.propTypes = {
    jornadas: PropTypes.any,
    setJornadas: PropTypes.any,
    editable: PropTypes.bool,
};
