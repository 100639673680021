import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import { formatDate, formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import LabelChip from '../notas/LabelChip';
import MaterialesForm from './MaterialesForm';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(2),
            '&:last-of-type': {
                marginBottom: theme.spacing(2),
            },
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
            fontSize: 13,
            fontWeight: 'bold',
            color: '#818CAE',
            '& span': {
                fontWeight: 'normal',
            },
        },
    }),
    { name: 'MaterialesJornada' },
);

export function MaterialesJornada({ index, jornada, onSaveMateriales, onChange }) {
    const styles = useStyles();

    const todayStr = formatISODate(new Date());
    const isToday = jornada.fecha === todayStr;

    const collectionName = 'materiales';
    const materialesElementId = `materiales${index}`;

    const bloqueada = jornada.facturada || jornada.revisada;

    return (
        <PageBodySection className={styles.root}>
            <Typography className={styles.title}>
                {isToday ? 'Hoy' : formatDate(new Date(jornada.fecha))}{' '}
                {jornada.operario && <span>({jornada.operario})</span>}
                {bloqueada && <LabelChip label={jornada.facturada ? 'Facturado' : 'Revisado'} icon={<LockIcon />} />}
            </Typography>
            <MaterialesForm
                elementId={materialesElementId}
                collectionName={collectionName}
                materiales={jornada.materiales}
                canEdit={!bloqueada && jornada.propia}
                onChange={(changed) => onChange(index, changed)}
                onSave={(materiales) =>
                    tareasProvider
                        .getAll(`jornadas/${jornada.id}/materiales/all`, {
                            method: 'post',
                            body: JSON.stringify({
                                materiales,
                            }),
                        })
                        .then(onSaveMateriales)
                }
            />
        </PageBodySection>
    );
}

MaterialesJornada.propTypes = {
    index: PropTypes.any,
    jornada: PropTypes.any,
    onSaveMateriales: PropTypes.any,
    onChange: PropTypes.any,
};
