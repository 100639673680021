import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AlarmIcon from '@material-ui/icons/Alarm';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { endOfDay, min, startOfDay } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { notasDiaProvider, tareasProvider } from '../../api';
import { processTareaForCalendar } from '../../api/tareas';
import { createTiempo } from '../../api/tareas-functions';
import useAuthState from '../../contexts/AuthState';
import { formatFullDate, formatISODate, formatTime, isAllDay } from '../../utils';
import DrawerMenu from '../common/DrawerMenu';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import CalendarioIcon from '../icons/Calendario';
import OperariosIcon from '../icons/Operarios';
import MonthDaySelectorDialog from '../tareas/MonthDaySelectorDialog';
import ResumenSliderDialog from '../tareas/ResumenSliderDialog';
import TareaItemList from '../tareas/TareaItemList';
import ActividadTab from './ActividadTab';
import NotaDia from './NotaDia';
import ProyectosTab from './ProyectosTab';
import { Tab, Tabs } from './Tabs';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            paddingBottom: 0,
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        body: {
            padding: 16,
        },
        navigation: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
        },
        navigationButton: {
            color: 'white',
        },
        vehiculos: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            marginBottom: theme.spacing(1),
        },
        vehiculo: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            color: 'white',
            backgroundColor: '#23395F',
            borderRadius: 8,
            height: 36,
            padding: theme.spacing(0, 1),
            '& .MuiTypography-root': {
                fontWeight: 400,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
        dontShowOperarios: {
            opacity: 0.5,
        },
    }),
    { name: 'ControlJornada' },
);

const PLANIFICACION = 'planificacion';
const PROYECTOS = 'proyectos';
const ACTIVIDAD = 'actividad';

export function ControlJornada({
    title,
    fecha: selectedDate,
    fetchUrl,
    onDateChange,
    renderFilterComponent,
    aggregateTodayOnly = false,
}) {
    const [dateSelectorOpen, setDateSelectorOpen] = useState(false);
    const [resumen, setResumen] = useState([]);
    const [solicitudes, setSolicitudes] = useState([]);
    const [tareas, setTareas] = useState([]);
    const [view, setView] = useState(PLANIFICACION);
    const [showOperarios, setShowOperarios] = useState(false);
    const [notasDia, setNotasDia] = useState([]);

    const classes = useStyles();

    const {
        userInfo: {
            preferencias: { usar_agrupar_tareas_proyectos_app: usarAgruparTareasProyectosApp },
        },
    } = useAuthState();

    useEffect(() => {
        tareasProvider.getAll(fetchUrl).then((resultado) => {
            const tareaList = resultado.tareas.map(processTareaForCalendar);
            tareaList.sort((a, b) => (isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1));

            setTareas(tareaList);
            setResumen(resultado.resumen);
            setSolicitudes(resultado.solicitudes);
        });
    }, [fetchUrl]);

    useEffect(() => {
        if (selectedDate) {
            notasDiaProvider.getAll(formatISODate(selectedDate)).then(setNotasDia);
        }
    }, [selectedDate]);

    const horaEntradaHoy = useMemo(() => {
        const resumenHoy = resumen.find((r) => r.fecha === selectedDate);
        return resumenHoy && resumenHoy.hora_entrada ? new Date(resumenHoy.hora_entrada) : null;
    }, [resumen, selectedDate]);

    const { servicios, solicitudesValidas, tareasValidas, proyectos, vehiculosHoy, earliestInicio, notasTareas } =
        useMemo(() => {
            const tareasValidas = tareas
                .filter((tarea) => tarea.fechas && tarea.fechas[selectedDate] !== undefined)
                .map((tarea) => {
                    const tareaFecha = tarea.fechas[selectedDate];

                    return {
                        ...tarea,
                        vehiculo: tareaFecha.vehiculo,
                        marcajes: tareaFecha.marcajes,
                        operarios: tareaFecha.operarios,
                        mensaje: tareaFecha.mensaje,
                        fechaInicio: tareaFecha.fecha_inicio ? new Date(tareaFecha.fecha_inicio) : null,
                        fechaFin: tareaFecha.fecha_fin ? new Date(tareaFecha.fecha_fin) : null,
                        fechaVisita: tareaFecha.fecha_visita ? new Date(tareaFecha.fecha_visita) : null,
                        fechaInicioJornada: tareaFecha.fecha_inicio_jornada
                            ? new Date(tareaFecha.fecha_inicio_jornada)
                            : null,
                        confirmada: tareaFecha.confirmada,
                        salido: tareaFecha.salido,
                        tiempoHoy: tareaFecha.minutos !== null ? createTiempo(tareaFecha.minutos) : null,
                    };
                });
            tareasValidas.sort((a, b) => {
                const isAllDayA = isAllDay(a.fechaInicio, a.fechaFin);
                const isAllDayB = isAllDay(b.fechaInicio, b.fechaFin);

                if (isAllDayA && !isAllDayB) {
                    return 1;
                }
                if (!isAllDayA && isAllDayB) {
                    return -1;
                }

                return isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1;
            });

            const tareasPorProyecto = tareasValidas
                .filter((t) => t.proyecto_id)
                .reduce((acc, tarea) => {
                    const proyectoId = tarea.proyecto_id;
                    if (!acc[proyectoId]) {
                        acc[proyectoId] = [];
                    }
                    acc[proyectoId].push(tarea);
                    return acc;
                }, {});

            const proyectos = Object.entries(tareasPorProyecto).map(([proyectoId, tareas]) => {
                return {
                    id: proyectoId,
                    nombre: tareas[0].proyecto,
                    cliente: tareas[0].cliente,
                    tareas,
                };
            });

            const fecha = new Date(selectedDate);
            const solicitudesValidas = solicitudes.filter((solicitud) => {
                const inicio = startOfDay(new Date(solicitud.fecha_inicio));
                const fin = endOfDay(new Date(solicitud.fecha_fin));

                return !isBefore(fecha, inicio) && !isAfter(fecha, fin);
            });

            const vehiculos = tareasValidas.map((t) => t.vehiculo).filter(Boolean);
            const uniqueIds = new Set(vehiculos.map((obj) => obj.id));
            const vehiculosHoy = Array.from(uniqueIds, (id) => vehiculos.find((obj) => obj.id === id));

            const fechasInicio = tareasValidas
                .filter((t) => !isAllDay(t.fechaInicio, t.fechaFin) || t.fechaInicioJornada !== null)
                .map((t) => {
                    if (isAllDay(t.fechaInicio, t.fechaFin)) return t.fechaInicioJornada;

                    return t.fechaInicioJornada ? min([t.fechaInicio, t.fechaInicioJornada]) : t.fechaInicio;
                })
                .sort((a, b) => (isBefore(a, b) ? -1 : 1));

            return {
                tareasValidas,
                servicios: usarAgruparTareasProyectosApp ? tareasValidas.filter((t) => !t.proyecto_id) : tareasValidas,
                proyectos,
                solicitudesValidas,
                vehiculosHoy,
                earliestInicio: fechasInicio.length > 0 ? fechasInicio[0] : null,
                notasTareas: tareasValidas
                    .filter((t) => t.mensaje)
                    .map((t) => ({ titulo: t.descripcion, mensaje: t.mensaje })),
            };
        }, [selectedDate, tareas, solicitudes, usarAgruparTareasProyectosApp]);

    const filters = useMemo(() => renderFilterComponent(resumen), [renderFilterComponent, resumen]);

    const resumenParaAgregar = aggregateTodayOnly ? resumen.filter((r) => r.fecha === selectedDate) : resumen;

    let horaInicioJornada = null;
    if (earliestInicio === null) {
        horaInicioJornada = horaEntradaHoy;
    } else if (horaEntradaHoy === null) {
        horaInicioJornada = earliestInicio;
    } else {
        horaInicioJornada = isBefore(horaEntradaHoy, earliestInicio) ? horaEntradaHoy : earliestInicio;
    }

    function toggleShowOperarios() {
        setShowOperarios((show) => !show);
    }

    return (
        <React.Fragment>
            <PageHeader
                startButton={
                    <IconButton onClick={() => setDateSelectorOpen(true)}>
                        <CalendarioIcon />
                    </IconButton>
                }
                title={title}
                onTitleClick={() => setDateSelectorOpen(true)}
                endButton={
                    <>
                        <IconButton onClick={toggleShowOperarios}>
                            <OperariosIcon
                                className={showOperarios ? classes.showOperarios : classes.dontShowOperarios}
                            />
                        </IconButton>
                        <ResumenSliderDialog
                            title={aggregateTodayOnly ? formatFullDate(selectedDate) : title}
                            resumen={resumenParaAgregar}
                        />
                        <DrawerMenu />
                    </>
                }
                className={classes.header}
            >
                <div className={classes.headerContent}>{filters}</div>
                <Tabs value={view} onChange={(ev, newValue) => setView(newValue)}>
                    <Tab label={usarAgruparTareasProyectosApp ? 'Servicios' : 'Planificación'} value={PLANIFICACION} />
                    {usarAgruparTareasProyectosApp && <Tab label='Proyectos' value={PROYECTOS} />}
                    <Tab label='Actividad' value={ACTIVIDAD} />
                </Tabs>
            </PageHeader>
            <PageBody className={classes.body} bodyGap={8} color='dark' paddingTop={0}>
                {view === PLANIFICACION && (
                    <>
                        {(((vehiculosHoy.length > 0 || horaInicioJornada) && tareasValidas.length > 0) ||
                            notasDia.length > 0 ||
                            notasTareas.length > 0) && (
                            <div className={classes.vehiculos}>
                                {horaInicioJornada && (
                                    <div className={classes.vehiculo}>
                                        <AlarmIcon />{' '}
                                        <Typography variant='subtitle2'>
                                            Tu jornada empieza a las {formatTime(horaInicioJornada)}
                                        </Typography>
                                    </div>
                                )}
                                {vehiculosHoy.map((vehiculo, i) => (
                                    <div key={i} className={classes.vehiculo}>
                                        <DriveEtaIcon />{' '}
                                        <Typography variant='subtitle2'>
                                            {vehiculo.codigo} | {vehiculo.nombre}
                                        </Typography>
                                    </div>
                                ))}
                                {notasDia.map((nota, i) => (
                                    <NotaDia key={i} nota={nota} />
                                ))}
                                {notasTareas.map((nota, i) => (
                                    <NotaDia key={i} nota={nota} />
                                ))}
                            </div>
                        )}
                        <TareaItemList
                            tareas={servicios}
                            solicitudes={solicitudesValidas}
                            showOperarios={showOperarios}
                        />
                    </>
                )}
                {view === PROYECTOS && <ProyectosTab proyectos={proyectos} />}
                {view === ACTIVIDAD && <ActividadTab tareas={tareasValidas} />}
            </PageBody>
            <MonthDaySelectorDialog
                fecha={selectedDate}
                onClose={() => setDateSelectorOpen(false)}
                open={dateSelectorOpen}
                onDateChange={onDateChange}
            />
        </React.Fragment>
    );
}

ControlJornada.propTypes = {
    aggregateTodayOnly: PropTypes.bool,
    fecha: PropTypes.any,
    fetchUrl: PropTypes.any,
    onDateChange: PropTypes.any,
    renderFilterComponent: PropTypes.any,
    title: PropTypes.any,
};
