import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getMinutos, getTiempoTotal } from '../../api/tareas-functions';
import useAuthState, { usePermisos } from '../../contexts/AuthState';
import useSplash from '../../contexts/SplashState';
import { formatISODate, formatTiempo } from '../../utils';
import { BigButton } from '../common/BigButton';
import { PageBodySection } from '../common/PageBodySection';
import ConfigurarServicioLibre from './ConfigurarServicioLibre';
import MarcajesList from './marcajes/MarcajesList';
import NewOpcionesContinuarView, {
    CERRAR_SUBVIEW,
    DEFAULT_SUBVIEW,
} from './opciones_continuar/NewOpcionesContinuarView';
import OpcionesContinuarView from './opciones_continuar/OpcionesContinuarView';
import { ALBARANES, EXPLICACION, GASTOS, MATERIAL_UTILIZADO, NOTAS, PARTES_MANTENIMIENTO_LIST } from './TareaView';
import { TimeSlotItem } from './TimeSlotItem';

const useStyles = makeStyles(() => ({
    hoyLabel: {
        background: 'linear-gradient(180deg, #4D62FF 0%, #4D96FF 100%), #EDF8F5',
        borderRadius: 20,
        color: 'white',
        width: 40,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 11,
        marginTop: -2,
        marginBottom: -2,
    },
    timeButton: {
        marginLeft: 8,
        height: '100%',
        minWidth: 44,
        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
    },
    hoyNoTime: {
        borderRadius: 4,
        border: '1px dashed #BABABA',
        minHeight: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 13,
        color: '#818CAE',
        flex: 1,
    },
    hoyAddButton: {
        marginLeft: 8,
        height: 40,
        minWidth: 44,
        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
    },
    scrollBox: {
        borderRadius: 8,
        padding: '8px 16px',
        position: 'relative',
        minHeight: 100,
        marginBottom: 24,
        color: '#818CAE',
        fontSize: 13,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        background: `linear-gradient(white 30%, rgba(255,255,255, 0)),
                linear-gradient(rgba(255,255,255, 0), white 70%) 0 100%,
                radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.2), rgba(0,0,0,0)),
                radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.2), rgba(0,0,0,0)) 0 100%`,
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'local, local, scroll, scroll',
        backgroundSize: '100% 24px, 100% 24px, 100% 8px, 100% 8px',
        '& .MuiIconButton-root': {
            position: 'absolute',
            bottom: 'calc(50% - 12px)',
            right: 8,
        },
        '&.empty': {
            minHeight: 24,
            justifyContent: 'center',
        },
        '&.empty .MuiIconButton-root': {
            bottom: 'calc(50% - 15px)',
        },
    },
}));

export const VistaGeneral = ({ id, tarea, setView, onSave, editable }) => {
    const classes = useStyles();
    const history = useHistory();
    const { showCustomComponent } = useSplash();
    const {
        userInfo: {
            preferencias: { usar_nueva_ui_operarios: usarNuevaUI, add_tiempo_operarios: addTiempoOperarios },
        },
    } = useAuthState();

    const [cerrarServicios, generarAlbaranes] = usePermisos('cerrar_servicios', 'generar_albaranes');

    const listaMateriales = tarea.jornadaHoy ? tarea.jornadaHoy.materiales : [];
    const emptyMateriales = listaMateriales.length === 0;
    const materiales = emptyMateriales ? (
        <span style={{ fontStyle: 'italic' }}>Añadir material utilizado</span>
    ) : (
        listaMateriales.map((m, i) => (
            <span style={{ marginBottom: 4 }} key={i}>
                {m.unidades} {m.descripcion}
            </span>
        ))
    );

    const hasExplicacion = tarea.jornadaHoy && tarea.jornadaHoy.explicacion;
    const explicacion = hasExplicacion ? (
        <span style={{ marginBottom: 4, whiteSpace: 'pre-line' }}>{tarea.jornadaHoy.explicacion}</span>
    ) : (
        <span style={{ fontStyle: 'italic' }}>Explicar el trabajo realizado</span>
    );

    const notasPropias = tarea.jornadaHoy && tarea.jornadaHoy.notas.length > 0 ? tarea.jornadaHoy.notas[0] : null;
    const notas =
        notasPropias !== null ? (
            <span style={{ marginBottom: 4, whiteSpace: 'pre-line' }}>{notasPropias.texto}</span>
        ) : (
            <span style={{ fontStyle: 'italic' }}>Ver, editar o añadir observaciones</span>
        );

    const numAlbaranes = tarea.albaranes.length;
    const numAlbaranesPendientes = tarea.albaranes.filter((a) => a.firma === null || a.firma.image_url === null).length;
    const albaranes = (
        <span style={{ fontStyle: 'italic' }}>
            {numAlbaranesPendientes > 0
                ? `Hay ${numAlbaranesPendientes} albaranes pendientes de firmar`
                : numAlbaranes
                ? `Hay ${numAlbaranes} ${numAlbaranes > 1 ? 'albaranes' : 'albarán'} de servicio`
                : 'Generar albaranes para el cliente'}
        </span>
    );

    const numPartesMantenimiento = tarea.partes_mantenimiento.length;
    const numPartesMantenimientoCompletados = tarea.partes_mantenimiento.filter((p) => p.terminado).length;
    const partesMantenimiento = (
        <span style={{ fontStyle: 'italic' }}>
            {numPartesMantenimientoCompletados} de {numPartesMantenimiento} terminados
        </span>
    );

    const usarMarcajes = true;

    function openJornadasList() {
        history.push(`/tarea/${id}/jornadas/${formatISODate(new Date())}`, { editing: true });
    }

    const calendarioButton = (
        <Button onClick={() => openJornadasList()} className={classes.timeButton}>
            <EventIcon style={{ color: 'white', fontSize: 20 }} />
        </Button>
    );

    const tiempoAcumulado = useMemo(() => getTiempoTotal(tarea), [tarea]);

    if (tarea.libre) return <ConfigurarServicioLibre />;

    let manoObraTexto = ['No hay mano de obra registrada'];

    if (getMinutos(tarea.tiempoAcumuladoHoy) > 0) {
        manoObraTexto = [
            `Tu mano de obra de hoy: ${formatTiempo(tarea.tiempoHoy)}`,
            `En total hoy hay: ${formatTiempo(tarea.tiempoAcumuladoHoy)}`,
        ];
    }
    return (
        <>
            <PageBodySection>
                <Typography variant='h4'>Mano de obra</Typography>

                {addTiempoOperarios && (
                    <div className={clsx(classes.scrollBox, 'empty')} onClick={() => editable && openJornadasList()}>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{manoObraTexto.join('\n')}</span>
                        {editable && (
                            <IconButton size='small' onClick={() => openJornadasList()}>
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                    </div>
                )}
                {!addTiempoOperarios && usarMarcajes && (
                    <MarcajesList
                        selectedJornadaDate={formatISODate(new Date())}
                        calendarioButton={calendarioButton}
                        tiempoAcumulado={tiempoAcumulado}
                    />
                )}
                {!addTiempoOperarios && !usarMarcajes && (
                    <>
                        {tarea.tiempoHoy ? (
                            <TimeSlotItem
                                label={<div className={classes.hoyLabel}>HOY</div>}
                                tiempo={tarea.tiempoHoy}
                                button={
                                    editable && (
                                        <Button
                                            disabled={tarea.jornadas[0].facturada}
                                            onClick={() =>
                                                history.push(`/tarea/${id}/tiempo/${formatISODate(new Date())}`, {
                                                    editing: true,
                                                })
                                            }
                                            className={classes.timeButton}
                                            style={{
                                                opacity: tarea.jornadas[0].facturada ? 0.4 : 1,
                                            }}
                                        >
                                            <EditIcon style={{ color: 'white', fontSize: 20 }} />
                                        </Button>
                                    )
                                }
                            />
                        ) : (
                            <div style={{ display: 'flex' }}>
                                <div className={classes.hoyNoTime}>Aún no has registrado tiempo hoy</div>
                                {editable && (
                                    <Button
                                        onClick={() => history.push(`/tarea/${id}/tiempo/${formatISODate(new Date())}`)}
                                        className={classes.hoyAddButton}
                                    >
                                        <AddIcon style={{ color: 'white', fontSize: 20 }} />
                                    </Button>
                                )}
                            </div>
                        )}
                        <TimeSlotItem
                            label='Total'
                            tiempo={tiempoAcumulado}
                            button={calendarioButton}
                            style={{
                                marginTop: 8,
                                marginBottom: 24,
                            }}
                        />
                    </>
                )}
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Material utilizado</Typography>
                <div
                    className={clsx(classes.scrollBox, { empty: true })}
                    onClick={() => editable && setView(MATERIAL_UTILIZADO)}
                >
                    {materiales}
                    {editable && (
                        <IconButton size='small' onClick={() => setView(MATERIAL_UTILIZADO)}>
                            <ChevronRightIcon />
                        </IconButton>
                    )}
                </div>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Descripción del servicio</Typography>
                <div
                    className={clsx(classes.scrollBox, { empty: true })}
                    onClick={() => editable && setView(EXPLICACION)}
                >
                    {explicacion}
                    {editable && (
                        <IconButton size='small' onClick={() => setView(EXPLICACION)}>
                            <ChevronRightIcon />
                        </IconButton>
                    )}
                </div>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Observaciones y fotos</Typography>
                <div className={clsx(classes.scrollBox, { empty: true })} onClick={() => editable && setView(NOTAS)}>
                    {notas}
                    {/* <span style={{fontStyle: 'italic'}}>Ver, editar o añadir observaciones</span> */}
                    {editable && (
                        <IconButton size='small' onClick={() => setView(NOTAS)}>
                            <ChevronRightIcon />
                        </IconButton>
                    )}
                </div>
            </PageBodySection>
            <PageBodySection>
                <Typography variant='h4'>Otros gastos</Typography>
                <div className={clsx(classes.scrollBox, 'empty')} onClick={() => editable && setView(GASTOS)}>
                    <span style={{ fontStyle: 'italic' }}>Dietas, desplazamientos, parking, ...</span>
                    {editable && (
                        <IconButton size='small' onClick={() => setView(GASTOS)}>
                            <ChevronRightIcon />
                        </IconButton>
                    )}
                </div>
            </PageBodySection>
            {generarAlbaranes && (
                <PageBodySection>
                    <Typography variant='h4'>Albaranes de servicio</Typography>
                    <div className={clsx(classes.scrollBox, 'empty')} onClick={() => editable && setView(ALBARANES)}>
                        <span style={{ fontStyle: 'italic' }}>{albaranes}</span>
                        {editable && (
                            <IconButton size='small' onClick={() => setView(ALBARANES)}>
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                    </div>
                </PageBodySection>
            )}
            {numPartesMantenimiento > 0 && (
                <PageBodySection>
                    <Typography variant='h4'>Revisión de equipos</Typography>
                    <div
                        className={clsx(classes.scrollBox, 'empty')}
                        onClick={() => editable && setView(PARTES_MANTENIMIENTO_LIST)}
                    >
                        <span style={{ fontStyle: 'italic' }}>{partesMantenimiento}</span>
                        {editable && (
                            <IconButton size='small' onClick={() => setView(ALBARANES)}>
                                <ChevronRightIcon />
                            </IconButton>
                        )}
                    </div>
                </PageBodySection>
            )}

            {editable && cerrarServicios && (
                <PageBodySection
                    style={{
                        marginTop: 'auto',
                        marginBottom: 24,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingTop: 16,
                    }}
                >
                    <BigButton
                        onClick={() => {
                            const OpcionesContinuarViewComponent = usarNuevaUI
                                ? NewOpcionesContinuarView
                                : OpcionesContinuarView;
                            showCustomComponent(({ closeSplash }) => (
                                <OpcionesContinuarViewComponent
                                    onClose={closeSplash}
                                    initialSubView={tarea.isUltimoOperario ? CERRAR_SUBVIEW : DEFAULT_SUBVIEW}
                                />
                            ));
                        }}
                        style={{ width: '100%', marginBottom: 0 }}
                    >
                        {tarea.isUltimoOperario ? 'CERRAR SERVICIO' : 'SALIR O CERRAR SERVICIO'}
                    </BigButton>
                </PageBodySection>
            )}
        </>
    );
};

VistaGeneral.propTypes = {
    editable: PropTypes.any,
    id: PropTypes.any,
    onSave: PropTypes.any,
    setView: PropTypes.any,
    tarea: PropTypes.any,
};
