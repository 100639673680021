import DataProvider from './DataProvider';

export const empty = {
    nombre: '',
    descripcion: '',
};

class DocumentosProvider extends DataProvider {
    constructor() {
        super('documentos', empty);
    }
}

export const dataProvider = new DocumentosProvider();
