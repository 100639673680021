import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(
    (theme) => ({
        materialButton: {
            '& svg': {
                // color: '#818CAE',
                fontSize: 20,
            },
        },
    }),
    { name: 'DeleteDialog' },
);

export function DeleteDialog({ onDelete, disabled }) {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (!open) return;

        setIsDeleting(false);
    }, [open]);

    return (
        <>
            <IconButton
                className={styles.materialButton}
                size='small'
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                <DeleteIcon />
            </IconButton>
            <Dialog open={open}>
                <DialogTitle
                    disableTypography
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    <DeleteIcon style={{ fontSize: 60, color: '#e2e2e2', marginBottom: 8 }} />
                    ¿Estás seguro que quieres eliminar este material?
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'space-between', padding: 20 }}>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            setIsDeleting(true);
                            onDelete();
                            setOpen(false);
                        }}
                        variant='contained'
                        color='primary'
                        autoFocus
                        disabled={isDeleting}
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

DeleteDialog.propTypes = {
    disabled: PropTypes.any,
    onDelete: PropTypes.any,
};
