import DataProvider from './DataProvider';

export const empty = {
    lista_material: '',
    notas: '',
    fecha_creacion: null,
    fecha_actualizacion: null,
    estado: '',
    compras_material: null,
    solicitudes_material: null,
};

export const estadoPreparacionLabels = {
    SOLICITADO: 'Solicitado',
    EN_PROCESO: 'En proceso',
    PROCESADO: 'Procesado',
};

export const estadoPreparacionColors = {
    SOLICITADO: 'default',
    EN_PROCESO: 'info',
    PROCESADO: 'success',
};

class PreparacionesMaterialProvider extends DataProvider {
    constructor() {
        super('preparaciones_material', empty);
    }

    addSolicitudMaterial = async (tareaId, params) =>
        this.action(`solicitudes/by-tarea/${tareaId}`, { body: JSON.stringify(params), method: 'post' });

    editSolicitudMaterial = async (solicitudId, params) =>
        this.action(`solicitudes/${solicitudId}`, { body: JSON.stringify(params), method: 'post' });

    getSolicitudMaterial = async (solicitudId) => this.getAll(`solicitudes/${solicitudId}`);

    notificarSolicitudMaterial = async (solicitudId) => {
        return this.action(`solicitudes/${solicitudId}/notificar`, { method: 'post' });
    };

    getMaterialesPreparacion = async (tareaId) => this.getAll(`lista-material/${tareaId}`);
}

export const dataProvider = new PreparacionesMaterialProvider();
