import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../../api';
import { PageHeader } from '../../common/PageHeader';
import { RoundedButton } from '../../common/RoundedButton';
import { CERRAR, useTareaState } from '../TareaView';
import OpcionConfirmar from './OpcionConfirmar';

const useStyles = makeStyles(
    (theme) => ({
        opciones: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(6)}px`,
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
        opcion: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            maxWidth: 260,
            '& .MuiTypography-root': {
                color: 'white',
                fontSize: 14,
                textAlign: 'center',
            },
        },
    }),
    { name: 'OpcionesContinuarView' },
);

const DEFAULT_SUBVIEW = 'default';
const NOTIFICAR_SUBVIEW = 'notificar';
const NOTIFICAR_OK_SUBVIEW = 'notificar-ok';
const SALIR_SUBVIEW = 'salir';
const SALIR_OK_SUBVIEW = 'salir-ok';
const CERRAR_CON_OPERARIOS_SUBVIEW = 'cerrar-con-operarios';
const CERRAR_ALBARAN_SUBVIEW = 'cerrar-albaran';
const CERRAR_SUBVIEW = 'cerrar';
const CERRAR_OK_SUBVIEW = 'cerrar-ok';

export default function OpcionesContinuarView({ onClose, initialSubView = DEFAULT_SUBVIEW }) {
    const history = useHistory();
    const classes = useStyles();
    const { id, tarea, setView } = useTareaState();
    const [subView, setSubView] = useState(initialSubView);

    const isDefault = subView === DEFAULT_SUBVIEW;

    const hasOperarios = tarea.planificaciones.some((planificacion) =>
        planificacion.operarios.some((op) => !op.is_self && op.visible),
    );

    const hasPendientesAlbaran = tarea.jornadas.some((jornada) => jornada.albaran_id === null);

    const cerrarStartSubView = hasOperarios
        ? CERRAR_CON_OPERARIOS_SUBVIEW
        : hasPendientesAlbaran
        ? CERRAR_ALBARAN_SUBVIEW
        : CERRAR_SUBVIEW;

    function handleCerrarTarea() {
        tareasProvider.actionOnId(id, 'cerrar').then(() => setSubView(CERRAR_OK_SUBVIEW));
    }

    return (
        <PageHeader
            title={isDefault ? '¿Qué deseas hacer?' : ''}
            fill
            startButton={
                isDefault && (
                    <IconButton onClick={onClose}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                )
            }
        >
            {subView === DEFAULT_SUBVIEW && (
                <>
                    <div className={classes.opciones}>
                        <div className={classes.opcion}>
                            <RoundedButton onClick={() => setSubView(NOTIFICAR_SUBVIEW)}>
                                Notificar a administración
                            </RoundedButton>
                            <Typography>Con esta opción enviarás un correo notificando el servicio de hoy</Typography>
                        </div>
                        <div className={classes.opcion}>
                            <RoundedButton onClick={() => setSubView(SALIR_SUBVIEW)}>Salir del servicio</RoundedButton>
                            <Typography>
                                Si no tienes más trabajo a realizar en este servicio, puedes salir del trabajo. El
                                trabajo desaparece de la sección de Mis trabajos.
                            </Typography>
                        </div>
                        <div className={classes.opcion}>
                            <RoundedButton onClick={() => setSubView(cerrarStartSubView)}>
                                Cerrar servicio
                            </RoundedButton>
                            <Typography>
                                Si el trabajo está terminado, puedes cerrar el servicio. Opcionalmente puedes crear un
                                albarán de servicio.
                            </Typography>
                        </div>
                        <div className={classes.opcion}>
                            <RoundedButton onClick={() => onClose(true)}>Continuar trabajando</RoundedButton>
                        </div>
                    </div>
                </>
            )}

            {subView === NOTIFICAR_SUBVIEW && (
                <OpcionConfirmar
                    icon={InfoIcon}
                    acceptText='Si, notificar'
                    onAccept={() => {
                        tareasProvider.actionOnId(id, 'notificar').then(() => setSubView(NOTIFICAR_OK_SUBVIEW));
                    }}
                    onCancel={() => setSubView(DEFAULT_SUBVIEW)}
                >
                    <Typography variant='h2'>¿Estás seguro que quieres notificar el trabajo de hoy?</Typography>
                </OpcionConfirmar>
            )}
            {subView === NOTIFICAR_OK_SUBVIEW && (
                <OpcionConfirmar
                    icon={DoneIcon}
                    acceptText='Volver a mis trabajos'
                    onAccept={() => {
                        onClose();
                        history.replace('/');
                    }}
                >
                    <Typography variant='h1'>Notificación enviada</Typography>
                </OpcionConfirmar>
            )}

            {subView === SALIR_SUBVIEW && (
                <OpcionConfirmar
                    icon={InfoIcon}
                    acceptText='Si, salir del servicio'
                    onAccept={() => {
                        tareasProvider.actionOnId(id, 'salir').then(() => setSubView(SALIR_OK_SUBVIEW));
                    }}
                    onCancel={() => setSubView(DEFAULT_SUBVIEW)}
                >
                    <Typography variant='h2'>¿Estás seguro que quieres salir de este servicio?</Typography>
                </OpcionConfirmar>
            )}
            {subView === SALIR_OK_SUBVIEW && (
                <OpcionConfirmar
                    icon={DoneIcon}
                    acceptText='Volver a mis trabajos'
                    onAccept={() => {
                        onClose();
                        history.replace('/');
                    }}
                >
                    <Typography variant='h1'>Has salido del servicio</Typography>
                </OpcionConfirmar>
            )}

            {subView === CERRAR_CON_OPERARIOS_SUBVIEW && (
                <OpcionConfirmar
                    icon={InfoIcon}
                    acceptText='Si, cerrar servicio'
                    onAccept={() => (hasPendientesAlbaran ? setSubView(CERRAR_ALBARAN_SUBVIEW) : handleCerrarTarea())}
                    onCancel={onClose}
                >
                    <Typography variant='h2'>
                        En este servicio aún hay operarios que no han terminado su trabajo
                    </Typography>
                    <Typography variant='h2'>¿Estás seguro que quieres cerrar este servicio?</Typography>
                </OpcionConfirmar>
            )}
            {subView === CERRAR_ALBARAN_SUBVIEW && (
                <OpcionConfirmar
                    icon={DescriptionIcon}
                    acceptText='Si, generar albarán'
                    cancelText='Sólo cerrar servicio'
                    onAccept={() => {
                        setView(CERRAR);
                        onClose();
                    }}
                    onCancel={handleCerrarTarea}
                >
                    <Typography variant='h2'>Aún hay partes de trabajo sin albarán de servicio</Typography>
                    <Typography variant='h2'>¿Quieres generar un albarán de servicio antes de cerrar?</Typography>
                </OpcionConfirmar>
            )}
            {subView === CERRAR_SUBVIEW && (
                <OpcionConfirmar
                    icon={InfoIcon}
                    acceptText='Si, cerrar servicio'
                    onAccept={handleCerrarTarea}
                    onCancel={onClose}
                >
                    <Typography variant='h2'>¿Estás seguro que quieres cerrar este servicio?</Typography>
                </OpcionConfirmar>
            )}
            {subView === CERRAR_OK_SUBVIEW && (
                <OpcionConfirmar
                    icon={LockIcon}
                    acceptText='Volver a mis trabajos'
                    onAccept={() => {
                        onClose();
                        history.replace('/');
                    }}
                >
                    <Typography variant='h1'>Has cerrado el servicio</Typography>
                </OpcionConfirmar>
            )}
        </PageHeader>
    );
}

OpcionesContinuarView.propTypes = {
    initialSubView: PropTypes.any,
    onClose: PropTypes.any,
};
