import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { endOfDay, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { operariosProvider, tareasProvider } from '../../api';
import useSplash from '../../contexts/SplashState';
import { BigButton } from '../common/BigButton';
import { DateRangePicker } from '../common/DateRangePicker';
import { PageBodySection } from '../common/PageBodySection';
import { StartLabelSwitchFormik } from '../common/SwitchWithLabel';
import { ButtonField } from './common/ButtonField';
import { BaseSelectOperarioView } from './editar/SelectOperarioView';
import { useTareaState } from './TareaView';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            backgroundColor: 'white',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(0.5)}px`,
            '& .MuiTypography-subtitle2': {
                fontWeight: 'normal',
            },
        },
        dateRange: {
            display: 'flex',
            // alignItems: 'center',
            marginBottom: theme.spacing(1),
            '& .MuiInput-root': {
                background: 'white',
                borderRadius: 4,
            },
            '& .MuiInput-input': {
                fontSize: 13,
                padding: 10,
            },
            '&&& .MuiInput-underline:after': {
                borderBottom: 'none',
            },
            '&&& .MuiInput-underline:before': {
                borderBottom: 'none',
            },
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        switchLabelRoot: {
            flexDirection: 'row',
            marginLeft: 0,
            gap: '8px',
        },
        switchLabel: {
            flex: 1,
            color: '#213061',
            fontSize: 13,
        },
        buttonFieldContent: {
            margin: theme.spacing(1.25, 2),
            fontSize: 13,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
    }),
    { name: 'ConfigurarServicioLibre' },
);

const ConfigurarServicioLibreSchema = Yup.object().shape({
    fecha_inicio: Yup.date().typeError('La fecha de inicio debe ser una fecha').required('Requerido'),
    fecha_fin: Yup.date()
        .typeError('La fecha de fin debe ser una fecha')
        .required('Requerido')
        .min(Yup.ref('fecha_inicio'), 'La fecha de fin debe ser posterior a la de inicio'),
    operarios: Yup.array(),
});

export default function ConfigurarServicioLibre() {
    const classes = useStyles();
    const { id, refresh } = useTareaState();
    const { showCustomComponent } = useSplash();

    const [operariosOptions, setOperariosOptions] = useState([]);

    useEffect(() => {
        operariosProvider.getAll('as_options').then(setOperariosOptions);
    }, []);

    return (
        <>
            <div className={classes.header}>
                <Typography className={classes.label}>Asignar servicio libre</Typography>
                <Typography variant='subtitle2'>
                    Este servicio no tiene operarios asisgnados y cualquiera puede realizarlo.
                </Typography>
                <Typography variant='subtitle2'>Si quieres asignarte este servicio sigue estos 2 pasos:</Typography>
                <Typography variant='subtitle2'>1. Añade una fecha prevista de ejecución al servicio.</Typography>
                <Typography variant='subtitle2'>2. Asígnate a ti mismo y opcionalmente a más operarios.</Typography>
            </div>
            <PageBodySection className={classes.form}>
                <Formik
                    initialValues={{
                        fecha_inicio: startOfDay(new Date()),
                        fecha_fin: endOfDay(new Date()),
                        allDay: true,
                        operarios: operariosOptions
                            .filter((op) => op.is_self)
                            .map((op) => ({ operario: op, visible: true })),
                    }}
                    validationSchema={ConfigurarServicioLibreSchema}
                    enableReinitialize
                    onSubmit={({ fecha_inicio: fechaInicio, fecha_fin: fechaFin, allDay, operarios }) => {
                        if (allDay) {
                            fechaInicio.setHours(0, 0, 0, 0);
                            fechaFin.setHours(23, 59, 59, 0);
                        }

                        const params = {
                            fecha_inicio: fechaInicio,
                            fecha_fin: fechaFin,
                            operarios: operarios.map((op) => ({
                                ...op,
                                operario: { id: op.operario.id, nombre: op.operario.nombre },
                            })),
                        };

                        tareasProvider.actionOnId(id, 'asignar-libre', params).then(refresh);
                    }}
                >
                    {({ values: { fecha_inicio: fechaInicio, allDay, operarios }, setFieldValue, submitForm }) => (
                        <>
                            <Typography className={classes.label}>Fecha esperada de ejecución</Typography>

                            <div className={classes.dateRange}>
                                <DateRangePicker
                                    fecha={fechaInicio}
                                    allDay={allDay}
                                    setFieldValue={setFieldValue}
                                    fechaInicioKey={'fecha_inicio'}
                                    fechaFinKey={'fecha_fin'}
                                    showError={false}
                                    fromLabel={null}
                                    toLabel={'a'}
                                />
                            </div>
                            <StartLabelSwitchFormik
                                name={'allDay'}
                                label='Todo el dia'
                                classes={{
                                    root: classes.switchLabelRoot,
                                    label: classes.switchLabel,
                                }}
                            />
                            <ButtonField
                                style={{ marginTop: 16 }}
                                className={classes.buttonFieldContent}
                                value={operarios.map((op) => op.operario.nombre).join(', ')}
                                placeholder='Selecciona los operarios'
                                onClick={() => {
                                    showCustomComponent(({ closeSplash }) => (
                                        <BaseSelectOperarioView
                                            currentValue={operarios.map((op) => op.operario)}
                                            setFieldValue={(selectedOperarios) => {
                                                setFieldValue(
                                                    'operarios',
                                                    selectedOperarios.map((op) => ({ operario: op, visible: true })),
                                                );
                                                closeSplash();
                                            }}
                                            onClose={closeSplash}
                                            operarios={operariosOptions}
                                        />
                                    ));
                                }}
                            />
                            <BigButton onClick={submitForm} style={{ width: '100%', marginTop: 'auto' }}>
                                Asignar servicio
                            </BigButton>
                        </>
                    )}
                </Formik>
            </PageBodySection>
        </>
    );
}
