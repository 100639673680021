import DataProvider from './DataProvider';

export const empty = {};

export const Solicitudes = 0;
export const AUSENCIA = 1;

export const solicitudEstadoLabels = {
    PENDIENTE: 'Pendiente',
    APROBADA: 'Aprobada',
    RECHAZADA: 'Rechazada',
};

export const solicitudTipoLabels = {
    VACACIONES: 'Vacaciones',
    AUSENCIA: 'Ausencia',
};

class SolicitudesProvider extends DataProvider {
    constructor() {
        super('solicitudes', empty);
    }

    getResumen = async (year) => {
        if (year === undefined) year = new Date().getFullYear();

        return this.getAll(`resumen/${year}`);
    };

    solicitarVacaciones = async (values) => {
        return this.getAll('registrar', {
            method: 'post',
            body: JSON.stringify({
                ...values,
                tipo: 'VACACIONES',
            }),
        });
    };

    registrarAusencia = async (values) => {
        return this.getAll('registrar', {
            method: 'post',
            body: JSON.stringify({
                ...values,
                tipo: 'AUSENCIA',
            }),
        });
    };

    getByYearMonth = async (year, month) => {
        const url = month !== undefined ? `${year}/${month}` : `${year}`;
        return this.getAll(`operario/${url}`);
    };
}

export function convertDates(solicitud) {
    if (Array.isArray(solicitud)) return solicitud.map(convertDates);

    solicitud.fecha = new Date(solicitud.fecha);
    solicitud.fecha_inicio = new Date(solicitud.fecha_inicio);
    solicitud.fecha_fin = new Date(solicitud.fecha_fin);

    return solicitud;
}

// export const dataProvider = new DataProvider('fichajes', empty);
export const dataProvider = new SolicitudesProvider();
