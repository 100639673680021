import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isToday from 'date-fns/isToday';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import WheelPicker from 'react-simple-wheel-picker';
import { formatDate, formatTime } from '../../../utils';
import { PageHeader } from '../../common/PageHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        '& li': {
            justifyContent: 'center',
        },
        '& li span': {
            display: 'none',
        },
    },
}));

const dataHours = Array(24)
    .fill(0)
    .map((_, i) => ({
        id: i.toString(),
        value: i.toString().padStart(2, '0'),
    }));

const INTERVAL = 5;
const dataMinutes = [];

for (let i = 0; i < 60; i += INTERVAL) {
    dataMinutes.push({
        id: i.toString(),
        value: i.toString().padStart(2, '0'),
    });
}

export function getNearestTiempo(fecha) {
    const now = roundToNearestMinutes(fecha, { nearestTo: 5 });
    return { horas: now.getHours(), minutos: now.getMinutes() };
}

export default function SelectTime({ fecha, currentTiempo, onClose, onSelect, tipo, minTime, maxTime }) {
    const styles = useStyles();
    const [geolocationTimedOut, setGeolocationTimedOut] = useState(false);
    const [coordenadas, setCoordenadas] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [tiempo, setTiempo] = useState(() => {
        if (currentTiempo) return currentTiempo;

        return getNearestTiempo(new Date());
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setGeolocationTimedOut(true);
        }, 2000);

        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                function ({ coords: { latitude, longitude, accuracy } }) {
                    setCoordenadas({ latitude, longitude, accuracy });
                },
                function () {
                    setCoordenadas(null);
                },
                {
                    enableHighAccuracy: true,
                },
            );

            return () => {
                navigator.geolocation.clearWatch(watchId);
                clearTimeout(timeoutId);
            };
        }
    }, []);

    const handleOnChange = (field) => (value) => {
        if (!tiempo) return;

        setTiempo((tiempo) => ({ ...tiempo, [field]: Number(value.id) }));
    };

    const timePickerProps = {
        height: 380,
        width: null,
        itemHeight: 76,
        color: 'rgb(255,255,255,0.4)',
        activeColor: '#fff',
        focusColor: 'transparent',
        backgroundColor: 'transparent',
        shadowColor: 'none',
        fontSize: 48,
    };

    const minError = useMemo(() => {
        if (!minTime) return false;

        if (tipo === 'salida' && tiempo.horas === 0 && tiempo.minutos === 0) return false;

        const selected = new Date(minTime);
        selected.setHours(tiempo.horas, tiempo.minutos, 0, 0);
        return isBefore(selected, minTime);
    }, [minTime, tiempo, tipo]);

    const maxError = useMemo(() => {
        if (!maxTime) return false;

        const selected = new Date(maxTime);
        selected.setHours(tiempo.horas, tiempo.minutos, 0, 0);
        return isAfter(selected, maxTime);
    }, [maxTime, tiempo]);

    const hasError = minError || maxError;

    const formattedFecha = isToday(new Date(fecha)) ? 'hoy' : `el ${formatDate(fecha)}`;

    return (
        <PageHeader
            title={'Marcar tiempo de servicio'}
            fill
            startButton={
                <IconButton onClick={onClose}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            }
            endButton={
                <IconButton
                    disabled={(!geolocationTimedOut && !coordenadas) || hasError}
                    onClick={() =>
                        onSelect(tiempo, coordenadas).catch((err) => {
                            console.error(err);
                            const errorMessage = err.body.message;

                            const error = errorMessage.hora_entrada ?? errorMessage.hora_salida;
                            if (error) {
                                setValidationError(error[0]);
                            }
                        })
                    }
                >
                    <DoneIcon style={{ color: 'white' }} />
                </IconButton>
            }
        >
            <Typography
                variant='h3'
                style={{
                    textAlign: 'center',
                    marginTop: 48,
                    position: 'relative',
                }}
            >
                Selecciona una hora de {tipo} para {formattedFecha}
            </Typography>
            <Typography
                style={{
                    textAlign: 'center',
                    marginTop: 16,
                    marginBottom: 24,
                    position: 'relative',
                    color: 'white',
                }}
            >
                {minError && `La hora debe estar después de las ${formatTime(minTime)}`}
                {maxError && `La hora debe estar antes de las ${formatTime(maxTime)}`}
                {validationError}
            </Typography>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={styles.root}>
                    {tiempo !== null && (
                        <>
                            <WheelPicker
                                data={dataHours}
                                onChange={handleOnChange('horas')}
                                selectedID={tiempo.horas.toString()}
                                {...timePickerProps}
                            />
                            <WheelPicker
                                data={dataMinutes}
                                onChange={handleOnChange('minutos')}
                                selectedID={tiempo.minutos.toString()}
                                {...timePickerProps}
                            />
                        </>
                    )}
                </div>
            </div>
        </PageHeader>
    );
}

SelectTime.propTypes = {
    currentTiempo: PropTypes.any,
    fecha: PropTypes.any,
    maxTime: PropTypes.any,
    minTime: PropTypes.any,
    onClose: PropTypes.any,
    onSelect: PropTypes.any,
    tipo: PropTypes.any,
};
