import SvgIcon from '@material-ui/core/SvgIcon';

export default function ListaIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path d='M7.50049 12.0047H16.5005V10.4047H7.50049V12.0047Z' />
            <path d='M16.5005 17.95H7.50049V16.35H16.5005V17.95Z' />
            <path d='M16.5005 14.95H7.50049V13.35H16.5005V14.95Z' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.12904 2.05005C8.57676 2.05005 8.12904 2.49776 8.12904 3.05005V3.25017H6.5002C4.9538 3.25017 3.7002 4.50377 3.7002 6.05017V19.1501C3.7002 20.6965 4.9538 21.9501 6.5002 21.9501H17.5002C19.0466 21.9501 20.3002 20.6965 20.3002 19.1501V6.05017C20.3002 4.50377 19.0466 3.25017 17.5002 3.25017H15.8599V3.05005C15.8599 2.49776 15.4122 2.05005 14.8599 2.05005H9.12904ZM9.72904 3.65005V4.45005H14.2599V3.65005H9.72904ZM15.8599 5.05005V4.85017H17.5002C18.1629 4.85017 18.7002 5.38743 18.7002 6.05017V19.1501C18.7002 19.8129 18.1629 20.3501 17.5002 20.3501H6.5002C5.83745 20.3501 5.3002 19.8129 5.3002 19.1501V6.05017C5.3002 5.38743 5.83745 4.85017 6.5002 4.85017H8.12904V5.05005C8.12904 5.60233 8.57676 6.05005 9.12904 6.05005H14.8599C15.4122 6.05005 15.8599 5.60233 15.8599 5.05005Z'
            />
        </SvgIcon>
    );
}
