import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../common/Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: 'white',
            backgroundColor: '#23395F',
            borderRadius: 8,
            minHeight: 36,
        },
        expanded: {},
        header: {
            minHeight: 36,
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            padding: theme.spacing(0, 1),
            '& .MuiTypography-root': {
                fontWeight: 400,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
            '& .MuiButton-root': {
                marginRight: -theme.spacing(1.5),
            },
        },
        title: {
            flex: 1,
            fontWeight: 400,
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
        },
        mensaje: {
            margin: theme.spacing(0, 1, 1, 1),
            whiteSpace: 'pre-wrap',
        },
    }),
    { name: 'NotaDia' },
);
export default function NotaDia({ nota }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <ChatIcon />{' '}
                <Typography variant='subtitle2' className={classes.title}>
                    {!expanded && nota.titulo}
                </Typography>
                <Button color='white' size='small' onClick={() => setExpanded((v) => !v)}>
                    {expanded ? 'Leer menos' : 'Leer más'}
                </Button>
            </div>
            {expanded && (
                <>
                    <Typography variant='subtitle2' className={classes.mensaje}>
                        {nota.titulo}
                    </Typography>
                    <Typography variant='subtitle2' className={classes.mensaje}>
                        {nota.mensaje}
                    </Typography>
                </>
            )}
        </div>
    );
}

NotaDia.propTypes = {
    nota: PropTypes.object.isRequired,
};
