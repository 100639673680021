import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: (props) =>
                `${theme.spacing(1)}px ${theme.spacing(2)}px ${
                    props.bottomPadding ? theme.spacing(1) : 0
                }px ${theme.spacing(2)}px`,
            '&:not(:first-of-type)': {
                borderTop: '0.5px solid #818cae60',
            },
        },
    }),
    { name: 'PageBodySection' },
);

export function PageBodySection({ style, className, children, bottomPadding = false }) {
    const classes = useStyles({ bottomPadding });

    return (
        <div className={clsx(classes.root, className)} style={style}>
            {children}
        </div>
    );
}

PageBodySection.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    style: PropTypes.any,
    bottomPadding: PropTypes.any,
};
