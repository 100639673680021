import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { clientesProvider } from '../../../api';
import ListView from '../../common/ListView';
import { GENERAL } from '../nueva/NuevaTarea';

export default function SelectDireccionView({ tarea, setFieldValue, setCurrentFieldComponent, clienteId }) {
    const [direcciones, setDirecciones] = useState(null);

    useEffect(() => {
        if (!clienteId) {
            setDirecciones([]);
            return;
        }
        clientesProvider.actionOnId(clienteId, 'direcciones/as_options', null, { method: 'get' }).then(setDirecciones);
    }, [clienteId]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Direccion',
                accessor: 'direccion_completa',
            },
        ],
        [],
    );

    return (
        direcciones && (
            <ListView
                title='Seleccionar direccion'
                onClose={() => setCurrentFieldComponent(GENERAL)}
                setFieldValue={(value) => {
                    setFieldValue('direccion')(value);
                }}
                currentValue={tarea.direccion}
                items={direcciones}
                columns={columns}
                searchPlaceholder='Buscar direccion'
                canAdd
            />
        )
    );
}

SelectDireccionView.propTypes = {
    clienteId: PropTypes.any,
    setCurrentFieldComponent: PropTypes.any,
    setFieldValue: PropTypes.any,
    tarea: PropTypes.any,
};
