import withStyles from '@material-ui/core/styles/withStyles';
import MuiSwitch from '@material-ui/core/Switch';

export const Switch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: '#F3F5FA',
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#F3F5FA',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#2196F3',
                border: 'none',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        opacity: 1,
        backgroundColor: '#e2e2e2',
    },
    checked: {},
}))(MuiSwitch);

export const BigSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 27,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: '#565656',
        '&$checked': {
            transform: 'translateX(21px)',
            color: '#4C60FE',
            '& + $track': {
                opacity: 1,
                backgroundColor: 'white',
                border: 'none',
            },
        },
    },
    thumb: {
        width: 22,
        height: 22,
        boxShadow: 'none',
    },
    track: {
        opacity: 1,
        backgroundColor: '#e2e2e2',
        borderRadius: 14,
    },
    checked: {},
}))(MuiSwitch);
