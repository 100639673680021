import FolderIcon from '@material-ui/icons/Folder';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PropTypes from 'prop-types';

export default function DocumentoIcon({ documento }) {
    if (documento.es_carpeta) return <FolderIcon />;
    if (documento.content_type.startsWith('image')) return <ImageIcon />;
    if (documento.content_type === 'application/pdf') return <PictureAsPdfIcon />;
    return <InsertDriveFileIcon />;
}

DocumentoIcon.propTypes = {
    documento: PropTypes.object,
};
