import { Typography } from '@material-ui/core';
// eslint-disable-next-line import/no-named-default
import { default as MuiButton } from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { addMinutes } from 'date-fns';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { tareasProvider } from '../../../api';
import { BigButton } from '../../common/BigButton';
import Button from '../../common/Button';
import { Input } from '../../common/Input';
import { PageBody } from '../../common/PageBody';
import { PageBodySection } from '../../common/PageBodySection';
import { PageHeader } from '../../common/PageHeader';
import { ButtonField } from '../common/ButtonField';
import { CLIENTE, DIRECCION, PROYECTO } from './EditarTarea';
import { PlanificacionFormItem } from './PlanificacionFormItem';

const useStyles = makeStyles(
    (theme) => ({
        label: {
            color: '#213061',
            fontSize: 14,
            fontWeight: 500,
            marginBottom: theme.spacing(1),
            '&:not(:first-of-type)': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& svg': {
                fontSize: 20,
            },
        },
        addButtonSection: {
            display: 'flex',
            marginBottom: theme.spacing(1),
        },
        addButton: {
            fontSize: 12,
            marginLeft: 'auto',
        },
        buttonField: {
            marginTop: theme.spacing(1),
        },
        buttonFieldContent: {
            margin: theme.spacing(1.25, 2),
            fontSize: 13,
            '& span': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                borderRadius: 4,
                padding: theme.spacing(0.5, 1),
                marginRight: theme.spacing(1),
                fontSize: '80%',
            },
        },
        operariosList: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: theme.spacing(1.5),
        },
        switches: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: theme.spacing(2),
        },
    }),
    { name: 'GeneralView' },
);

export function createEmptyPlanificacion(selectedDate, selectedOperarios) {
    const now = roundToNearestMinutes(addMinutes(selectedDate, 8), { nearestTo: 15 });

    return {
        fecha_inicio: now,
        fecha_fin: addMinutes(now, 60),
        fecha_visita: null,
        fecha_inicio_jornada: null,
        usarFechaInicioJornada: false,
        allDay: true,
        operarios: selectedOperarios.map((op) => ({ operario: op, visible: true, fecha_salida: null })),
        vehiculo: null,
        confirmada: false,
        extender_automaticamente: false,
        editable: true,
    };
}

export default function GeneralView({ tareaId, setCurrentFieldComponent, onClose, onDelete }) {
    const classes = useStyles();
    const { values, setFieldValue: setFormFieldValue, submitForm } = useFormikContext();

    return (
        <React.Fragment>
            <PageHeader
                title={tareaId ? 'Editar servicio' : 'Añadir servicio'}
                startButton={
                    <IconButton onClick={onClose}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            />
            <PageBody style={{ padding: 0, paddingTop: 8 }}>
                <PageBodySection>
                    <ButtonField
                        label='Cliente'
                        className={classes.buttonFieldContent}
                        value={
                            values.cliente && (
                                <div>
                                    {values.cliente.id === null && <span>NUEVO</span>}
                                    {values.cliente.nombre}
                                </div>
                            )
                        }
                        placeholder='Selecciona el cliente'
                        onClick={
                            tareaId === null &&
                            (() =>
                                setCurrentFieldComponent(CLIENTE, {
                                    currentValue: values.cliente,
                                    setFieldValue: (fieldKey) => (value) => {
                                        console.log(fieldKey, value);
                                        setFormFieldValue(fieldKey, value);
                                    },
                                }))
                        }
                    />
                    <ButtonField
                        label='Direccion'
                        style={{ marginTop: 16 }}
                        className={classes.buttonFieldContent}
                        value={
                            values.direccion && (
                                <div>
                                    {values.direccion.id === null && <span>NUEVO</span>}
                                    {values.direccion.direccion_completa ?? values.direccion.nombre}
                                </div>
                            )
                        }
                        placeholder={values.cliente ? 'Selecciona una direccion' : 'Selecciona primero un cliente'}
                        onClick={
                            tareaId === null &&
                            (() =>
                                values.cliente &&
                                setCurrentFieldComponent(DIRECCION, {
                                    clienteId: values.cliente.id,
                                    setFieldValue: (fieldKey) => (value) => {
                                        console.log(fieldKey, value);
                                        setFormFieldValue(fieldKey, value);
                                    },
                                }))
                        }
                    />
                    <ButtonField
                        label='Proyecto'
                        style={{ marginTop: 16 }}
                        className={classes.buttonFieldContent}
                        value={
                            values.proyecto && (
                                <div>
                                    {values.proyecto.id === null && <span>NUEVO</span>}
                                    {values.proyecto.nombre}
                                </div>
                            )
                        }
                        placeholder={values.cliente ? 'Sin proyecto asignado' : 'Selecciona primero un cliente'}
                        onClick={
                            tareaId === null &&
                            (() =>
                                values.cliente &&
                                setCurrentFieldComponent(PROYECTO, {
                                    clienteId: values.cliente.id,
                                    setFieldValue: (fieldKey) => (value) => {
                                        console.log(fieldKey, value);
                                        setFormFieldValue(fieldKey, value);
                                    },
                                }))
                        }
                    />
                    <Typography className={classes.label}>Título del servicio</Typography>
                    <Input name='descripcion' disableUnderline fullWidth style={{ marginBottom: 16 }} />
                    {tareaId === null && (
                        <div>
                            <PlanificacionFormItem
                                planificacion={values.planificaciones[0]}
                                index={0}
                                setCurrentFieldComponent={setCurrentFieldComponent}
                                isSingle
                            />
                        </div>
                    )}
                </PageBodySection>
                {tareaId !== null && (
                    <FieldArray
                        name='planificaciones'
                        render={({ push, remove }) => (
                            <>
                                {values.planificaciones.map((planificacion, i) => {
                                    return (
                                        <PageBodySection key={i}>
                                            <PlanificacionFormItem
                                                planificacion={planificacion}
                                                index={i}
                                                onRemove={() => remove(i)}
                                                setCurrentFieldComponent={setCurrentFieldComponent}
                                                isSingle={tareaId === null}
                                            />
                                        </PageBodySection>
                                    );
                                })}
                                <PageBodySection className={classes.addButtonSection}>
                                    <MuiButton
                                        startIcon={<AddIcon />}
                                        className={classes.addButton}
                                        color='primary'
                                        onClick={() => push(createEmptyPlanificacion(new Date(), []))}
                                    >
                                        {values.planificaciones.length > 0
                                            ? 'Añadir otra planificación'
                                            : 'Añadir planificación'}
                                    </MuiButton>
                                </PageBodySection>
                            </>
                        )}
                    />
                )}
                <PageBodySection
                    style={{ display: 'flex', flexDirection: 'column', paddingTop: 16, marginTop: 'auto' }}
                >
                    <BigButton
                        style={{ marginTop: 'auto' }}
                        disabled={!(values.cliente && values.direccion && values.descripcion)}
                        onClick={submitForm}
                    >
                        Guardar servicio
                    </BigButton>
                    {onDelete && <BorrarTareaDialog id={tareaId} onDelete={onDelete} />}
                </PageBodySection>
            </PageBody>
        </React.Fragment>
    );
}

GeneralView.propTypes = {
    onClose: PropTypes.any,
    onDelete: PropTypes.any,
    setCurrentFieldComponent: PropTypes.any,
    tareaId: PropTypes.any,
};

export const DeleteButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        textTransform: 'none',
        fontSize: 15,
        padding: 16,
        borderRadius: 8,
        marginBottom: 24,
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(({ children, ...props }) => (
    <Button startIcon={<DeleteIcon />} color='error' {...props}>
        {children}
    </Button>
));

function BorrarTareaDialog({ id, onDelete }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <DeleteButton onClick={() => setOpen(true)}>Borrar servicio</DeleteButton>
            <Dialog open={open}>
                <DialogTitle
                    disableTypography
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    ¿Estás seguro que quieres borrar este servicio?
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'space-between', padding: 20 }}>
                    <Button outline color='primary' onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            tareasProvider.delete(id).then(() => {
                                setOpen(false);
                                onDelete && onDelete();
                            });
                        }}
                        color='primaryFilled'
                        autoFocus
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

BorrarTareaDialog.propTypes = {
    id: PropTypes.any,
    onDelete: PropTypes.any,
};
