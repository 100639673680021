import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { PageBodySection } from '../../common/PageBodySection';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'InfoPageBodySection' },
);

export default function InfoPageBodySection({ children, ...props }) {
    const classes = useStyles();

    return (
        <PageBodySection className={classes.root} {...props}>
            {children}
        </PageBodySection>
    );
}

InfoPageBodySection.propTypes = {
    children: PropTypes.any,
};
