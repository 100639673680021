import { Checkbox } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { checklistsProvider } from '../../../api';
import { PageBody } from '../../common/PageBody';
import { useTareaState } from '../TareaView';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.text.primary,
            '& .MuiListItem-root .MuiTypography-body2': {
                fontWeight: 'normal',
            },
        },
        listItem: {
            paddingLeft: theme.spacing(1),
        },
    }),
    { name: 'ChecklistPage' },
);

export default function ChecklistPage({ checklist }) {
    const classes = useStyles();
    const { setTarea } = useTareaState();

    return (
        <PageBody className={classes.root} paddingTop={0}>
            <List dense disablePadding>
                {checklist &&
                    checklist.items.map((item, i) => (
                        <ListItem
                            key={i}
                            dense
                            button
                            divider
                            className={classes.listItem}
                            onClick={() => {
                                checklistsProvider
                                    .actionOnId(checklist.id, `check-item/${item.id}`)
                                    .then((checklist) => {
                                        setTarea((tarea) => ({
                                            ...tarea,
                                            checklists_tarea: [checklist],
                                        }));
                                    });
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox checked={item.completado} />
                            </ListItemIcon>
                            <ListItemText primary={item.texto} />
                        </ListItem>
                    ))}
            </List>
        </PageBody>
    );
}

ChecklistPage.propTypes = {
    checklist: PropTypes.any,
};
