import PropTypes from 'prop-types';
import React from 'react';
import DrawerMenu from '../common/DrawerMenu';
import ListView from '../common/ListView';

export default function SelectClienteView({ clientes, onSelect }) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
        ],
        [],
    );

    return (
        clientes && (
            <ListView
                autoOk
                title='Albaranes para firmar'
                subtitle='Selecciona un cliente para ver sus albaranes pendientes de firma'
                setFieldValue={onSelect}
                currentValue={null}
                items={clientes}
                columns={columns}
                searchPlaceholder='Buscar cliente'
                customEndButton={<DrawerMenu />}
            />
        )
    );
}

SelectClienteView.propTypes = {
    clientes: PropTypes.any,
    onSelect: PropTypes.any,
};
