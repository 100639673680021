import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: '10px',
        paddingBottom: theme.spacing(2),
        '& div': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            fontSize: 10,
            padding: theme.spacing(0, 1),
            color: '#213061',
            height: 20,
            borderRadius: 10,
            '& span': {
                width: 5,
                height: 5,
                marginRight: theme.spacing(0.5),
                borderRadius: '50%',
            },
        },
    },
}));

export default function LeyendaCalendario({ items }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {items.map((item, i) => (
                <div key={i}>
                    <span style={{ backgroundColor: item.color }} /> {item.label}
                </div>
            ))}
        </div>
    );
}

LeyendaCalendario.propTypes = {
    items: PropTypes.any,
};
