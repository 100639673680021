import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Switch } from '../Switch';

export function SwitchWithLabelFormik({ name, onChange, ...props }) {
    const [field, ,] = useField(name);

    return (
        <FormControlLabel
            control={
                <Switch
                    name={name}
                    checked={field.value}
                    onChange={(ev) => {
                        field.onChange(ev);
                        onChange && onChange(ev);
                    }}
                    onBlur={field.onBlur}
                />
            }
            {...props}
        />
    );
}

const useRightSwitchStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 8,
        marginRight: 8,
        marginLeft: 0,
    },
    label: {
        flex: 1,
        color: '#818CAE',
        fontSize: 14,
        marginLeft: 0,
    },
}));

const useLeftSwitchStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: 8,
        marginRight: 8,
        marginLeft: 0,
    },
    label: {
        flex: 1,
        color: '#818CAE',
        fontSize: 14,
        marginLeft: theme.spacing(1),
    },
}));

export const StartLabelSwitchFormik = ({ classes = {}, ...props }) => {
    const localClasses = useRightSwitchStyles();

    return <SwitchWithLabelFormik {...props} classes={{ ...localClasses, ...classes }} labelPlacement='start' />;
};

export function SwitchWithLabel({ name, onChange, classes = {}, ...props }) {
    const localClasses = useLeftSwitchStyles();

    return (
        <FormControlLabel
            classes={{ ...localClasses, ...classes }}
            control={<Switch name={name} onChange={onChange} />}
            {...props}
        />
    );
}

export const StartLabelSwitch = (props) => {
    const classes = useRightSwitchStyles();

    return <SwitchWithLabel {...props} classes={classes} labelPlacement='start' />;
};

SwitchWithLabel.propTypes = {
    name: PropTypes.any,
    onChange: PropTypes.any,
    classes: PropTypes.object,
};

StartLabelSwitchFormik.propTypes = {
    classes: PropTypes.object,
};

SwitchWithLabelFormik.propTypes = {
    name: PropTypes.any,
    onChange: PropTypes.any,
};
