import PropTypes from 'prop-types';
import { preparacionesMaterialProvider } from '../../../../api';
import { PageBodySection } from '../../../common/PageBodySection';
import MaterialesForm from '../MaterialesForm';

export function AddSolicitudLineas({ solicitud, tareaId, setViewParams }) {
    return (
        <>
            <PageBodySection>
                <MaterialesForm
                    elementId={'materiales'}
                    collectionName={'materiales'}
                    materiales={solicitud.lineas}
                    preventDeleteAllLineas
                    canEdit={true}
                    onChange={() => {}}
                    onSave={(materiales) => {
                        const promise =
                            solicitud.id === null
                                ? preparacionesMaterialProvider.addSolicitudMaterial(tareaId, { lineas: materiales })
                                : preparacionesMaterialProvider.editSolicitudMaterial(solicitud.id, {
                                      lineas: materiales,
                                  });

                        return promise.then((updatedSolicitud) => {
                            setViewParams((viewParams) => ({
                                ...viewParams,
                                solicitud: updatedSolicitud,
                                updatedSolicitudId: updatedSolicitud.id,
                                isNew: solicitud.id === null,
                            }));

                            return updatedSolicitud;
                        });
                    }}
                />
            </PageBodySection>
        </>
    );
}

AddSolicitudLineas.propTypes = {
    solicitud: PropTypes.any,
    tareaId: PropTypes.any,
    setViewParams: PropTypes.any,
};
