import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 24,
    },
}));

export function ButtonDialog({ button, title, content, onAccept, okText = 'Ok', okColor = 'success' }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const formButton = React.cloneElement(button, { onClick: () => setOpen(true) });

    return (
        <>
            {formButton}
            <Dialog open={open} classes={classes}>
                <DialogTitle
                    disableTypography
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    {title}
                </DialogTitle>
                {content && (
                    <DialogContent
                        style={{
                            color: '#818CAE',
                            fontSize: 13,
                            textAlign: 'center',
                        }}
                    >
                        {content}
                    </DialogContent>
                )}
                <DialogActions style={{ justifyContent: 'space-between', padding: 20 }}>
                    <Button onClick={() => setOpen(false)} color='transparent'>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            if (!open) return;

                            setOpen(false);
                            onAccept();
                        }}
                        color={okColor || 'success'}
                        autoFocus
                    >
                        {okText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ButtonDialog.propTypes = {
    button: PropTypes.any,
    content: PropTypes.any,
    okColor: PropTypes.string,
    okText: PropTypes.string,
    onAccept: PropTypes.any,
    title: PropTypes.any,
};
