import { IconButton } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Field } from './Field';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            fontSize: 'inherit',
            color: (props) => (props.variant === 'default' ? 'inherit' : 'white'),
            margin: theme.spacing(1.25, 1.5),
        },
        input: {
            padding: 0,
        },
        inputWithAdornment: {
            marginLeft: theme.spacing(1),
            color: (props) => (props.variant === 'default' ? 'inherit' : 'white'),
        },
        adornment: {
            color: (props) => (props.variant === 'default' ? 'inherit' : 'white'),
        },
    }),
    { name: 'TextField' },
);

export const TextField = ({
    label,
    value,
    onChange,
    backgroundColor,
    fieldStyle,
    classes = {},
    variant = 'default',
    ...props
}) => {
    const styles = useStyles({ variant });

    return (
        <Field label={label} backgroundColor={backgroundColor} style={fieldStyle} variant={variant}>
            <InputBase
                fullWidth
                value={value}
                onChange={onChange}
                endAdornment={
                    value !== null &&
                    value !== '' && (
                        <IconButton
                            size='small'
                            onClick={() => onChange({ target: { value: '' } })}
                            className={styles.adornment}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                }
                classes={{
                    root: classNames(styles.root, classes.root),
                    input: classNames(
                        styles.input,
                        { [styles.inputWithAdornment]: Boolean(props.startAdornment) },
                        classes.input,
                    ),
                }}
                {...props}
            />
        </Field>
    );
};

TextField.propTypes = {
    backgroundColor: PropTypes.any,
    classes: PropTypes.object,
    fieldStyle: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.any,
    startAdornment: PropTypes.any,
    value: PropTypes.any,
    variant: PropTypes.string,
};
