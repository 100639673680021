import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.neutral.dark,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            '& .MuiTypography-root': {
                color: 'white',
            },
        },
    }),
    { name: 'LoadingScreen' },
);

export default function LoadingScreen() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress color='inherit' />
            <Typography>Cargando datos...</Typography>
        </div>
    );
}
