import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { tareasProvider } from '../../api';
import { usePermisos, usePreferencias } from '../../contexts/AuthState';
import useSplash from '../../contexts/SplashState';
import LoadingScreen from '../common/LoadingScreen';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import { BaseCerrarView } from '../tareas/CerrarView';
import { BaseFirmarView } from '../tareas/firmar/FirmarView';

export default function AlbaranView({ albaranId, onClose, onFirmar }) {
    const enviarAlbaranes = usePermisos('enviar_albaranes');
    const enviarMailCliente = usePreferencias('enviar_mail_cliente') && enviarAlbaranes;
    const [enviarCopia, setEnviarCopia] = useState(enviarMailCliente);
    const [firma, setFirma] = useState(null);
    const { showCustomComponent } = useSplash();

    const [albaran, setAlbaran] = useState(null);

    useEffect(() => {
        tareasProvider.getAll(`albaranes/pendientes-firma/${albaranId}`).then((res) => {
            setAlbaran(res);
        });
    }, []);

    if (!albaran) return <LoadingScreen />;

    return (
        <>
            <PageHeader
                title={`Revisar albarán ${albaran.numero}`}
                startButton={
                    <IconButton onClick={onClose}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            >
                <Typography variant='h2'>{albaran.cliente}</Typography>
                <Typography variant='h3'>{albaran.descripcion}</Typography>
            </PageHeader>
            <PageBody paddingTop={0}>
                <BaseCerrarView
                    tareaId={albaran.tarea_id}
                    albaran={albaran}
                    firma={firma}
                    clienteId={albaran.cliente_id}
                    jornadas={albaran.jornadas}
                    enviarCopia={enviarCopia}
                    setEnviarCopia={setEnviarCopia}
                    onRepetirFirma={() => {
                        showCustomComponent(({ closeSplash }) => (
                            <BaseFirmarView
                                cliente={albaran.cliente}
                                descripcion={albaran.descripcion}
                                onClose={closeSplash}
                                onSave={(firma) => {
                                    setFirma(firma);
                                    closeSplash();
                                }}
                            />
                        ));
                    }}
                    onAlbaranGenerado={() => {
                        onFirmar();
                        onClose();
                    }}
                />
            </PageBody>
        </>
    );
}

AlbaranView.propTypes = {
    albaranId: PropTypes.any,
    onClose: PropTypes.any,
    onFirmar: PropTypes.any,
};
