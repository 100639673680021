import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { estadoLabels as estadoLabelsTarea } from '../../../api/tareas';
import { formatTiempo } from '../../../utils';
import ItemCard from '../../common/ItemCardList/ItemCard';
const useStyles = makeStyles(
    (theme) => ({
        urgente: {
            backgroundColor: '#F24822',
            color: 'white',
        },
        estado: {
            '&.HAS_INCIDENCIA': {
                backgroundColor: '#E94F4F',
            },
            '&.SALIDO': {
                backgroundColor: '#E94F4F',
            },
            '&.URGENTE': {
                backgroundColor: '#B62100',
            },
            '&.PENDIENTE, &.SIN_PLANIFICAR': {
                backgroundColor: '#3D79F3',
            },
            '&.EN_PROGRESO': {
                backgroundColor: '#FDA729',
            },
            '&.FINALIZADA': {
                backgroundColor: '#1BC47D',
            },
        },
        operariosList: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
            flexWrap: 'wrap',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.neutral.grey1,
                padding: theme.spacing(0.25, 1),
                borderRadius: 20,
            },
            '& .MuiSvgIcon-root': {
                color: 'inherit',
                fontSize: 16,
            },
        },
    }),
    { name: 'TareaItem' },
);

const estadoLabels = {
    ...estadoLabelsTarea,
    HAS_INCIDENCIA: 'Incidencia',
    SALIDO: 'Has salido',
    URGENTE: 'Urgente',
};

export default function TareaItem({ cliente, descripcion, tiempo, estado, operarios, onClick, className }) {
    const classes = useStyles();

    return (
        <ItemCard
            classes={{
                root: classNames(estado === 'URGENTE' ? classes.urgente : null, className),
                estado: classNames(classes.estado, estado),
            }}
            title={cliente}
            subtitle={descripcion}
            estado={estado && estadoLabels[estado]}
            valor={tiempo && formatTiempo(tiempo)}
            onClick={onClick}
        >
            {operarios && operarios.length > 0 && (
                <div className={classes.operariosList}>
                    {operarios.map((operario, i) => (
                        <Typography key={i} variant='caption'>
                            {operario.es_responsable && <StarIcon />} {operario.is_self ? 'Tú' : operario.nombre}
                        </Typography>
                    ))}
                </div>
            )}
        </ItemCard>
    );
}

TareaItem.propTypes = {
    cliente: PropTypes.any,
    descripcion: PropTypes.any,
    tiempo: PropTypes.any,
    estado: PropTypes.any,
    operarios: PropTypes.any,
    onClick: PropTypes.any,
    className: PropTypes.any,
};
