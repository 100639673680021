import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import API_ROOT from '../../api/api-config';
import AuthService from './AuthService';

const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Requerido'),
    password: Yup.string().required('Requerido'),
});

const styles = (theme) => ({
    main: {
        width: 'auto',
        display: 'flex', // Fix IE 11 issue.
        flexDirection: 'column',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
    },
    header: {
        display: 'flex',
        fontSize: 24,
        fontWeight: 'bold',
        color: '#213061',
        alignItems: 'center',
    },
    logo: {
        margin: theme.spacing(2, 1.5, 2, 2),
        // height: theme.spacing(3),
    },
    paper: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        borderRadius: 8,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        marginTop: theme.spacing(3),
        height: 48,
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 24,
        color: '#363636',
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
    },
    subtitle: {
        fontSize: 16,
        color: '#9B9B9B',
        marginBottom: theme.spacing(3),
    },
    recoverPassword: {
        fontSize: 12,
        alignSelf: 'center',
        marginTop: theme.spacing(3),
        fontWeight: 500,
        textTransform: 'none',
    },
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService(API_ROOT);
    }

    render() {
        const { classes, onLogin } = this.props;

        return (
            <>
                <Typography className={classes.header}>
                    <img src='/logo-text-panel.png' alt='Labory logo' className={classes.logo} />
                    <Typography>para operarios</Typography>
                </Typography>
                <main className={classes.main}>
                    <Typography className={classes.title}>Bienvenido,</Typography>
                    <Typography className={classes.subtitle}>Inicia sesión para continuar</Typography>
                    <div className={classes.paper}>
                        <Formik
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={(values, actions) => {
                                this.authService
                                    .login(values.username, values.password)
                                    .then((res) => {
                                        onLogin(res.token, values.username);
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        console.log(err.response);
                                        if (err.response.status === 401)
                                            actions.setFieldError(
                                                'username',
                                                'Nombre de usuario o contraseña incorrectos',
                                            );
                                        else {
                                            err.response.json().then((data) => {
                                                actions.setFieldError('_global', data.message);
                                            });
                                        }
                                        actions.setSubmitting(false);
                                    });
                            }}
                        >
                            {({ isSubmitting, errors }) => (
                                <Form className={classes.form}>
                                    <TextField
                                        name='username'
                                        label='Usuario'
                                        className={classes.textField}
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        name='password'
                                        label='Contraseña'
                                        type='password'
                                        className={classes.textField}
                                        disabled={isSubmitting}
                                    />
                                    {errors._global && (
                                        <div style={{ fontSize: 12, color: 'red' }}>{errors._global}</div>
                                    )}
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        className={classes.submit}
                                        disabled={isSubmitting}
                                    >
                                        Entrar
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <Button className={classes.recoverPassword} component={Link} to='/recover-password'>
                        ¿Olvidaste la contraseña?
                    </Button>
                </main>
            </>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired,
};

export default withStyles(styles)(Login);
