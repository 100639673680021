import { makeStyles } from '@material-ui/core/styles';
import CropIcon from '@material-ui/icons/Crop';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Button from '../../common/Button';
import './styles.scss';
const useStyles = makeStyles(
    (theme) => ({
        camera: {
            background: 'black',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            '& .react-html5-camera-photo': {
                top: '50%',
                transform: 'translate(0, -50%)',
            },
            '& #container-circles': {
                zIndex: 999,
            },
        },
        overlay: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
        },
        cropper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            width: '100%',
            height: '100%',
        },
        cropButton: {
            position: 'absolute',
            zIndex: 999,
            left: '50%',
            bottom: 100,
            transform: 'translate(-50%, 0)',
        },
    }),
    { name: 'FirmaCamara' },
);

function fitCanvasIntoResult(srcCanvas, dstCanvas) {
    const dstContext = dstCanvas.getContext('2d');

    const srcWidth = srcCanvas.width;
    const srcHeight = srcCanvas.height;
    const dstWidth = dstCanvas.width;
    const dstHeight = dstCanvas.height;

    const scaleX = dstWidth / srcWidth;
    const scaleY = dstHeight / srcHeight;
    const scale = Math.min(scaleX, scaleY);

    const newWidth = srcWidth * scale;
    const newHeight = srcHeight * scale;

    dstCanvas.width = newWidth;
    dstCanvas.height = newHeight;

    dstContext.drawImage(srcCanvas, 0, 0, newWidth, newHeight);
}

export default function FirmaCamara({ open, onCapture, canvas }) {
    const classes = useStyles();
    const cropperRef = useRef(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (!open) setImage(null);
    }, [open]);

    useEffect(() => {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        return () => {
            if (image) {
                URL.revokeObjectURL(image);
            }
        };
    }, [image]);

    const onCrop = () => {
        const cropper = cropperRef.current;
        if (cropper) {
            const cropperCanvas = cropper.getCanvas();
            if (cropperCanvas) {
                canvas.width = 600;
                canvas.height = 600;
                fitCanvasIntoResult(cropperCanvas, canvas);
                const cropDataUri = canvas.toDataURL('image/jpeg', 0.95);
                onCapture(cropDataUri, canvas.width, canvas.height);
            }
        }
    };

    return (
        <>
            {open && (
                <div className={classes.camera}>
                    {image ? (
                        <div className={classes.cropper}>
                            <Cropper ref={cropperRef} src={image} />
                            <Button
                                color='info'
                                onClick={onCrop}
                                className={classes.cropButton}
                                startIcon={<CropIcon />}
                            >
                                Recortar selección
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Camera
                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                isMaxResolution={true}
                                imageType={IMAGE_TYPES.JPG}
                                onTakePhoto={setImage}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
}

FirmaCamara.propTypes = {
    canvas: PropTypes.any,
    onCapture: PropTypes.any,
    open: PropTypes.any,
};
