import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import SyncIcon from '@material-ui/icons/Sync';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { usePreferencias } from '../../../contexts/AuthState';
import FirmaCamara from './FirmaCamara';

const useStyles = makeStyles(
    (theme) => ({
        label: {
            color: '#818CAE',
            fontSize: 12,
            margin: theme.spacing(1, 0),
        },
        input: {
            backgroundColor: 'white',
            borderRadius: 4,
            padding: theme.spacing(0.5, 1),
            fontSize: 13,
            color: '#818CAE',
            marginBottom: theme.spacing(3),
        },
        signatureButtons: {
            position: 'absolute',
            top: 8,
            right: 8,
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
        },
        signatureButton: {
            boxShadow: 'none',
            textTransform: 'none',
            color: 'white',
            backgroundColor: '#4D86FF',
            '&:focus, &:active': {
                backgroundColor: '#4D86FF',
            },
        },
        signatureWrapper: {
            border: '1px dashed #bababa',
            borderRadius: 4,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            marginBottom: theme.spacing(3),
        },
    }),
    { name: 'FirmaManual' },
);

export default function FirmaManual({ signatureResult, setSignatureResult, onBegin }) {
    const classes = useStyles();
    const isSignatureValid = signatureResult.imagen !== null && signatureResult.nombre !== '';
    const [showCamera, setShowCamera] = useState(false);
    const [canvasHeight, setCanvasHeight] = useState(300);
    const usarCamara = usePreferencias('usar_firma_camara');

    const sigCanvas = useRef({});
    const sigPad = useRef(null);

    const clearInput = () => {
        sigPad.current.clear();
        setSignatureResult((current) => ({
            ...current,
            imagen: null,
        }));
    };

    useEffect(() => {
        const resizeCanvas = () => {
            if (!sigCanvas.current) return;

            const canvas = sigCanvas.current;
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
        };

        window.addEventListener('resize', resizeCanvas);

        return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    const measuredRef = useCallback((node) => {
        const resizeCanvas = (signaturePad, canvas) => {
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
            signaturePad.clear();
        };

        if (node !== null) {
            sigCanvas.current = node.getCanvas();
            sigPad.current = node;
            resizeCanvas(node.getSignaturePad(), node.getCanvas());
        }
    }, []);

    const setSignatureOnChange = () => {
        const dataURL = sigPad.current.getTrimmedCanvas().toDataURL();
        setSignatureResult((result) => ({ ...result, imagen: dataURL }));
    };

    return (
        <>
            <Typography className={classes.label}>Firma en el recuadro</Typography>
            <FirmaCamara
                open={showCamera}
                canvas={sigCanvas.current}
                onCapture={(dataURL, width, height) => {
                    setSignatureResult((result) => ({ ...result, imagen: dataURL }));
                    setCanvasHeight(height);
                    setShowCamera(false);
                }}
            />
            <div className={classes.signatureWrapper} style={{ height: canvasHeight }}>
                <div className={classes.signatureButtons}>
                    {usarCamara && (
                        <Button
                            size='small'
                            startIcon={<CameraAltIcon />}
                            variant='contained'
                            className={classes.signatureButton}
                            onClick={() => setShowCamera(true)}
                        >
                            Camara
                        </Button>
                    )}
                    {isSignatureValid && (
                        <Button
                            size='small'
                            onClick={clearInput}
                            startIcon={<SyncIcon />}
                            variant='contained'
                            className={classes.signatureButton}
                        >
                            Repetir
                        </Button>
                    )}
                </div>
                <SignatureCanvas
                    ref={measuredRef}
                    penColor='black'
                    clearOnResize={false}
                    onBegin={onBegin}
                    onEnd={setSignatureOnChange}
                />
            </div>
        </>
    );
}

FirmaManual.propTypes = {
    onBegin: PropTypes.any,
    setSignatureResult: PropTypes.any,
    signatureResult: PropTypes.any,
};
