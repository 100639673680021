import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            alignItems: 'flex-start',
            padding: theme.spacing(1, 0),
        },
        fullWidth: {
            width: '100%',
        },
        icon: {
            fontSize: 20,
        },
        content: {
            fontSize: 13,
            lineHeight: '20px',
        },
    }),
    { name: 'TareaResumenDialog' },
);

export default function InfoChip({ Icon, fullWidth = false, children }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, { [classes.fullWidth]: fullWidth })}>
            <Icon className={classes.icon} />
            <Typography className={classes.content}>{children}</Typography>
        </div>
    );
}

InfoChip.propTypes = {
    Icon: PropTypes.any,
    children: PropTypes.any,
    fullWidth: PropTypes.bool,
};
