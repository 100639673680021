import PropTypes from 'prop-types';
import React from 'react';
import ListView from '../../common/ListView';

export function SelectSubtareaView({ onChange, onClose, currentValue, subtareas }) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Titulo',
                accessor: 'titulo',
            },
        ],
        [],
    );

    return (
        subtareas && (
            <ListView
                title='Seleccionar tarea'
                onClose={onClose}
                setFieldValue={onChange}
                currentValue={currentValue ? { id: currentValue.id } : null}
                items={subtareas}
                columns={columns}
                searchPlaceholder='Seleccionar tarea'
                multiple={false}
            />
        )
    );
}

SelectSubtareaView.propTypes = {
    currentValue: PropTypes.any,
    onChange: PropTypes.any,
    onClose: PropTypes.any,
    subtareas: PropTypes.any,
};
