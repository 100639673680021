import PropTypes from 'prop-types';
import { useState } from 'react';
import { preparacionesMaterialProvider } from '../../../../api';
import { EditDialogView } from '../../../common/EditDialogView';
import { TareaHeader, useTareaState } from '../../TareaView';

export function AddSolicitud({ id, currentSolicitud, onClose, headerComponent }) {
    const { id: tareaId } = useTareaState();

    const [solicitudId, setSolicitudId] = useState(id);

    return (
        <EditDialogView
            header={headerComponent || <TareaHeader title='Solicitar material' />}
            onClose={() => onClose(solicitudId)}
            onChange={(explicacion) => {
                const promise = solicitudId
                    ? preparacionesMaterialProvider.editSolicitudMaterial(solicitudId, { notas: explicacion })
                    : preparacionesMaterialProvider.addSolicitudMaterial(tareaId, { notas: explicacion });

                return promise.then((res) => {
                    if (res.id) setSolicitudId(res.id);

                    return res;
                });
            }}
            currentText={currentSolicitud}
        />
    );
}

AddSolicitud.propTypes = {
    currentSolicitud: PropTypes.any,
    id: PropTypes.any,
    onClose: PropTypes.any,
    headerComponent: PropTypes.any,
};
