import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { operariosProvider } from '../../../api';
import ListView from '../../common/ListView';
import { GENERAL } from '../nueva/NuevaTarea';

export default function SelectOperarioView({ tarea, setFieldValue, setCurrentFieldComponent, currentValue }) {
    const [operarios, setOperarios] = useState(null);

    useEffect(() => {
        operariosProvider.getAll('as_options').then(setOperarios);
    }, []);

    return (
        <BaseSelectOperarioView
            operarios={operarios}
            setFieldValue={setFieldValue}
            onClose={() => setCurrentFieldComponent(GENERAL)}
            currentValue={currentValue}
        />
    );
}

export function BaseSelectOperarioView({ operarios, setFieldValue, onClose, currentValue, ...props }) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
        ],
        [],
    );

    return (
        operarios && (
            <ListView
                title='Seleccionar operarios'
                onClose={onClose}
                setFieldValue={setFieldValue}
                currentValue={currentValue}
                items={operarios}
                columns={columns}
                searchPlaceholder='Buscar operario'
                multiple
                canAdd
                {...props}
            />
        )
    );
}

SelectOperarioView.propTypes = {
    currentValue: PropTypes.any,
    setCurrentFieldComponent: PropTypes.any,
    setFieldValue: PropTypes.any,
    tarea: PropTypes.any,
};

BaseSelectOperarioView.propTypes = {
    operarios: PropTypes.any,
    currentValue: PropTypes.any,
    onClose: PropTypes.any,
    setFieldValue: PropTypes.any,
};
