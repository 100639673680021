import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tareasProvider } from '../../../api';
import { formatDate } from '../../../utils';
import { BigButton } from '../../common/BigButton';
import { PageBody } from '../../common/PageBody';
import { PageHeader } from '../../common/PageHeader';
import { ButtonField } from '../common/ButtonField';
import { TextField } from '../common/TextField';
import { CLIENTE, DIRECCION, FECHA, OPERARIO } from './NuevaTarea';

const useStyles = makeStyles(
    (theme) => ({
        fieldValue: {
            display: 'flex',
            alignItems: 'center',
            '& span': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                borderRadius: 4,
                padding: theme.spacing(0.5, 1),
                marginRight: theme.spacing(1),
                fontSize: '80%',
            },
        },
    }),
    { name: 'GeneralView' },
);

export default function GeneralView({ tarea, setFieldValue, setCurrentFieldComponent }) {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <React.Fragment>
            <PageHeader
                title='Añadir servicio'
                startButton={
                    <IconButton onClick={() => history.goBack()}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            />
            <PageBody>
                <ButtonField
                    label='Cliente'
                    value={
                        tarea.cliente && (
                            <div className={classes.fieldValue}>
                                {tarea.cliente.id === null && <span>NUEVO</span>}
                                {tarea.cliente.nombre}
                            </div>
                        )
                    }
                    placeholder='Selecciona un cliente'
                    onClick={() => setCurrentFieldComponent(CLIENTE)}
                />
                <ButtonField
                    label='Direccion'
                    value={
                        tarea.direccion && (
                            <div className={classes.fieldValue}>
                                {tarea.direccion.id === null && <span>NUEVO</span>}
                                {tarea.direccion.direccion_completa ?? tarea.direccion.nombre}
                            </div>
                        )
                    }
                    placeholder={tarea.cliente ? 'Selecciona una direccion' : 'Selecciona primero un cliente'}
                    onClick={() =>
                        tarea.cliente && setCurrentFieldComponent(DIRECCION, { clienteId: tarea.cliente.id })
                    }
                />
                <TextField
                    label='Tarea'
                    value={tarea.descripcion}
                    onChange={setFieldValue('descripcion')}
                    placeholder='Descripción de la tarea'
                />
                <ButtonField
                    label='Fecha'
                    value={tarea.fecha && formatDate(new Date(tarea.fecha))}
                    placeholder='Selecciona una fecha'
                    onClick={() => setCurrentFieldComponent(FECHA)}
                />
                <ButtonField
                    label='Operarios'
                    value={tarea.operarios && tarea.operarios.map((operario) => operario.nombre).join(', ')}
                    placeholder='Selecciona un operario'
                    onClick={() => setCurrentFieldComponent(OPERARIO)}
                />
                <BigButton
                    style={{ marginTop: 'auto' }}
                    disabled={!(tarea.cliente && tarea.descripcion && tarea.fecha && tarea.operarios.length > 0)}
                    onClick={() => {
                        if (isSubmitting) return;

                        setSubmitting(true);

                        const fechaInicio = new Date(tarea.fecha);
                        const fechaFin = new Date(tarea.fecha);

                        fechaInicio.setHours(0, 0, 0, 0);
                        fechaFin.setHours(23, 59, 59, 0);

                        const values = {
                            cliente_id: tarea.cliente.id,
                            direccion_id: tarea.direccion.id,
                            descripcion: tarea.descripcion,
                            planificaciones: [
                                {
                                    fecha_inicio: fechaInicio,
                                    fecha_fin: fechaFin,
                                    operarios: tarea.operarios.map((op) => ({ operario: op, visible: true })),
                                },
                            ],
                        };
                        if (tarea.cliente.id === null) {
                            values.cliente = tarea.cliente;
                        }
                        if (tarea.direccion.id === null) {
                            values.direccion = tarea.direccion;
                        }

                        tareasProvider
                            .save(values)
                            .then(() => history.push('/'))
                            .catch(() => {
                                snackbar.showMessage('Ha ocurrido un error al guardar el servicio');
                            })
                            .finally(() => setSubmitting(false));
                    }}
                >
                    Guardar servicio
                </BigButton>
            </PageBody>
        </React.Fragment>
    );
}

GeneralView.propTypes = {
    setCurrentFieldComponent: PropTypes.any,
    setFieldValue: PropTypes.any,
    tarea: PropTypes.any,
};
