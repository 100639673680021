import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarioDayIcon(props) {
    return (
        <SvgIcon viewBox='0 0 24 24' {...props}>
            <path d='M7.0002 10.3756L17.0002 10.3756V8.77556H7.0002V10.3756Z' />
            <path d='M15.5002 13.6479H8.5002V12.0479H15.5002V13.6479Z' />
            <path d='M10.5002 16.9202H13.5002V15.3202H10.5002V16.9202Z' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.7002 7.3479C3.7002 5.8015 4.9538 4.5479 6.5002 4.5479H7.23352V2.85205H8.83352V4.5479H15.2668V2.85205H16.8668V4.5479H17.5002C19.0466 4.5479 20.3002 5.8015 20.3002 7.3479V18.3479C20.3002 19.8943 19.0466 21.1479 17.5002 21.1479H6.5002C4.9538 21.1479 3.7002 19.8943 3.7002 18.3479V7.3479ZM6.5002 6.1479C5.83745 6.1479 5.3002 6.68516 5.3002 7.3479V18.3479C5.3002 19.0106 5.83745 19.5479 6.5002 19.5479H17.5002C18.1629 19.5479 18.7002 19.0106 18.7002 18.3479V7.3479C18.7002 6.68516 18.1629 6.1479 17.5002 6.1479H6.5002Z'
            />
        </SvgIcon>
    );
}
